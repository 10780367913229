import React, { useState, useEffect } from 'react';
import {
    IoCheckmarkSharp
} from 'react-icons/io5';

const Alert = ({ message, show, onClose }) => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        alertHandler()
    }, [show])

    const alertHandler = () => {
        setShowAlert(show)

        setTimeout(() => {
            setShowAlert(false)
            onClose(false)
        }, 2000);
    }

    return (
        <div className={showAlert ? "alert" : "alert-close"}>
            <div className="alert-wrapper">
                <IoCheckmarkSharp size={25} color={"green"} />
                <p className="m-txt">{message}</p>
            </div>
        </div> 
        
    )
}

export default Alert;