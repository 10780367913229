import React, { Fragment, memo, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	IoChevronDownOutline,
	IoLogOutOutline,
	IoPersonOutline,
	IoSettingsOutline,
	IoNotificationsOutline,
	IoNotifications,
} from "react-icons/io5";
import { useHistory, useLocation } from "react-router";
import { logout } from "redux/adminSlice";
import { pathnames } from "routes/routes";
import logo from "appassets/navbar/logo.png";
import api from "api/api";
import { dateFormat } from "common/utility";

const NavBar = memo(() => {
	const history = useHistory();
	const scrollRef = useRef();
	const pagesRef = useRef({ size: 100, page: 0 });
	var totalPages = 0;

	const { isAuthenticated, user } = useSelector((state) => state.admin);
	const [notifications, setNotifications] = useState();
	const [notificationHover, setNotificationHover] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			fetchNotifications();
		}
	}, []);

	const fetchNotifications = async () => {
		try {
			let payload = {
				...pagesRef.current,
			};
			const resp = await api.get.getNotification(payload);
			totalPages = resp.data.result.totalPage;

			if (pagesRef.current.page == 0) {
				setNotifications(resp.data.result.notifications);
			} else {
				setNotifications((o) => {
					return [...o, ...resp.data.result.notifications];
				});
			}
		} catch (error) {
			console.log(error);
			setNotifications([]);
		}
	};

	const onHandleScrollGetNotification = () => {
		if (pagesRef.current.page + 1 < totalPages) {
			pagesRef.current.page += 1;
			fetchNotifications();
		}
	};

	useEffect(() => {
		const onHandleScroll = (e) => {
			const target = e.target;
			const scrollHeight = target.scrollHeight - target.clientHeight;
			const currentScroll = Math.round(target.scrollTop);

			if (scrollHeight <= currentScroll) {
				onHandleScrollGetNotification();
			}
		};

		scrollRef?.current?.addEventListener("scroll", onHandleScroll);
	}, []);

	const notificationDropdown = () => {
		return (
			<div className="nav-bar__notification--dropdown" ref={scrollRef}>
				<ul>
					{notifications?.map((o, i) => {
						return (
							<li key={i} onClick={() => handleNotificationRedirect(o)}>
								<div
									className={
										o.status == "unread"
											? "nav-bar__notification--item"
											: "nav-bar__notification--item nav-bar__notification--read"
									}
								>
									<div>
										<p className="m-txt m-txt--bold">{o.title}</p>
										{o.notificationType == "order" ? (
											<p className="m-txt m-txt--xs">
												Order no:
												<span className="m-txt m-txt--bold"> {o.orderNumber}</span>
											</p>
										) : o.notificationType == "monthlySettlement" ? (
											<p className="m-txt m-txt--xs">
												<span className="m-txt m-txt--bold">{o.description}</span>
											</p>
										) : null}
									</div>
									<div className="nav-bar__notification--date">
										<p className="m-txt m-txt--xs">{dateFormat(o.updatedAt)}</p>
										{o.status == "unread" ? <span className="greenDot"></span> : null}
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	};

	const handleNotificationRedirect = async (item) => {
		try {
			const payload = {
				adminId: user.adminId,
				customerId: null,
				merchantId: null,
				notificationId: item.id,
				notificationType: item.notificationType,
			};
			await api.post.readNotification(payload);
            fetchNotifications();
		} catch (error) {
			console.log(error);
		}

		switch (item.notificationType) {
			case "order":
				history.push(`/admin${pathnames.orderDetails.replace(":id", item.orderNumber)}`);
				break;
			case "monthlySettlement": 
                localStorage.setItem('monthlySettlement', item.title.substring(0,3));
				history.push(`/admin/monthly-settlement`);
				break;
			default:
				break;
		}
	};

	return (
		<nav className="nav-bar">
			<div className="nav-container">
				{!isAuthenticated ? (
					<Link to="/">
						<img className="nav-bar__logo" src={logo} alt="" />
					</Link>
				) : (
					<Fragment>
						<div />
						<div className="nav-bar__action-wrapper">
							<div
								className="nav-bar__notification"
								onMouseEnter={() => setNotificationHover(true)}
								onMouseLeave={() => setNotificationHover(false)}
							>
								<span className="redDot"></span>
								{notificationHover ? (
									<IoNotifications size={20} color={"white"} />
								) : (
									<IoNotificationsOutline size={20} color={"white"} />
								)}
								{notificationDropdown()}
							</div>
							<span className="divider"></span>
							<div className="nav-bar__account-wrapper">
								<Link to="/accounts" className="nav-bar__link nav-bar__link--account">
									<IoPersonOutline size={18} />
									<span>My Account</span>
									<IoChevronDownOutline size={18} />
								</Link>
								<AccountDropdown />
							</div>
						</div>
					</Fragment>
				)}
			</div>
		</nav>
	);
});

export default NavBar;

const AccountDropdown = memo(() => {
	const dispatch = useDispatch();
	const history = useHistory();

	const onHandleLogout = () => {
		dispatch(logout());
	};

	const accountSettings = [
		// {
		// 	to: "account/settings",
		// 	label: "Profile",
		// 	icon: <IoPersonOutline size={18} />,
		// },
		{
			to: "account/settings",
			label: "Account Settings",
			icon: <IoSettingsOutline size={18} />,
		},
		{
			label: "Logout",
			to: "",
			icon: <IoLogOutOutline size={18} />,
			onClick: onHandleLogout,
		},
	];

	return (
		<div className="nav-bar__dropdown">
			<ul>
				{accountSettings.map((o, i) => {
					return (
						<li key={`${i}-${o.to}`} onClick={o.onClick}>
							<Link to={o.to} className="nav-bar__link">
								{o.icon}
								<span>{o.label}</span>
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
});
