import api from "api/api";
import { findByValue } from "utils/functions";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	monthlySettlements: [],
	filteredMonthlySettlements: [],
	error: "",
	loading: false,
	currentPage: 0,
	totalPage: 0,
	orderSettlementDetails: {
		sellerDetails: null,
		monthlySettlementDetailsList: null,
		subtotal: null,
		serviceFee: null,
		advertisementCharge: null,
		closingCost: null,
	},
};

export const fetchMonthlySettlements = createAsyncThunk(
	"dashboard/fetchMonthlySettlement",
	async ({ currentPage, search, filters, values, sort, order }) => {
		const response = await api.get.monthlyStatements({ page: currentPage, search, filters, values, sort, order });
		return response;
	}
);

export const fetchOrderSettlementDetails = createAsyncThunk(
	"dashboard/fetchOrderSettlementDetails",
	async ({ filters, values, sort, order }) => {
		const response = await api.get.monthlyOrders({ filters, values, sort, order });
		return response;
	}
);

export const fetchOrderPdf = createAsyncThunk("dashboard/fetchOrderSettlementDetails", async ({ filters, values }) => {
	return await api.get.monthlyOrders({ filters, values });
});

const monthlySettlementSlice = createSlice({
	name: "monthlySettlement",
	initialState: initialState,
	reducers: {
		fetchedSettlements: (state, action) => {
			const { monthlySettlementList } = action.payload.data.result;
			state.monthlySettlements = monthlySettlementList;
		},
		fetchedOrderSettlementDetails: (state, action) => {
			const {
				sellerDetails,
				monthlySettlementDetailsList,
				subtotal,
				advertisementCharge,
				serviceFee,
				closingCost,
				totalOrders,
				totalQuantity,
			} = action.payload.data.result;

			state.orderSettlementDetails = {
				sellerDetails,
				monthlySettlementDetailsList,
				subtotal: subtotal?.toFixed(2),
				advertisementCharge: advertisementCharge?.toFixed(2),
				serviceFee: serviceFee?.toFixed(2),
				closingCost: closingCost?.toFixed(2),
				totalOrders,
				totalQuantity,
			};
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		filterSettlements: (state, action) => {
			const { value, key } = action.payload;
			if (value !== undefined) {
				state.filteredMonthlySettlements = findByValue(state.monthlySettlements, value, key);
			} else {
				state.filteredMonthlySettlements = state.monthlySettlements;
			}
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMonthlySettlements.fulfilled, (state, action) => {
			const response = action.payload;
			state.loading = false;
			const { responseCode } = response.data;

			if (responseCode === 200) {
				const { totalPage } = response.data.result;

				state.totalPage = totalPage;

				monthlySettlementSlice.caseReducers.fetchedSettlements(state, action);
			} else {
				state.error = `${response.data.error}  ${response.data.message};`;
			}
		});

		builder.addCase(fetchMonthlySettlements.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchMonthlySettlements.rejected, (state, action) => {
			state.loading = false;
			state.error = action.message;
		});

		builder.addCase(fetchOrderSettlementDetails.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(fetchOrderSettlementDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.message;
		});

		builder.addCase(fetchOrderSettlementDetails.fulfilled, (state, action) => {
			const response = action.payload;
			state.loading = false;
			const { responseCode } = response.data;
			if (responseCode === 200) {
				monthlySettlementSlice.caseReducers.fetchedOrderSettlementDetails(state, action);
			} else {
				state.error = `${response.data.message}`;
			}
		});
	},
});

export const { fetchedSettlements, filterSettlements, setCurrentPage } = monthlySettlementSlice.actions;

export default monthlySettlementSlice.reducer;
