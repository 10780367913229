import Navbar from "components/common/Navbar";
import { FormikErrorMessage, FormikField } from "components/ui/InputComponents";
import Button from "components/button/button";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loginUser } from "redux/adminSlice";
import * as yup from "yup";
import { Message } from "components/ui/Message";

function AdminLoginPage() {
	const history = useHistory();

	const dispatch = useDispatch();

	const { email, password, isAuthenticated, error, loading } = useSelector((state) => state.admin);

	const handleLogin = async (values) => {
		dispatch(loginUser(values));
	};

	useEffect(() => {
		if (isAuthenticated) {
			history.push("/admin/dashboard");
		}
	}, [isAuthenticated]);

	return (
		<div className="login m-full-h">
			<div className="container m-container m-container--center">
				<div className="login__card  m-card">
					<section className="login__section">
						<img src="/assets/admin.svg" alt="" className="p-10" />
					</section>

					<div className="login__divider" />

					<section className="login__section">
						<p className="m-txt m-txt--xl m-txt">Login</p>

						<Formik
							initialValues={{
								email: "",
								password: "",
							}}
							validationSchema={yup.object({
								email: yup.string().required("Email is required").email("Invalid email address"),
								password: yup
									.string()
									.required("Password is required")
									.min(6, "Password must be mininum 6 characters"),
							})}
							onSubmit={(values, { setSubmitting }) => {
								setTimeout(() => {
									handleLogin(values);
									setSubmitting(false);
								}, 400);
							}}
						>
							{() => (
								<Form className="p-2">
									<div className="mb-4">
										<FormikField type={"email"} name={"email"} placeholder="Email Address" />
										<FormikErrorMessage name={"email"} component="div" className={"text-red-500"} />
									</div>
									<div className="mb-4">
										<FormikField type={"password"} name={"password"} placeholder="Password" />
										<FormikErrorMessage
											name={"password"}
											component="div"
											className={"text-red-500"}
										/>
									</div>
									{error && <Message variant="danger" message={error} />}
									<Button type="submit" label="LOGIN" />
								</Form>
							)}
						</Formik>
					</section>
				</div>
			</div>
		</div>
	);
}

export default AdminLoginPage;
