import api from "api/api";
import { sortByKey } from "common/utility";
import { findByKeys, findByValue } from "utils/functions";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	loading: false,
	error: "",
	orders: [],
	currentPage: 0,
	totalPage: 0,
	filteredOrders: null,
	orderDetails: {
		sellerDetails: null,
		ordersDetails: null,
		productDetailsList: null,
	},
};

export const fetchOrders = createAsyncThunk(
	"orders/fetchOrders",
	async ({ currentPage, search, year, status, order, sort }, thunkAPI) => {
		let params = `page=${currentPage}&search=${search}&status=${status}&year=${year}&sort=${sort}&order=${order}`;

		if (!status) {
			params = `page=${currentPage}&search=${search}&year=${year}&sort=${sort}&order=${order}`
		}
		
		const response = await api.get.orders(params);
		return response;
	}
);

export const fetchOrderDetails = createAsyncThunk("orders/fetchOrderDetails", async (orderNumber) => {
	const response = await api.get.order(orderNumber);
	return response;
});

const orderSlice = createSlice({
	name: "orders",
	initialState,

	reducers: {
		filterOrders: (state, action) => {
			state.filteredOrders = [];
			const { value, keys } = action.payload;
			if (value !== undefined) {
				state.filteredOrders = findByKeys(state.orders, value.toString(), keys);
			} else {
				state.filteredOrders = state.orders;
			}
		},
		filterByStatus: (state, action) => {
			if (action.payload === null) {
				state.filteredOrders = state.orders;
			} else {
				state.filteredOrders = state.orders.filter((o) => o.status === action.payload);
			}
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		sort: (state, action) => {
			state.loading = true;
			const { key, order } = action.payload;

			state.orders = sortByKey([...state.orders], key, order);
			state.loading = false;

			if (state.filteredOrders) {
				state.filteredOrders = sortByKey([...state.filteredOrders], key, order);
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOrders.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchOrders.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchOrders.fulfilled, (state, action) => {
			state.loading = false;
			state.filteredOrders = null;
			const { data, status } = action.payload;

			if (status === 200) {
				const { orderHistory, page } = data.result;

				state.orders = orderHistory.map((order) => ({
					id: order.orderNumber,
					companyName: order.companyName,
					price: parseFloat(order.price).toFixed(2),
					date: new Date(order.date).toDateString(),
					status: order.status,
				}));
				state.totalPage = page;
			} else {
				state.error = data.message;
			}
		});

		builder.addCase(fetchOrderDetails.pending, (state) => {
			state.loading = true;
			state.orderDetails = {
				sellerDetails: null,
				productDetailsList: null,
				ordersDetails: null,
			};
		});

		builder.addCase(fetchOrderDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
			state.loading = false;
			const { data, status } = action.payload;

			if (status === 200) {
				const { sellerDetails, productDetailsList, ordersDetails } = data.result;

				state.orderDetails = {
					productDetailsList,
					sellerDetails,
					ordersDetails,
				};
			} else {
				state.error = data.message;
			}
		});
	},
});

export const { filterOrders, filterByStatus, setCurrentPage, sort } = orderSlice.actions;

export default orderSlice.reducer;
