import api from "api/api";
import { findByValue } from "utils/functions";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	announcements: [],
	error: "",
	loading: false,
	currentPage: 0,
	totalPage: 0,
	filterAnnouncements: [],
	createSuccess: false,
};

export const fetchAnnouncement = createAsyncThunk(
	"announcements/fetchAnnouncements",
	async ({ page, search, field, value, sort, order }) => {
		const response = await api.get.getAnnouncementList({
			page,
			search,
			field,
			value,
			sort,
			order,
		});
		return response;
	}
);

export const createAnnouncement = createAsyncThunk("announcements/createAccouncement", async (payload) => {
	const response = await api.post.createAnnouncement(payload);
	return response;
});

const announcementSlice = createSlice({
	name: "announcements",
	initialState: initialState,
	reducers: {
		filteredAnnouncements: (state, action) => {
			const { value, key } = action.payload;
			if (value !== undefined) {
				state.filterAnnouncements = findByValue(state.announcements, value, key);
			} else {
				state.filterAnnouncements = state.announcements;
			}
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		fetchedAnnouncements: (state, action) => {
			const response = action.payload.data.result;
			state.announcements = response.announcements.map((announcement) => ({
				message: announcement.content,
				recipient:
					announcement.recipient === 0
						? "Seller"
						: announcement.recipient === 1
						? "Buyer"
						: announcement.recipient === 2
						? "Buyer & Seller"
						: announcement.recipient,
				sentDate: new Date(announcement.sentDate).toDateString(),
			}));
			state.totalPage = response.totalPage;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchAnnouncement.pending, (state, action) => {
			state.error = "";
			state.loading = true;
		});
		builder.addCase(fetchAnnouncement.fulfilled, (state, action) => {
			state.loading = false;
			const response = action.payload;
			const { responseCode } = response.data;

			if (responseCode === 200) {
				announcementSlice.caseReducers.fetchedAnnouncements(state, action);
			} else {
				state.announcements = [];
				state.error = `${response.data.message};`;
			}
		});
		builder.addCase(createAnnouncement.pending, (state, action) => {
			state.error = "";
			state.loading = true;
			state.createSuccess = false;
		});
		builder.addCase(createAnnouncement.fulfilled, (state, action) => {
			state.loading = false;
			const response = action.payload;

			const { responseCode, result } = response.data;

			if (responseCode === 200) {
				state.createSuccess = true;
				let newAnnouncement = {
					message: result.announcement.content,
					recipient:
						result.announcement.recipient === 0
							? "Seller"
							: result.announcement.recipient === 1
							? "Buyer"
							: result.announcement.recipient === 2
							? "Buyer & Seller"
							: result.announcement.recipient,
					sentDate: new Date(result.announcement.sentDate).toDateString(),
				};
				state.announcements = state.announcements.concat(newAnnouncement);
			} else {
				state.error = `${response.data.message};`;
			}
		});
	},
});

export const { filteredAnnouncements, setCurrentPage } = announcementSlice.actions;

export default announcementSlice.reducer;
