import React from 'react';
import moment from "moment";
const Footer = () => {
    var year = moment().format('YYYY');
    return (
        <div className="footer">
            <div className="m-container">
                <section className="footer__section">
                    <p className="m-txt m-txt--s">© {year} E-commerce. All rights reserved.</p>
                </section>
            </div>
        </div>
    );
};

export default Footer;
