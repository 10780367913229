import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "appassets/navbar/logo.png";
import { logout } from "redux/adminSlice";

export default function Navbar() {
	const { isAuthenticated } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const location = useLocation();

	const handleSignOut = () => {
		dispatch(logout());
	};

	return (
		<div className="h-20">
			<nav className="bg-primary hidden md:flex flex flex-wrap w-full h-full">
				<div className="flex justify-between items-center w-full px-5">
					{location.pathname == "/" ? 
						<div>
							<img src={logo} alt="logo" className="h-10" />
						</div> 
						: <div></div>	
					}
					<div className="flex">
						{isAuthenticated && (
							<div className="py-1">
								<NavLink
									to="/"
									onClick={() => handleSignOut()}
									className="px-4 py-2 flex justify-start"
								>
									<p className="m-txt m-txt--white">Sign Out</p>
								</NavLink>
							</div>
						)}
					</div>
				</div>
			</nav>
		</div>
	);
}
