import React, { useState } from "react";
import SortHeader from "./SortHeader";

const AdApplyTableComponent = (props) => {
	var headersLength = props.headers.length;
	const columnsLength = Object.keys(props?.headers)?.length;
	const [activeSortOrder, setActiveSort] = useState(null);

	return (
		<div className="flex w-full justify-center items-center rounded-lg flex-col font-medium shadow-md">
			<div className="flex flex-row w-full divide-x">
				{props.headers
					? props.headers.map((header, index) => {
							if (index === headersLength - 1 && props.hasButton) {
								return (
									<div
										key={header.key}
										className="flex w-1/2 bg-tableHeaderColor rounded-tr-md text-white justify-center items-center h-10 px-4"
									>
										{header.label}
									</div>
								);
							}

							if (index === 0) {
								return (
									<div
										key={header.key}
										className="flex w-full bg-tableHeaderColor rounded-tl-md text-white text-left items-center h-10 px-4"
									>
										<div className="pl-4">{header.label}</div>
										<div className="mr-8">
											{header.hasSorting ? (
												<SortHeader
													sortFunction={props.sortFunction}
													sortKey={header.key}
													setActiveSortOrder={setActiveSort}
													activeSortOrder={activeSortOrder}
												/>
											) : null}
										</div>
									</div>
								);
							} else {
								return (
									<div
										key={header.key}
										className="flex bg-tableHeaderColor text-white justify-start items-center h-10 px-4"
										style={index === 1?{width:'200%'}:{width:'100%'}}
									>
										<div className="px-4">{header.label}</div>
										<div className="mr-8">
											{header.hasSorting ? (
												<SortHeader
													sortFunction={props.sortFunction}
													sortKey={header.key}
													setActiveSortOrder={setActiveSort}
													activeSortOrder={activeSortOrder}
												/>
											) : null}
										</div>
									</div>
								);
							}
					  })
					: null}
			</div>

			<div className={`${"flex flex-col w-full "}`}>
				{!(props?.columns?.length > 0) && <div className="mx-auto mt-10">No items found</div>}
				{props.columns &&
					props.columns.map((column, index) => {
						return (
							<div
								key={column + index}
								className={`flex flex-row h-16 bg-white text-black divide-x ${
									column.status === "disabled" ? "opacity-50" : null
								}`}
							>
								{Object.keys(column).map((key, index) => {
									switch (true) {
										case index === columnsLength - 1 && props.hasButton:
											return (
												<button
													key={key + index}
													{...column[key].props}
													className="flex w-1/2 justify-center items-center divide-x border-b-2 px-4"
												>
													{column[key]}
												</button>
											);

										case column[key] === "disabled" || column[key] === "enabled":
											return null;

										case index === 0:
											return (
												<div
													key={key + index}
													className={`flex w-full items-center divide-x border-b-2 px-4`}
												>
													<div className="px-4">{column[key]} </div>
												</div>
											);

										default:
											return (
												<div
													key={key + index}
													className={`flex items-center divide-x border-b-2 px-4`}
													style={index === 1?{width:'200%'}:{width:'100%'}}
												>
													<div
														className="px-4"
													>
														{column[key]}
													</div>
												</div>
											);
									}
								})}
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default AdApplyTableComponent;
