import { config } from "common/config";
import { RoundedButton } from "components/ui/UIComponents";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createAnnouncement } from "redux/announcementSlice";

const AddAccouncementModal = ({ popupVisible, setPopupVisible }) => {
	const [textareaCount, setTextareaCount] = useState(0);

	const dispatch = useDispatch();

	const { loading, error, createSuccess } = useSelector((state) => state.announcement);

	const [input, setInput] = useState({
		content: "",
		recipient: "",
	});

	const handleChange = (e) => {
		setInput({ ...input, [e.target.name]: e.target.value });
	};

	const handleSelect = (value) => {
		setInput({ ...input, recipient: value.value });
	};

	useEffect(() => {
		setTextareaCount(input.content.length);
	}, [input.content]);

	const handleSubmit = async () => {
		const payload = { ...input, sentDate: new Date().toUTCString() };
		dispatch(createAnnouncement(payload));
	};

	useEffect(() => {
		if (createSuccess) {
			toast.success("Announcement was successfully created");
			setPopupVisible(false);
		}
	}, [createSuccess]);

	const customStyles = {
		content: {
			top: "50%",
			left: "55%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: 10,
			width: "25%",
			minWidth: 400,
		},
	};

	return (
		<Modal ariaHideApp={false} isOpen={popupVisible} onRequestClose={() => setPopupVisible(false)} style={customStyles}>
			<div className="pl-4 pb-4 m-4">
				<div className="flex justify-end">
					<button onClick={() => setPopupVisible(false)}>
						<MdClose size="24" color="gray" />
					</button>
				</div>
				<div className="pb-3 font-semibold">Add New Announcement</div>
				<div className="pb-4">You can send the announcement to all the sellers / customers</div>
				<div className="pt-4 pb-2">Send to</div>
				<Select
					name="recipient"
					onChange={handleSelect}
					defaultValue={config.announcementPage.sentTo[0]}
					options={config.announcementPage.sentTo}
				/>
				<div className="pt-6">Announcement message</div>
				<textarea
					name="content"
					onChange={handleChange}
					value={input.content}
					className="w-full h-24 p-2 border-2 border-gray-500 mt-2"
					placeholder="Enter announcement message here"
					maxLength={200}
				/>
				<div className="flex justify-end">
					<div className="flex text-gray-700">Maximum characters: {200 - textareaCount}</div>
				</div>
				<div className="flex justify-center mt-8">
					<RoundedButton label="Confirm & Send" dark onClick={handleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default AddAccouncementModal;
