import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import Scrollbars from 'rc-scrollbars';

import Navbar from "components/nav-bar/nav-bar";
import SideNav from 'components/side-nav/side-nav';
import Footer from 'components/footer/footer';

export default function AdminLayout(props) {
	const { isAuthenticated } = useSelector((state) => state.admin);
	const scrollRef = useRef(); 
	const location = useLocation();

	useEffect(() => {
		scrollRef.current.scrollToTop();
	}, [location])

	return (
		<div className="m-page">
			{isAuthenticated ? <SideNav /> : null}
			<main className="m-main">
				<Navbar />
				<Scrollbars id="rc-scroll" ref={scrollRef}>{props.children}</Scrollbars>
				{/* <Footer /> */}
			</main>
		</div>
	);
}
