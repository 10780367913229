import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { FiCheck } from 'react-icons/fi';

const Popup = ({ open, onPopupClose, success, children }) => {

    return (
        <Modal 
            classNames={{ modal: "popup", overlay: 'popup__overlay' }}
            open={open}
            onClose={onPopupClose} 
            center
        >
            {success ?
                <div className="m-txt m-txt--valid">
                    <FiCheck size={35} />
                </div>
                : null
            }
            {children}
        </Modal>
    )
}

export default Popup;