import React from "react";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";

const SortHeader = ({ sortFunction, sortKey, setActiveSortOrder, activeSortOrder }) => {
	const handleSort = (order) => {
		console.log(order, sortKey);
		sortFunction(sortKey, order);
		setActiveSortOrder({ [sortKey]: order });
	};

	return (
		<div className="flex flex-col text-gray-500 text-xl justify-end">
			<button
				className={activeSortOrder && activeSortOrder[sortKey] === "asc" && "text-white"}
				onClick={() => handleSort("asc")}
			>
				<VscTriangleUp />
			</button>
			<button
				className={activeSortOrder && activeSortOrder[sortKey] === "desc" && "text-white"}
				onClick={() => handleSort("desc")}
			>
				<VscTriangleDown />
			</button>
		</div>
	);
};

export default SortHeader;
