import notfound from "appassets/notfound/notfound.png";
import Navbar from "components/common/Navbar";
import React from "react";
import { NavLink } from "react-router-dom";

export const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className="w-full h-screen bg-white flex flex-col items-center justify-center space-y-6">
        <img src={notfound} alt="notfound" className="w-1/4" />
        <h1 className="font-bold text-2xl">Oops, We got a problem!</h1>
        <h2>Looks like we had lost you.</h2>
        <NavLink to="/">
          <button className="btn-cta px-4">BACK TO HOME</button>
        </NavLink>
      </div>
    </>
  );
};
