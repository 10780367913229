import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { fetchOrderSettlementDetails } from "redux/monthlySettlementSlice";
import { jsPDF } from "jspdf";
import { MonthFormat } from "common/utility";
import SortHeader from "components/ui/SortHeader";
import { streamStatusColorFormat } from "common/utility";
import { config } from "common/config";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { NavigationTitle, RoundedButton, Row } from "components/ui/UIComponents";
import { currencyFormat } from "common/utility";

export default function MerchantMonthlySettlementDetailPage() {
	const [popupVisible, setPopupVisible] = useState(false);
	const [header, setHeader] = useState(config.monthlySettlementDetailsPage.settlementHeader);
	const history = useHistory();
	const columnsLength = Object.keys(header)?.length;
	const [list, setList] = useState(null);
	const [activeSortOrder, setActiveSort] = useState(null);
	const { id, month } = useParams();
	let date = new Date();

	const dispatch = useDispatch();

	const { orderSettlementDetails, loading, error } = useSelector((state) => state.monthlySettlement);

	const sellerDetails = [
		{
			label: "Seller Id",
			value: orderSettlementDetails?.sellerDetails?.id,
		},
		{
			label: "Seller Type",
			value: orderSettlementDetails?.sellerDetails?.sellerType,
		},
		{
			label: "Company Name",
			value: orderSettlementDetails?.sellerDetails?.companyName,
		},
		{
			label: "Person In Charge",
			value: orderSettlementDetails?.sellerDetails?.name,
		},
		{
			label: "Email",
			value: orderSettlementDetails?.sellerDetails?.personInChargeEmail,
		},
		{
			label: "Mobile No",
			value: orderSettlementDetails?.sellerDetails?.contactNumber,
		},
	];

	const customStyles = {
		content: {
			top: "50%",
			left: "55%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: 10,
			width: "30%",
			minWidth: 400,
		},
	};

	const customDropdownStyle = {
		option: (provided) => ({
			...provided,
			fontSize: 13,
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			fontSize: 15,
		}),
		control: (provided) => ({
			...provided,
			width: "100%",
			height: 30,
			fontSize: 12,
			backgroundColor: "#F3F3F6",
		}),
	};

	const navigateToDetails = (id) => {
		history.push(`/admin/orders/order/${id}`);
	};

	const navigateBack = () => {
		history.goBack();
	};

	// const handleAddStorageLimitModal = () => {
	//   setPopupVisible(true);
	// };

	const handlePickStorageLimit = (selectedItem) => {
		console.log("selectedItem:", selectedItem);
	};

	const handleAddStorageLimit = () => {
		setPopupVisible(false);
	};

	const handleExportPdf = () => {
		setHeader(config.monthlySettlementDetailsPage.settlementHeaderPdf);
		const statements = orderSettlementDetails.monthlySettlementDetailsList.map((statement) => ({
			orderId: statement.orderNumber, 
			items: statement.itemQuantity,
			date: statement.date,
			totalAmount: currencyFormat(parseFloat(statement.totalAmount)),
		}));
		setList(statements);
		
		const input = document.getElementById("divToExport");
		const pdf = new jsPDF({ unit: "px", format: "a4", userUnit: "px" });
		pdf.html(input, { html2canvas: { scale: 0.25 } }).then(() => {
			pdf.save("Monthly_Settlements.pdf");
			setHeader(config.monthlySettlementDetailsPage.settlementHeader);
			mapColumns(orderSettlementDetails.monthlySettlementDetailsList);
		});
	};

	useEffect(() => {
		dispatch(
			fetchOrderSettlementDetails({
				filters: "sellerId,month",
				values: `${id}, ${month}`,
			})
		);
	}, []);

	useEffect(() => {
		if (orderSettlementDetails.monthlySettlementDetailsList)
			mapColumns(orderSettlementDetails.monthlySettlementDetailsList);
	}, [orderSettlementDetails.monthlySettlementDetailsList]);

	function mapColumns(array) {
		const statements = array.map((statement) => ({
			orderId: statement.orderNumber,
			items: statement.itemQuantity,
			date: statement.date,
			totalAmount: currencyFormat(parseFloat(statement.totalAmount)),
			action: (
				<button onClick={() => navigateToDetails(statement.orderNumber)}>
					<BsChevronRight size="15" />
				</button>
			),
		}));
		setList(statements);
	}

	function mapColumnsClean(array) {
		const statements = array.map((statement) => ({
			orderId: statement.orderNumber,
			items: statement.itemQuantity,
			date: statement.date,
			totalAmount: currencyFormat(parseFloat(statement.totalAmount)),
		}));
		setList(statements);
	}

	const sort = (sort, order) => {
		dispatch(
			fetchOrderSettlementDetails({
				filters: "sellerId,month",
				values: `${id}, ${month}`,
				sort,
				order,
			})
		);
	};

	return (
		<>
			<Spinner show={loading} />
			<div className=" text-black min-h-full min-h-screen p-8" id="divToExport">
				{false ? (
					<Message variant="danger" message={error} />
				) : (
					<>
						<NavigationTitle
							prevPage="Monthly Settlement"
							toPrevPage="/admin/monthly-settlement"
							currentPage={orderSettlementDetails?.sellerDetails?.companyName}
						/>
						<div className="bg-white rounded-md p-6 mt-8">
							<div className="grid grid-cols-2">
								<div className="flex flex-col">
									<div className="text-sm">
										{month < 10 ? "0" + month : month} /{" "}
										{date.getFullYear()}
									</div>
									<div className="font-bold">
										{orderSettlementDetails?.sellerDetails?.companyName}
									</div>
									<div>
										Total of order: {orderSettlementDetails?.totalOrders} order(s) -{" "}
										{orderSettlementDetails?.totalQuantity} items
									</div>
								</div>
								<div className="flex items-end justify-end flex-col">
									<div>
										Total of sales :
										<span className="font-semibold">
											{" "}
											RM{currencyFormat(parseFloat(orderSettlementDetails?.subtotal))}
										</span>
									</div>
									<div>
										Service Fee :{" "}
										<span className="font-semibold">
											RM{currencyFormat(parseFloat(orderSettlementDetails?.serviceFee))}
										</span>
									</div>
								</div>
							</div>
							<hr className="my-5" />

							<Row className="w-full divide-x-2">
								<div className="w-1/3">
									<h2 className="bg-primaryBg px-4 py-4 mr-4 font-medium">Seller Details</h2>

									{sellerDetails.map((details) => (
										<div key={details.label} className="my-4">
											<div style={{ color: "grey" }}>{details.label}</div>
											<div className="font-semibold">{details.value} </div>
										</div>
									))}
								</div>
								<div className="w-2/3">
									<div className="grid grid-cols-3">
										<h2 className="bg-primaryBg px-4 py-4 mx-5 font-medium col-span-2">
											Orders ({MonthFormat(parseInt(month)).label} {date.getFullYear()})
										</h2>
										<div className="col-span-1 flex items-center justify-end">
											<RoundedButton
												label="EXPORT PDF"
												dark
												onClick={() => handleExportPdf()}
												style={{ minWidth: "max-content" }}
											/>
										</div>
									</div>
									<div className="p-5 h-full flex flex-wrap">
										<div className="flex w-full justify-center items-center rounded-lg flex-col">
											<div className="flex flex-row w-full divide-x">
												{header
													? header.map((headerItem, index) => {
															if (index === columnsLength - 1 && headerItem.label == "") {
																return (
																	<div
																		key={headerItem.label}
																		className="flex w-1/2 bg-tableHeaderColor text-white justify-center  items-center h-12"
																	>
																		{headerItem.label}
																	</div>
																);
															}
															if (index === 0) {
																return (
																	<div
																		key={headerItem.label}
																		className="flex w-full bg-tableHeaderColor text-white items-center h-12 text-left justify-between"
																	>
																		<div className="pl-4">{headerItem.label}</div>
																		<div className="mr-8">
																			{headerItem.hasSorting ? (
																				<SortHeader
																					sortFunction={sort}
																					sortKey={headerItem.key}
																					setActiveSortOrder={setActiveSort}
																					activeSortOrder={activeSortOrder}
																				/>
																			) : null}
																		</div>
																	</div>
																);
															} else {
																return (
																	<div
																		key={headerItem.key}
																		className={`flex w-full bg-tableHeaderColor text-white items-center h-12 justify-around`}
																	>
																		{headerItem.label}
																		{headerItem.hasSorting ? (
																			<SortHeader
																				sortFunction={sort}
																				sortKey={headerItem.key}
																				setActiveSortOrder={setActiveSort}
																				activeSortOrder={activeSortOrder}
																			/>
																		) : null}
																	</div>
																);
															}
													  })
													: null}
											</div>

											<div className="flex flex-col w-full">
												{!(list?.length > 0) && (
													<div className="mx-auto mt-10">No items found</div>
												)}
												{list &&
													list.map((column, index) => {
														return (
															<div
																className="flex flex-row h-16 bg-white text-black divide-x overflow-auto"
																key={column + index}
															>
																{Object.keys(column).map((key, index) => {
																	switch (true) {
																		case index === columnsLength - 1 &&
																			key == "action":
																			return (
																				<button
																					key={column[key] + index}
																					{...column[key].props}
																					className="flex  w-1/2  justify-center items-center divide-x border-b-2  text-sm"
																				></button>
																			);

																		case index === 0:
																			return (
																				<div
																					key={column[key] + index}
																					className={`flex w-full items-center divide-x border-b-2 break-all text-sm`}
																				>
																					<div className="pl-4">
																						{column[key]}
																					</div>
																				</div>
																			);

																		case index !== 0:
																			return (
																				<div
																					key={column[key] + index}
																					className={`flex w-full justify-center
                       items-center divide-x border-b-2 text-${streamStatusColorFormat(column[key]).color}`}
																				>
																					{column[key]}
																				</div>
																			);

																		default:
																			return null;
																	}
																})}
															</div>
														);
													})}
											</div>
											<div className="mt-0 pt-0 w-full flex flex-row justify-end shadow-md">
												<div className="flex flex-wrap flex-col items-end items-between w-full">
													<div className="p-3 w-full text-right bg-greyTile">Total :</div>
													<div className="p-3 w-full text-right bg-lightGreyTile">
														E-commerce Service Fee (1%) :
													</div>
													<div className="p-3 w-full text-right bg-whiteGreyTile">
														Seller closing cost :
													</div>
												</div>
												<div className="flex flex-col items-end font-semibold">
													<div className="p-3 w-full text-right bg-greyTile">
														{currencyFormat(parseFloat(orderSettlementDetails?.subtotal))}
													</div>
													<div className="p-3 w-full text-right bg-lightGreyTile">
														{currencyFormat(parseFloat(orderSettlementDetails?.serviceFee))}
													</div>
													<div className="text-red-600 font-bold p-3 w-full text-right bg-whiteGreyTile">
														{currencyFormat(
															parseFloat(
																orderSettlementDetails?.subtotal -
																	orderSettlementDetails?.serviceFee
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Row>
						</div>
					</>
				)}
				<Modal isOpen={popupVisible} onRequestClose={() => setPopupVisible(false)} style={customStyles}>
					<div className="pl-4 pb-4 m-4">
						<div className="flex justify-end">
							<button onClick={() => setPopupVisible(false)}>
								<MdClose size="24" color="gray" />
							</button>
						</div>
						<div className="pb-3 font-semibold">Upgrade live-stream storage</div>
						<div className="pb-4 font-medium">
							Storage balance: <span className="text-red-600">0 GB</span>
						</div>
						<div className="pb-6 border-b-2">Store name: FruitLab</div>
						<div className="pt-4 pb-2">Storage limit</div>
						<Select
							styles={customDropdownStyle}
							onChange={(item) => handlePickStorageLimit(item)}
							defaultValue={config.liveStream.storageLimit[0]}
							options={config.liveStream.storageLimit}
						/>
						<div className="text-xs">Update the storage by select the storage size</div>
						<div className="flex justify-center mt-8">
							<RoundedButton label="CONFIRM UPGRADE" dark onClick={() => handleAddStorageLimit()} />
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
}
