import React, { useEffect, useState } from "react";

const Pill = ({ color, bg, status, ...props }) => {
  const [bgColor, setbgColor] = useState("");

  useEffect(() => {
    switch (status) {
      case "success":
        setbgColor("bg-green-400 text-white");
        break;
      case "danger":
        setbgColor("bg-red-400 text-white");
        break;
      case "warning":
        setbgColor("bg-yellow-400 text-white");
        break;
      case "inactive":
        setbgColor("bg-gray-400 text-white");
        break;

      default:
        break;
    }
  }, [status]);

  return (
    <div
      className={`  ${color} ${bg} ${bgColor} px-6 py-1 text-sm rounded-full mx-2 `}
      {...props}
    >
      {props.children}
    </div>
  );
};

export default Pill;
