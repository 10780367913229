import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;

const initialState = {
	user: userInfoFromStorage,
	isAuthenticated: userInfoFromStorage !== null ? true : false,
	email: "admin@ecomm.com",
	password: "password",
	loading: false,
	error: "",
};

export const loginUser = createAsyncThunk("login", async (values) => {
	const { email, password } = values;
	const response = await api.post.login({ email, password });

	return response.data;
});

export const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {
		login: (state, action) => {
			const response = action.payload;
			state.isAuthenticated = false;
			state.user = response.result;
			localStorage.setItem("userInfo", JSON.stringify(response.result));
			state.isAuthenticated = true;
			state.loading = false;
		},
		logout: (state) => {
			state.user = null;
			localStorage.setItem("userInfo", null);
			state.isAuthenticated = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loginUser.fulfilled, (state, action) => {
			const response = action.payload;
			if (response.responseCode === 200) {
				adminSlice.caseReducers.login(state, action);
			} else {
				state.error = response.message;
			}
		});

		builder.addCase(loginUser.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(loginUser.rejected, (state, action) => {
			state.loading = true;
			state.error = action.error;
		});
	},
});

export const { login, logout } = adminSlice.actions;

export default adminSlice.reducer;
