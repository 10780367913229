import api from "api/api";
import toast from "react-hot-toast";
import { BiLoader } from "react-icons/bi";
import { findByValue } from "utils/functions";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	loading: false,
	error: "",
	currentPage: 0,
	totalPage: 0,
	categoryList: [],
	filteredCategoryList: [],
	pageSize: 50,
};

export const fetchCategories = createAsyncThunk("productCategory/fetchCategories", async ({ sort, order }) => {
	const response = await api.get.categories({ sort, order });
	return response;
});

export const updateCategory = createAsyncThunk("productCategory/updateCategory", async ({ payload }, thunkApi) => {
	let headers = { "Content-Type": "application/x-www-form-urlencoded" };

	const response = await api.post.updateCategory(payload, headers);

	thunkApi.dispatch(fetchCategories({ sort: "date", order: "desc" }));
	return response;
});

export const createCategory = createAsyncThunk("productCategory/createCategory", async ({ payload }, thunkApi) => {
	let headers = { "Content-Type": "multipart/form-data" };
	
	const response = await api.post.createCategory(payload, headers);

	thunkApi.dispatch(fetchCategories({ sort: "date", order: "desc" }));
	return response;
});

const productCategorySlice = createSlice({
	name: "productCategory",
	initialState,

	reducers: {
		filterCategory: (state, action) => {
			const { value, key } = action.payload;
			if (value !== undefined) {
				state.filteredCategoryList = findByValue(state.categoryList, value, key);
			} else {
				state.filteredCategoryList = state.categoryList;
			}
		},

		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCategories.pending, (state) => {
			state.loading = true;
			state.error = "";
		});

		builder.addCase(fetchCategories.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchCategories.fulfilled, (state, action) => {
			state.loading = false;

			const { data, status } = action.payload;

			if (status === 200) {
				const { categoryList } = data.result;
				state.categoryList = categoryList.map((category) => ({
					id: category.id,
					categoryName: { name: category.name, image: category.image },
					date: new Date(category.createdAt).toDateString(),
					status: category.active,
				}));
			} else {
				state.error = data.message;
			}
		});

		builder.addCase(updateCategory.pending, (state) => {
			state.loading = true;
			state.error = "";
		});

		builder.addCase(updateCategory.rejected, (state, action) => {
			state.loading = false;

			state.error = action.error.message;
		});

		builder.addCase(updateCategory.fulfilled, (state, action) => {
			state.loading = false;

			const { data, status } = action.payload;

			if (status === 200) {
				toast.success("Category updated");
			}
		});
	},
});

export const { filterCategory } = productCategorySlice.actions;

export default productCategorySlice.reducer;
