import MerchantLogo from "appassets/myAccount/profile-picture.png";
import { config } from "common/config";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { NavigationTitle, RoundedButton, Row } from "components/ui/UIComponents";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { fetchStreamDetails } from "redux/streamSlice";
import { streamStatusFormat, preRecordStatusFormat } from "common/utility";
import { BsChevronRight } from "react-icons/bs";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function MerchantStreamsPage() {
	const [popupVisible, setPopupVisible] = useState(false);
	const history = useHistory();
	const { id } = useParams();	
	const [liveList, setLiveList] = useState([]);
	const [preList, setPreList] = useState([]);
	const [storageUsed, setStorageUsed] = useState(0);
	const dispatch = useDispatch();
	const storageLimit = 5;
	const { streamDetails, loading, error } = useSelector((state) => state.streams);
	const [value, setValue] = React.useState(0);

	useEffect(() => {
		let liveList = [];
		let preList = [];

		if (streamDetails?.merchantStreamDetails) {
			liveList = streamDetails?.streamAnnouncementStatusDetails?.liveStreams?.map((stream) => ({
				sellerId: stream.id,
				liveStreamsTitle: stream.title,
				date: new Date(stream.streamDate).toDateString(),
				status: streamStatusFormat(stream.streamStatus).label,
				action: (
					<button onClick={() => handleNavigate(stream.id)}>
						<BsChevronRight size="15" />
					</button>
				),
			}));

			preList = streamDetails?.streamAnnouncementStatusDetails?.preRecordVideo?.map((stream) => ({
				sellerId: stream.id,
				liveStreamsTitle: stream.title,
				date: new Date(stream.streamDate).toDateString(),
				status: preRecordStatusFormat(stream.streamStatus).label,
				action: (
					<button onClick={() => handleNavigate(stream.id)}>
						<BsChevronRight size="15" />
					</button>
				),
			}));

			// if (liveList.length > 0) {
			// 	const totalStorageUsed = liveList.reduce((acc, item) => acc + item.size, 0);

			// 	setStorageUsed(totalStorageUsed);
			// }

			setLiveList(liveList);
			setPreList(preList);
		}
	}, [streamDetails?.merchantStreamDetails]);

	const handleNavigate = (streamId) => {
		history.push(`/admin/streams/details/${streamId}`);
	};

	const sellerDetails = [
		{
			label: "Seller Id",
			value: streamDetails?.sellerDetails?.id,
		},
		{
			label: "Seller Type",
			value: streamDetails?.sellerDetails?.sellerType,
		},
		{
			label: "Person In Charge",
			value: streamDetails?.sellerDetails?.name,
		},
		{
			label: "Email",
			value: streamDetails?.sellerDetails?.personInChargeEmail,
		},
		{
			label: "Mobile No",
			value: streamDetails?.sellerDetails?.contactNumber,
		},
	];

	const customStyles = {
		content: {
			top: "50%",
			left: "55%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: 10,
			width: "30%",
			minWidth: 400,
		},
	};

	const customDropdownStyle = {
		option: (provided) => ({
			...provided,
			fontSize: 13,
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			fontSize: 15,
		}),
		control: (provided) => ({
			...provided,
			width: "100%",
			height: 30,
			fontSize: 12,
			backgroundColor: "#F3F3F6",
		}),
	};

	const navigateBack = () => {
		history.goBack();
	};

	const handleAddStorageLimitModal = () => {
		setPopupVisible(true);
	};

	const handlePickStorageLimit = (selectedItem) => {
		console.log("selectedItem:", selectedItem);
	};

	const handleAddStorageLimit = () => {
		setPopupVisible(false);
	};

	useEffect(() => {
		dispatch(fetchStreamDetails({ sellerId: id, sort: "date", order: "desc" }));
	}, []);

	const sort = (sort, order) => {
		dispatch(fetchStreamDetails({ sellerId: id, sort, order }));
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Spinner show={loading} />
			<div className=" text-black min-h-full min-h-screen p-8">
				{error && <Message variant="danger" message={error} />}
				<NavigationTitle prevPage="Video/Live stream " toPrevPage="/admin/streams" currentPage={streamDetails?.sellerDetails?.companyName} />
				<div className="bg-white rounded-md p-6 mt-8">
					<div className="grid grid-cols-2">
						<div className="flex flex-col">
							<div className="flex flex-row flex-wrap items-center my-4 space-x-4">
								<img className="w-10 h-10" src={MerchantLogo} alt="logo" />
								<div className="font-bold text-lg">{streamDetails?.sellerDetails?.companyName}</div>
							</div>
						</div>
					</div>
					<hr className="my-5" />

					<Row className="w-full divide-x-2">
						<div className="w-1/4">
							<h2 className="bg-primaryBg px-4 py-4 mr-4">Seller Details</h2>

							{sellerDetails.map((details) => (
								<div key={details.label} className="my-4">
									<div>{details.label}</div>
									<div className="font-semibold">{details.value} </div>
								</div>
							))}
						</div>
						<div className="w-3/4">
							<AppBar
								position="static"
								style={{
									background: "none",
									boxShadow: "none",
									color: "black",
									paddingLeft: 20,
									paddingRight: 20,
								}}
							>
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="Video Type"
									TabIndicatorProps={{ style: { background: "#000000" } }}
								>
									<Tab
										disableRipple
										label={
											"Scheduled Live stream (" +
											streamDetails?.streamAnnouncementStatusDetails?.liveStreams?.length +
											")"
										}
										{...a11yProps(0)}
										style={
											{ boxShadow: "none",maxWidth:'none', width:'50%',backgroundColor: value === 0 ? "#E6E6E6": "#ffffff" }
										}
									/>
									<Tab
										disableRipple
										label={"Pre-recorded videos (" + streamDetails?.streamAnnouncementStatusDetails?.preRecordVideo?.length + ")"
									}
										{...a11yProps(1)}
										className={value === 1 ? "" : ""}
										style={
											{ boxShadow: "none",maxWidth:'none', width:'50%',backgroundColor: value === 1 ? "#E6E6E6": "#ffffff" }
										}
									/>
								</Tabs>
							</AppBar>
							<TabPanel value={value} index={0} className="bg-white streamTab">
								<div className="p-5 h-full flex flex-wrap content-between">
									<TableComponent
										headers={config.streamDetailsPage.detailHeader}
										rows={liveList}
										sortFunction={sort}
										hasButton={true}
									/>
								</div>
							</TabPanel>
							<TabPanel value={value} index={1} className="bg-white streamTab">
								<div className="p-5 h-full flex flex-wrap content-between">
									<TableComponent
										headers={config.streamDetailsPage.preRecordDetailHeader}
										rows={preList}
										sortFunction={sort}
										hasButton={true}
									/>
								</div>
							</TabPanel>
						</div>
					</Row>
				</div>
				<Modal isOpen={popupVisible} onRequestClose={() => setPopupVisible(false)} style={customStyles}>
					<div className="pl-4 pb-4 m-4">
						<div className="flex justify-end">
							<button onClick={() => setPopupVisible(false)}>
								<MdClose size="24" color="gray" />
							</button>
						</div>
						<div className="pb-3 font-semibold">Upgrade live-stream storage</div>
						<div className="pb-4 font-medium">
							Storage balance: <span className="text-red-600">0 GB</span>
						</div>
						<div className="pb-6 border-b-2">Store name: FruitLab</div>
						<div className="pt-4 pb-2">Storage limit</div>
						<Select
							styles={customDropdownStyle}
							onChange={(item) => handlePickStorageLimit(item)}
							defaultValue={config.liveStream.storageLimit[0]}
							options={config.liveStream.storageLimit}
						/>
						<div className="text-xs">Update the storage by select the storage size</div>
						<div className="flex justify-center mt-8">
							<RoundedButton label="CONFIRM UPGRADE" dark onClick={() => handleAddStorageLimit()} />
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
}
