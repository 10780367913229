import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import CsvDownload from "react-json-to-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { fetchMonthlySettlements, filterSettlements, setCurrentPage } from "redux/monthlySettlementSlice";
import { config } from "common/config";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { SearchInput } from "components/ui/UIComponents";
import { currencyFormat, MonthStringFormat } from "common/utility";
import Pagination from "components/pagination/pagination";

const AdminStreamsPage = () => {
	const today = new Date();
	const monthlySettlement = localStorage.getItem('monthlySettlement');
	const history = useHistory();
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(0);
	const [list, setList] = useState(null);
	const [search, setSearch] = useState("");
	const [yearInsert, setYearInsert] = useState(today.getFullYear());
	const [sort, setSort] = useState({
        key: "",
        order: ""
    });

	const { loading, error, monthlySettlements, filteredMonthlySettlements, currentPage, totalPage } = useSelector(
		(state) => state.monthlySettlement
	);

	const pageConfig = config.monthlyStatementPage.pageConfig;

	useEffect(() => {
		const date = new Date();
		let filterIndex = updateFiltersIndex(selected);
		let filters = updateFilters(selected);

		if (yearInsert) {
			dispatch(
				fetchMonthlySettlements({
					currentPage,
					search,
					filters: filters,
					values: selected == 0 ? `${yearInsert}` : `${filterIndex}, ${yearInsert}`,
					sort: sort.key,
					order: sort.order,
				})
			);
		}
	}, [selected, yearInsert, currentPage]);

	useEffect(() => {
		mapColumns(monthlySettlements);
	}, [monthlySettlements]);

	useEffect(() => {
		mapColumns(filteredMonthlySettlements);
	}, [filteredMonthlySettlements]);

	function mapColumns(array) {
		const statements = array.map((statement) => ({
			...statement,
			date: moment(statement.date).format('MMM YYYY'),
			totalSales: currencyFormat(parseFloat(statement.totalSales)),
			serviceFee: currencyFormat(parseFloat(statement.serviceFee)),
			action: (
				<button onClick={() => navigateToDetails(statement.sellerId, moment(statement.date).format('MMM'))}>					
					<BsChevronRight size="15" />
				</button>
			),
		}));

		setList(statements);
	}

	const searchList = async (value, key) => {
		setSearch(value)

		dispatch(
			fetchMonthlySettlements({
				currentPage,
				search: value,
			})
		);
	};

	const navigateToDetails = (id, date) => {
		date = MonthStringFormat(date).label;
		history.push(`/admin/monthly-settlement/${id}/${date}/`);
	};

	const handleSelect = (filter) => {
		localStorage.setItem('monthlySettlement', filter);
		setSelected(filter);
		setSearch("")
		dispatch(setCurrentPage(0));
		// dispatch(filterSettlements(filter));
	};

	const updateFiltersIndex = (value) => {
		return value === 0 ? 0 : value - 1;
	};

	const updateFilters = (value) => {
		return value === 0 ? "year" : "month,year";
	};

	const handleSort = (sort, order) => {
		console.log("sort:" + JSON.stringify(sort));
		console.log("order:" + JSON.stringify(order));
		console.log("selected:" + JSON.stringify(selected));
		let filterIndex = updateFiltersIndex(selected);
		let filters = updateFilters(selected);

		setSort({
            key: sort,
            order: order
        })

		dispatch(
			fetchMonthlySettlements({
				currentPage,
				search,
				filters: filters,
				values: selected == 0 ? `${yearInsert}` : `${filterIndex}, ${yearInsert}`,
				sort,
				order,
			})
		);
	};

	const handlePagination = (value) => {
		let filterIndex = updateFiltersIndex(selected);
		let filters = updateFilters(selected);
		const currentIndex = Math.max(0, Math.min(currentPage + value, totalPage - 1));
		dispatch(setCurrentPage(currentIndex));

		dispatch(
			fetchMonthlySettlements({
				currentPage: currentIndex,
				search,
				filters: filters,
				values: selected == 0 ? `${yearInsert}` : `${filterIndex}, ${yearInsert}`,
				sort: sort.key,
				order: sort.order,
			})
		);
	};

	const handleSpecificPagination = (value) => {
		let filterIndex = updateFiltersIndex(selected);
		let filters = updateFilters(selected);
		dispatch(setCurrentPage(value));

		dispatch(
			fetchMonthlySettlements({
				currentPage: value,
				search,
				filters: filters,
				values: selected == 0 ? `${yearInsert}` : `${filterIndex}, ${yearInsert}`,
				sort: sort.key,
				order: sort.order,
			})
		);
	};

	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					<Spinner show={loading} />
					<div className="text-black min-h-screen p-8">
						{error && <Message variant="danger" message={error} />}
						<div className="flex justify-between">
							<div className="w-2/4">
								<SearchInput
									placeholder="Search Seller ID, Store Name"
									icon={<FiSearch size="18" />}
									value={search}
									onChange={(e) => searchList(e.target.value)}
								/>
							</div>
							<div>
								<CsvDownload
									data={monthlySettlements}
									className="btn-form px-4"
									filename="monthlystatement.csv"
								>
									EXPORT DATA
								</CsvDownload>
							</div>
						</div>

						<div className="flex flex-row justify-between">
							<div className="flex flex-row my-6 divide-x-2 space-x-4 divide-gray-500">
								{pageConfig.filters.map((filter, index) => (
									<div
										key={filter + index}
										className={`pr-2 pl-6 cursor-pointer ${
											selected === index ? "text-gray-800 font-semibold" : "m-txt--lightGrey"
										}`}
										onClick={() => handleSelect(index)}
									>
										{filter}
									</div>
								))}
							</div>
							<div className="flex items-center">
								<div>
									<span className="font-bold p-4">Year</span>
									<input
										className="w-20 p-2"
										maxLength={4}
										value={yearInsert}
										type="number"
										onChange={(e) => setYearInsert(parseInt(e.target.value))}
									/>
								</div>
							</div>
						</div>

						<TableComponent
							headers={config.monthlyStatementPage.statementHeader}
							rows={list}
							sortFunction={handleSort}
							hasButton={true}
						/>

						<div className="flex flex-row items-center justify-end my-2">
							<button onClick={() => handlePagination(-1)}>
								<AiFillCaretLeft size="16" color={currentPage == 0 ? "#D2D2D2" : "#000000"}/>
							</button>

							<Pagination totalPages={totalPage} currentPage={currentPage + 1} onPageClick={handleSpecificPagination}/>

							<button onClick={() => handlePagination(+1)}>
								<AiFillCaretRight size="16" color={currentPage == totalPage - 1  ? "#D2D2D2" : "#000000"}/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminStreamsPage;
