import React, { useState, useEffect } from "react";
import { BsChevronRight, BsFillPlusSquareFill, BsCircle, BsCheck } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { dateFormat, currencyFormat, orderStatusFormat } from "common/utility";
import moment from "moment";
import { FiCheck } from "react-icons/fi";
import { CgClose } from "react-icons/cg";

export function Card() {
	return <div className="rounded-lg"></div>;
}

export function Title({ label }) {
	return (
		<div>
			<span className="text-3xl font-bold">{label}</span>
		</div>
	);
}

export function NavigationTitle({ prevPrevPage, prevPage, currentPage, toPrevPage, toPrevPrevPage }) {
	return (
		<div>
			{prevPrevPage ? (
				<span>
					<Link to={toPrevPrevPage}>
						<span>{prevPrevPage}</span>
					</Link>
					<span> / </span>
				</span>
			) : null}
			<Link to={toPrevPage}>
				<span>{prevPage}</span>
			</Link>
			<span> / </span>
			<span className="m-txt m-txt--bold">{currentPage}</span>
		</div>
	);
}

export function Input(props) {
	const {
		style,
		min,
		id,
		value,
		type,
		onChange,
		placeholder,
		className = "mt-2 flex w-full px-5  py-4 text-gray-700 bg-gray-200 rounded",
		onBlur,
	} = props;
	return (
		<div className="mb-4">
			<input
				min={min}
				id={id}
				type={type}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				className={className}
				onBlur={onBlur}
				style={style}
			/>
		</div>
	);
}

export function SearchInput(props) {
	const { id, value, type, placeholder, onChange, icon } = props;
	return (
		<div className="flex flex-row flex w-full justify-center items-center bg-white px-4 m-search">
			{icon}
			<input
				id={id}
				type={type}
				value={value}
				onChange={onChange}
				// onChange={(value) => onChange(value.target.value)}

				placeholder={placeholder}
				className="m-1 flex w-full px-5  py-1 text-gray-700 rounded"
			/>
		</div>
	);
}

export function Row(props) {
	return (
		<div
			className={`flex flex-wrap flex-row
   w-full ${props.className}`}
		>
			{props.children}
		</div>
	);
}

export function DashboardTile(props) {
	return (
		<div className="text-white rounded-md md:w-1/3  items-center flex flex-col py-5 justify-center max-w-xs w-full bg-gradient-to-b from-tileColorTop to-tileColorBottom mx-2 my-2">
			{props.superscript.length > 0 ? (
				<div className="mr-auto pl-6">{props.superscript}</div>
			) : (
				<div className="w-1/2 invisible">text</div>
			)}

			<div className="text-white text-3xl font-extrabold">{props.value}</div>
			<div className="text-base">{props.subtitle}</div>
		</div>
	);
}

export function RoundedIcon(props) {
	return (
		<div className="w-10 h-10 bg-gray-500 items-center flex justify-center rounded-full">
			<div className="w-5 h-5">{props.children} </div>
		</div>
	);
}
export function InputGroup(props) {
	return <div className="w-full">{props.children}</div>;
}

export function Label(props) {
	return (
		<label htmlFor="name" className="block text-sm text-gray-600">
			{props.children}
		</label>
	);
}

export function ALink(props) {
	return (
		<a href={props.href} className="underline mx-1">
			{props.children}
		</a>
	);
}

export function Select(props) {
	const { id, value, onChange, placeholder } = props;
	return (
		<select
			id={id}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			className="mt-2  appearance-none bg-gray-200 w-full text-gray-700 px-5  py-4 rounded"
		>
			{props.children}
		</select>
	);
}

export function Table(props) {
	return (
		<div className="rounded-md overflow-hidden shadow rounded-lg">
			<table className="min-w-full">
				<thead className="text-white bg-tableHeaderColor rounded-t-lg">
					<tr>
						{props.headers.map((header) => (
							<th
								key={header}
								className="px-6 py-3 border-b-2 leading-4 tracking-wider text-base border-2 border-gray-400"
							>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{props.products ? (
						<ProductTableRow
							products={props.products}
							onClickStockNumber={(item) => props.onClickStockNumber(item)}
							onClickTableRow={(productId) => props.onClickTableRow(productId)}
						/>
					) : null}
					{props.orderList ? (
						<OrdersTableList
							orderList={props.orderList}
							onClickToOrderDetails={(orderId) => props.onClickToOrderDetails(orderId)}
						/>
					) : null}
					{props.showLiveStreamProducts ? (
						<ShowLiveStreamProductsTableRow showLiveStreamProducts={props.showLiveStreamProducts} />
					) : null}
					{props.liveStreamList ? <LiveStreamListTableRow liveStreamList={props.liveStreamList} /> : null}
					{props.liveStreamListPast ? (
						<LiveStreamListPastTableRow liveStreamListPast={props.liveStreamListPast} />
					) : null}
					{props.productReviews ? (
						<AccountProductReviews
							onClick={(item) => props.onClick(item)}
							productReviews={props.productReviews}
						/>
					) : null}
					{props.ordersHistoryList ? (
						<OrdersHistoryTableList
							ordersHistoryList={props.ordersHistoryList}
							onClickToOrdersHistoryDetails={(orderId) => props.onClickToOrdersHistoryDetails(orderId)}
						/>
					) : null}
					{props.liveStreamProducts ? (
						<LiveStreamProductsTableRow
							onClick={(item) => props.onClick(item)}
							liveStreamProducts={props.liveStreamProducts}
							getSelectedProduct={props.getSelectedProduct}
						/>
					) : null}
					{props.newOrders ? (
						<NewOrdersTableRow
							newOrders={props.newOrders}
							onClick={(orderId, orderStatus) => props.onClick(orderId, orderStatus)}
						/>
					) : null}
				</tbody>
			</table>
		</div>
	);
}

export function NewOrdersTableRow(props) {
	return (
		<>
			{props.newOrders.map((item, index) => (
				<tr
					className="bg-white border-gray-200 hover:bg-gray-300 cursor-pointer"
					key={item.name + index}
					onClick={() => props.onClick(item.id, item.status)}
				>
					<td className="px-6 py-4 border-2 border-gray-200">
						<div className="flex flex-row max-w-lg">
							<img className="rounded-full h-10 w-10 mr-4 self-center" src={item.userImage} alt="" />
							<div className="text-sm text-gray-800 self-center break-word font-medium">
								{item.deliveryName}
							</div>
						</div>
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600">
						{dateFormat(item.createdAt)}
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium">
						{item.orderNumber}
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium">
						<span className={`text-sm text-${orderStatusFormat(item.status).color}-500`}>
							{orderStatusFormat(item.status).label}
						</span>
					</td>
					<td className="border-2 border-gray-200 py-3">
						<div className="justify-center">
							<BsChevronRight className="mx-auto" />
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

export function ProductTableRow(props) {
	return (
		<>
			{props.products.map((item, index) => (
				<tr
					className={`${
						item.status === 0 ? "bg-white border-gray-200 opacity-25" : "bg-white border-gray-200"
					} hover:bg-gray-300 cursor-pointer active:opacity-50`}
					key={item.name + index}
				>
					<td
						className="px-6 py-4 border-2 border-gray-200 w-4/12"
						onClick={() => props.onClickTableRow(item.id)}
					>
						<div className="flex flex-row max-w-lg">
							<img
								className="rounded-full h-10 w-10 mr-4 self-center"
								src={item.productImages[0]}
								alt=""
							/>
							<div className="text-sm text-gray-800 self-center break-word font-medium">{item.name}</div>
						</div>
					</td>
					<td
						className="px-6 py-4 border-2 border-gray-200 w-2/12"
						onClick={() => props.onClickTableRow(item.id)}
					>
						<div className="text-sm text-gray-800 text-center break-word font-medium">
							{item.categoryName}
						</div>
					</td>
					<td
						className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600 w-2/12"
						onClick={() => props.onClickTableRow(item.id)}
					>
						{dateFormat(item.createdAt)}
					</td>
					<td
						className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600 w-2/12"
						onClick={() => props.onClickTableRow(item.id)}
					>
						{dateFormat(item.updatedAt)}
					</td>
					<td
						className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium w-2/12"
						onClick={() => props.onClickStockNumber(item)}
					>
						<div
							className={`flex flex-row items-center justify-center ${
								item.stock <= 10 && item.stock > 0
									? "text-orange-500"
									: item.stock === 0
									? "text-red-500"
									: "text-green-500"
							} `}
						>
							{item.stock === 0 ? "No Stock" : item.stock <= 10 ? `${item.stock} Low Stock` : item.stock}
							<div className="ml-2">
								<BsFillPlusSquareFill size={16} />
							</div>
						</div>
					</td>
					<td
						className="px-6 py-4 text-sm border-2 border-gray-200 text-right font-medium w-1/12"
						onClick={() => props.onClickTableRow(item.id)}
					>
						<span className="text-sm">RM {currencyFormat(item.price)}</span>
					</td>
					<td className="border-2 border-gray-200 py-3" onClick={() => props.onClickTableRow(item.id)}>
						<div className="justify-center">
							<BsChevronRight className="mx-auto" />
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

export function OrdersTableList(props) {
	return (
		<>
			{props.orderList.map((item, index) => (
				<tr
					className="bg-white border-gray-200 hover:bg-gray-300 cursor-pointer"
					key={item.name + index}
					onClick={() => props.onClickToOrderDetails(item.id)}
				>
					<td className="px-6 py-4 border-2 border-gray-200">
						<div className="flex flex-row max-w-lg">
							<img className="rounded-full h-10 w-10 mr-4 self-center" src={item.userImage} alt="" />
							<div className="text-sm text-gray-800 self-center break-word font-medium">
								{item.deliveryName}
							</div>
						</div>
					</td>
					<td className="px-6 py-4 border-2 border-gray-200 break-word w-2">
						<div className="text-sm text-gray-800 text-center break-word font-medium">{item.category}</div>
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600">
						{dateFormat(item.createdAt)}
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium">
						{item.orderNumber}
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium">
						<span className={`text-sm text-${orderStatusFormat(item.status).color}-500`}>
							{orderStatusFormat(item.status).label}
						</span>
					</td>
					<td className="border-2 border-gray-200 py-3">
						<div className="justify-center">
							<BsChevronRight className="mx-auto" />
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

export function OrdersHistoryTableList(props) {
	return (
		<>
			{props.ordersHistoryList.map((item, index) => (
				<tr
					className="bg-white border-gray-200 hover:bg-gray-300 cursor-pointer"
					key={item.name + index}
					onClick={() => props.onClickToOrdersHistoryDetails(item.id)}
				>
					<td className="px-6 py-4 border-2 border-gray-200">
						<div className="flex flex-row max-w-lg">
							<img className="rounded-full h-10 w-10 mr-4 self-center" src={item.userImage} alt="" />
							<div className="text-sm text-gray-800 self-center break-word font-medium">
								{item.deliveryName}
							</div>
						</div>
					</td>
					<td className="py-4 border-2 border-gray-200 break-word w-2/12">
						<div className="text-sm text-gray-800 text-center break-word font-medium">
							{dateFormat(item.createdAt)}
						</div>
					</td>
					<td className="py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600 w-2/12">
						{item.orderNumber}
					</td>
					<td className="py-4 text-sm border-2 border-gray-200 text-center font-medium w-1/12">
						<span className={`text-sm text-${orderStatusFormat(item.status).color}-500`}>
							{orderStatusFormat(item.status).label}
						</span>
					</td>
					<td className="px-2 text-sm border-2 border-gray-200 text-center font-medium w-1/12">
						RM {currencyFormat(item.totalAmount)}
					</td>
					<td className="border-2 border-gray-200 py-3 w-2">
						<div className="justify-center">
							<BsChevronRight className="mx-auto" />
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

export function LiveStreamProductsTableRow(props) {
	return (
		<>
			{props.liveStreamProducts.map((item, index) => {
				const selectedThisProduct = props.getSelectedProduct(item);
				if (item.status === 1 && item.stock > 0) {
					return (
						<tr
							className="bg-white border-gray-200 hover:bg-gray-200 cursor-pointer"
							key={item.name + index}
							onClick={() => props.onClick(item)}
						>
							<td className="px-6 py-4 border-2 border-gray-200">
								<div className="flex flex-row max-w-lg">
									<img
										className="rounded-full h-10 w-10 mr-4 self-center"
										src={item.productImages[0]}
										alt=""
									/>
									<div className="text-sm text-gray-800 self-center break-word font-medium">
										{item.name}
									</div>
								</div>
							</td>
							<td className="px-6 py-4 border-2 border-gray-200 break-word w-2">
								<div className="text-sm text-gray-800 text-center break-word font-medium">
									{item.categoryName}
								</div>
							</td>
							<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600">
								{item.sku}
							</td>
							<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium">
								{item.stock}
							</td>
							<td className="px-6 py-4 text-sm border-2 border-gray-200 font-medium">
								<div className="text-sm flex justify-end">
									RM {currencyFormat(item.price)}
									<div className="ml-4">
										{selectedThisProduct ? (
											<BsCheck className="text-gray-600" size={22} />
										) : (
											<BsCircle className="text-gray-600" />
										)}
									</div>
								</div>
							</td>
						</tr>
					);
				} else {
					return null;
				}
			})}
		</>
	);
}

export function ShowLiveStreamProductsTableRow(props) {
	return (
		<>
			{props.showLiveStreamProducts.map((item, index) => (
				<tr className="bg-white border-gray-200" key={item.name + index}>
					<td className="px-6 py-4 border-2 border-gray-200">
						<div className="flex flex-row max-w-lg">
							<img
								className="rounded-full h-10 w-10 mr-4 self-center"
								src={item.productImages[0]}
								alt=""
							/>
							<div className="text-sm text-gray-800 self-center break-word font-medium">{item.name}</div>
						</div>
					</td>
					<td className="px-6 py-4 border-2 border-gray-200 break-word w-2">
						<div className="text-sm text-gray-800 text-center break-word font-medium">
							{item.categoryName}
						</div>
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600">
						{item.sku}
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium">{item.stock}</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 font-medium">
						<div className="text-sm flex justify-end">RM {currencyFormat(item.price)}</div>
					</td>
				</tr>
			))}
		</>
	);
}

export function LiveStreamListTableRow(props) {
	return (
		<>
			{props.liveStreamList.map((item, index) => {
				const currentDate = moment().subtract(24, "hours");
				const selectedDate = moment(item.streamDate);
				if (selectedDate.isAfter(currentDate)) {
					return (
						<tr className="bg-white border-gray-200" key={item.id + index}>
							<td className="px-6 py-4 border-2 border-gray-200">
								<div className="flex flex-row max-w-lg">
									<img className="rounded-full h-10 w-10 mr-4 self-center" src={item.image} alt="" />
									<div className="text-sm text-gray-800 self-center break-word font-medium">
										{item.id}
									</div>
								</div>
							</td>
							<td className="px-6 py-4 border-2 border-gray-200 break-word w-28">
								<div className="text-sm text-gray-800 text-center break-word font-medium">
									{dateFormat(item.date)}
								</div>
							</td>
							<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600 w-28">
								<div className="text-sm text-gray-800 text-center break-word font-medium">
									{item.merchant}
								</div>
							</td>
						</tr>
					);
				} else {
					return null;
				}
			})}
		</>
	);
}

export function LiveStreamListPastTableRow(props) {
	return (
		<>
			{props.liveStreamListPast.map((item, index) => (
				<tr className="bg-white border-gray-200" key={item.name + index}>
					<td className="px-6 py-4 border-2 border-gray-200">
						<div className="flex flex-row max-w-lg">
							<img className="rounded-full h-10 w-10 mr-4 self-center" src={item.image} alt="" />
							<div className="text-sm text-gray-800 self-center break-word font-medium">{item.title}</div>
						</div>
					</td>
					<td className="px-6 py-4 border-2 border-gray-200 break-word w-28">
						<div className="text-sm text-gray-800 text-center break-word font-medium">
							{dateFormat(item.streamDate)}
						</div>
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600 w-28">
						<div className="text-sm text-gray-800 text-center break-word font-medium">
							{item.streamTime}
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

export function AccountProductReviews(props) {
	return (
		<>
			{props.productReviews.map((item, index) => (
				<tr
					onClick={() => props.onClick(item)}
					className="bg-white border-gray-200 cursor-pointer hover:bg-gray-300"
					key={item.name + index}
				>
					<td className="px-6 py-4 border-2 border-gray-200 w-4/6">
						<div className="flex flex-row max-w-lg">
							<img
								className="rounded-full h-10 w-10 mr-4 self-center object-cover"
								src={item.productImage}
								alt=""
							/>
							<div>
								<div className="text-sm text-gray-800 break-word font-medium">{item.productName}</div>
								<div className="text-xs m-txt--lightGrey break-word">
									{dateFormat(item.lastCommentAt)}
								</div>
							</div>
						</div>
					</td>
					<td className="px-6 py-4 border-2 border-gray-200">
						<div className="justify-end flex">
							<RatingDisplay ratings={item.rating} />
						</div>
					</td>
					<td className="px-6 py-4 text-sm border-2 border-gray-200 text-center font-medium text-gray-600 w-28">
						<div className="text-sm text-gray-800 text-center break-word font-medium flex flex-row items-center justify-around">
							<div className="bg-green-500 text-white px-2 rounded-full text-xs">
								{item.numberOfReviews > 99 ? "99+" : item.numberOfReviews}
							</div>{" "}
							<BsChevronRight />
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

export const RatingDisplay = ({ ratings, size = 18 }) => (
	<div className={"flex items-center"}>
		<p className={"text-gray-600 mr-4"}>
			<span className={"text-lg font-semibold text-black"}>{ratings}</span>/5
		</p>
		<RatingPicker size={size} value={ratings} />
	</div>
);

export const RatingPicker = ({ className = "", value = 0, onChange = null, size = 16, forFilterUse = false }) => {
	let stars = [];
	for (let a = 0; a < 5; ++a) {
		stars.push(
			<div
				key={a}
				className={`
        ${!onChange ? "cursor-pointer" : ""}
        ${a < value ? "text-yellow-500" : "text-gray-400"}`}
				onClick={!onChange ? () => onChange(a + 1) : null}
			>
				<FaStar size={size} />
			</div>
		);
	}
	return (
		<div className={`flex space-x-2 items-center text-sm ${className}`}>
			{stars}
			{!!forFilterUse && <p>& Up</p>}
		</div>
	);
};

export const AddPhotoButton = ({ onClick, type }) => {
	let className;
	if (type === "thin") {
		className = "w-full h-16 border-dashed border-2 rounded-md border-gray-400 hover:shadow-none";
	} else {
		className = "w-32 h-32 border-dashed border-2 rounded-md border-gray-400 hover:shadow-none";
	}
	return (
		<div className="flex flex-column">
			<button onClick={onClick} className={className}>
				<BiPlus className="mx-auto" size="26" color="#BBBBBB" />
			</button>
		</div>
	);
};

export const FormLabelInput = ({ placeholder, onChange, maxlength }) => {
	return (
		<div>
			<input
				className="border-2 rounded-md py-1 px-2 w-full mt-1 border-gray-400 text-sm"
				placeholder={placeholder}
				onChange={onChange}
				maxLength={maxlength}
			/>
		</div>
	);
};

export const RoundedButton = ({
	className = "",
	label = "Label",
	type = "button",
	onClick = null,
	linkTo = null,
	dark = false,
	gray = false,
	gold = false,
	danger = false,
	sm = false,
	disabled = false,
	innerWidget = null,
}) => {
	const btnClassName = `
    button rounded-full py-2
    flex justify-center
    ${sm ? "px-4" : "px-12 h-10"}
    ${dark ? `bg-primary text-yellow-500` : ""}
    ${gray ? `bg-gray-200 text-black` : ""}
    ${danger ? `border-1 border-red-500 text-red-500` : ""}
    ${gold ? `bg-darkGold text-white` : ""}
    ${!dark && !gray && !gold ? "border-2 border-gray-400" : "border-2 border-transparent"}
    ${disabled ? "hover:shadow-none" : ""}
    ${className}
  `;

	const labelUI = (
		<div
			className={`
      font-medium
      ${sm ? "text-xs" : "text-sm"}`}
		>
			{label}
		</div>
	);

	return (
		<div className={innerWidget ? "relative" : ""}>
			{!!linkTo && !disabled ? (
				<Link
					className={`
          hover:shadow-md
          ${btnClassName}`}
					to={linkTo}
				>
					{labelUI}
				</Link>
			) : (
				<button
					// @ts-ignore
					type={type}
					className={btnClassName}
					onClick={onClick}
					disabled={disabled}
					style={{
						opacity: disabled ? 0.5 : 1.0,
					}}
				>
					{labelUI}
				</button>
			)}
			{innerWidget}
		</div>
	);
};

export function ToggleButton({ onClick, activated }) {
	const [active, setActive] = useState(activated);

	useEffect(() => {
		setActive(activated);
	}, [activated]);

	const handleToggle = () => {
		setActive(!active);
		onClick(!active);
	};

	return (
		<button
			type="button"
			onClick={handleToggle}
			className="hover:shadow-none flex flex-row items-center rounded-full px-0.5 border-2 border-gray-400 h-10"
		>
			{active ? (
				<>
					<span className="text-green-500 text-s mx-2">ACTIVE </span>
					<div className="bg-green-500 p-1 mr-1 rounded-full">
						<FiCheck color="white" size="23" />
					</div>
				</>
			) : (
				<>
					<div className="bg-gray-500 p-1 ml-1 rounded-full">
						<CgClose color="white" size="23" />
					</div>
					<span className="m-txt--lightGrey text-s mx-1">INACTIVE </span>
				</>
			)}
		</button>
	);
}

export const ImageInput = ({ onDrop, onClick, onDragOver, inputItem, type, device }) => {
	return (
		<div
			className="w-full h-48 border-dashed border-4 border-gray-200 justify-center flex mx-auto text-center "
			onDrop={onDrop}
			onDragOver={onDragOver}
		>
			<div className="grid grid-cols-1 place-content-around w-full">
				{inputItem ? (
					<div className="justify-center flex">
						<img className="h-24" src={inputItem} alt="icon" />
					</div>
				) : (
					<div className="pt-8">
					{type == "banner" ? (
							<>
								<p className="font-medium" style={{color:"#999999"}}>
									Drag the banner here
								</p>
								<p className="font-medium mt-2" style={{color:"#999999"}}>
									Or
								</p>
							</>
						) : (
							<>
								Drag the image here <br /> Or
							</>
						)}
					</div>
				)}
				<div className={type == "banner" ? "mb-8": null}>
					<input
						multiple
						id={"upload"+device+"Image"}
						className="hidden"
						accept="image/jpeg, image/png, image/bmp"
						type="file"
						onChange={onClick}
					/>
					<label
						htmlFor={"upload"+device+"Image"}
						className="btn-outline cursor-pointer"
						style={{ color: "#666666" }}
					>
						Browse
					</label>
				</div>
			</div>
		</div>
	);
};
/**
 * Copy component for Footer
 */

export function XPadding({ children }) {
	return <div className="px-6">{children}</div>;
}
export function YPadding({ children }) {
	return <div className="py-2">{children}</div>;
}

export const ListButtons = ({ selected, ...props }) => {
	return (
		<button
			className={`font-semibold text-sm  h-10  border-2 border-gray-200  items-center justify-center px-5 flex ${
				selected ? "bg-black text-white" : "bg-white text-black"
			} `}
		>
			{props.children}
		</button>
	);
};
