import React from "react";

export const Message = ({ variant, message }) => {
  switch (variant) {
    case "info":
      return (
        <div className="px-6 py-2 rounded-md text-white bg-blue-500 font-semibold w-1/2 my-4">
          {message}
        </div>
      );

    case "danger":
      return (
        <div className="px-6 py-2 rounded-md text-white bg-red-500 font-semibold w-1/2 my-4">
          {message}
        </div>
      );

    case "warning":
      return (
        <div className="px-6 py-2 rounded-md text-black bg-yellow-500 font-semibold w-1/2 my-4">
          {message}
        </div>
      );

    default:
      return (
        <div className="px-2 py-2 rounded-md text-white bg-blue-500 font-semibold w-1/2 my-4">
          {message}
        </div>
      );
  }
};
