import React, { useEffect, useState } from "react";

const StatusText = ({ color, status, text, ...props }) => {
  const [bgColor, setbgColor] = useState("");

  useEffect(() => {
    switch (status) {
      case "success":
        setbgColor("text-green-400");
        break;
      case "danger":
        setbgColor("text-red-500");
        break;
      case "warning":
        setbgColor("text-yellow-600");
        break;
      case "inactive":
        setbgColor("text-gray-400");
        break;

      default:
        break;
    }
  }, [status]);

  return (
    <div
      className={` ${bgColor}  ${color} px-6 py-1 text-sm font-semibold rounded-full mx-2 `}
      {...props}
    >
      {text}
    </div>
  );
};

export default StatusText;
