import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import logo from 'appassets/navbar/logo.png';
import { routes } from 'routes/routes';

const SideNav = () => {
    const sideNavRef = useRef();
    const sideNavItem = useRef();
    const location = useLocation();
    const [active, setActive] = useState(false);

    const onHandleToggle = () => {
        setActive(!active);

        if (!active) {
            sideNavRef.current.classList.add('side-nav--shrink');
            sideNavRef.current.classList.remove('side-nav--expand');
            sideNavItem.current.classList.remove('side-nav__lists--expand');
            sideNavItem.current.classList.add('side-nav__lists--shrink');
        } else {
            sideNavRef.current.classList.remove('side-nav--shrink');
            sideNavRef.current.classList.add('side-nav--expand');
            sideNavItem.current.classList.add('side-nav__lists--expand');
            sideNavItem.current.classList.remove('side-nav__lists--shrink');
        }
    };

    return (
        <div className="side-nav" ref={sideNavRef}>
            <div className="side-nav__action cursor-pointer" onClick={onHandleToggle}>
                {active ? <BsArrowRight size={28} /> : <BsArrowLeft size={28} />}
            </div>
            <div className="side-nav__logo">
                <Link to="/dashboard">
                    <img src={logo} alt="" style={{width: "150px"}}/>
                </Link>
            </div>
            <ul className="side-nav__lists" ref={sideNavItem}>
                {routes.map((o, i) => {
                    const key = `${i}-item`;
                    const active = location.pathname === `/admin${o.path}`;
                    const itemClass = active
                        ? 'side-nav__item side-nav__item--active'
                        : 'side-nav__item';
                    return o.showOnSideBar ? (
                        <Link to={`/admin${o.path}`} key={key} className="side-nav__link">
                            <li className={itemClass}>
                                {o.icon}
                                <p className="m-txt">{o.name}</p>
                            </li>
                        </Link>
                    ) : null;
                })}
            </ul>
        </div>
    );
};

export default SideNav;
