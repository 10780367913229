import api from "api/api";
import toast from "react-hot-toast";
import { findByValue } from "utils/functions";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	loading: false,
	error: "",
	currentPage: 0,
	totalPage: 0,
	bannerList: [],
	filteredBanners: [],
	sort: "",
	filter: "",
	value: "",
	pageSize: 50,
	bannerDetails: {
		sellerDetails: null,
		mobileImage: null,
		desktopImage: null,
		status: null,
		bannerLaunchDate: null,
		bannerExpiryDate: null,
	},
};

export const fetchAds = createAsyncThunk("ad/fetchAds", async ({ sort, filters, values, page, size, order }) => {
	const response = await api.get.getBannerList({
		sort,
		filters,
		values,
		page,
		size,
		order,
	});
	return response;
});

export const fetchAdDetails = createAsyncThunk("ad/fetchAdDetails", async (bannerId) => {
	const response = await api.get.getBannerDetails({ bannerId });
	return response;
});

export const verifyBanner = createAsyncThunk("ad/approveBanner", async ({ bannerId, message, status }) => {
	let headers = { "Content-Type": "application/json" };

	let payload = {
		bannerId,
		status,
		additionalMessage: message,
	};
	const response = await api.post.verifyBanner(payload, headers);

	return response;
});

export const setBannerFee = createAsyncThunk("ad/setBannerFee", async (payload) => {
	const response = await api.post.setBannerFee(payload);
	return response;
});

const adSlice = createSlice({
	name: "ads",
	initialState,

	reducers: {
		filterOrders: (state, action) => {
			state.filteredOrders = findByValue(state.orders, action.payload);
		},
		filterByStatus: (state, action) => {
			if (action.payload === null) {
				state.filteredOrders = state.orders;
			} else {
				state.filteredOrders = state.orders.filter((o) => o.status === action.payload);
			}
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAds.pending, (state) => {
			state.loading = true;
			state.error = "";
		});

		builder.addCase(fetchAds.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchAds.fulfilled, (state, action) => {
			state.loading = false;
			console.log(action);

			const { data, status } = action.payload;

			if (status === 200) {
				const { bannerDetails } = data.result;
				state.bannerList = bannerDetails.reverse();
			} else {
				state.error = data.message;
			}
		});

		builder.addCase(fetchAdDetails.pending, (state) => {
			state.verifyBannerSuccess = null;
			state.loading = true;
			state.error = "";
			state.orderDetails = {
				sellerDetails: null,
				productDetailsList: null,
				ordersDetails: null,
			};
		});

		builder.addCase(fetchAdDetails.rejected, (state, action) => {
			state.loading = false;
			state.verifyBannerSuccess = null;

			state.error = action.error.message;
		});

		builder.addCase(fetchAdDetails.fulfilled, (state, action) => {
			state.loading = false;
			state.verifyBannerSuccess = null;

			const { data, status } = action.payload;

			if (status === 200) {
				const { sellerDetails, mobileImage, desktopImage, status, bannerLaunchDate, bannerExpiryDate } =
					data.result;

				const expiryDate = new Date(bannerExpiryDate);
				const startDate = new Date(bannerLaunchDate);

				state.bannerDetails = {
					sellerDetails,
					mobileImage,
					desktopImage,
					status,
					bannerExpiryDate: `${expiryDate.getDate()}/${expiryDate.getMonth()}/${expiryDate.getFullYear()}`,
					bannerLaunchDate: bannerLaunchDate,
				};
			}
		});

		builder.addCase(verifyBanner.pending, (state, action) => {
			state.loading = true;
			state.verifyBannerSuccess = null;
		});

		builder.addCase(verifyBanner.fulfilled, (state, action) => {
			state.loading = false;
			const { data, status } = action.payload;

			if (status === 200) {
				const { banner } = data.result;

				if (banner.status === 1) {
					toast.success("Banner was approved");
				}

				if (banner.status === 2) {
					toast.success("Banner was rejected");
				}
			}
		});
	},
});

export const { filterOrders, filterByStatus, setCurrentPage } = adSlice.actions;

export default adSlice.reducer;
