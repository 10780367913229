import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { changeVerificationStatus, fetchCompanyDocuments } from "redux/merchantsSlice";
import MerchantLogo from "appassets/myAccount/profile-picture.png";
import { config } from "common/config";
import { Message } from "components/ui/Message";
import Pill from "components/ui/Pill";
import Button from "components/button/button";
import Popup from "components/popup/popup";
import Alert from "components/alert/alert";
import { Spinner } from "components/ui/Spinner";
import { NavigationTitle, RoundedButton, Row } from "components/ui/UIComponents";
import DocumentButton from "./DocumentButton";
import DeactivateIcon from "appassets/merchants/deactivate-icon.png";
import ReactivateIcon from "appassets/merchants/reactivate-icon.png";
import Download from "appassets/merchants/upload.svg";

const MerchantDetailsPage = () => {
	const history = useHistory();
	const { id } = useParams();
	const dispatch = useDispatch();

	const { companyDetails, error, loading } = useSelector((state) => state.merchants);
	const [showDeactivatePopup, setShowDeactivatePopup] = useState(false);
	const [showReactivatePopup, setShowReactivatePopup] = useState(false);
	const [merchantDetails, setMerchantDetails] = useState({
		activeStatus: config?.merchantStatus[0],
	});
	const [alert, setAlert] = useState({
		show: false,
		message: "",
	});

	useEffect(() => {
		dispatch(fetchCompanyDocuments({ sellerId: id }));
	}, [id]);

	//TODO to improve the handle actions
	const handleDeactivate = () => {
		setMerchantDetails({
			...merchantDetails,
			activeStatus: config?.merchantStatus[1],
		});
	};

	const handleVerify = (value) => {
		dispatch(
			changeVerificationStatus({
				sellerId: id,
				status: value,
			})
		);

		setTimeout(() => {
			dispatch(fetchCompanyDocuments({ sellerId: id }));
		}, 500);

		if (value == "deactivated") {
			setAlert({
				show: true,
				message: "Account Deactivated",
			});
		} else if (value == "active") {
			setAlert({
				show: true,
				message: "Account Activated",
			});
		}
	};

	const downloadAll = (files) => {
		var JSZip = require("jszip");
		var FileSaver = require("file-saver");
		var zip = new JSZip();
		files.forEach((element) => {
			var index = parseInt(files.indexOf(element)) + 1;
			var type = element.substring(element.lastIndexOf("."));
			var fileName = "Document " + index + type;
			let blob = fetch(element).then((r) => r.blob());
			zip.file(fileName, blob);
		});
		zip.generateAsync({ type: "blob" }).then(function (content) {
			FileSaver.saveAs(content, companyDetails?.sellerDetails?.companyName + ".zip");
		});
	};

	const corporateSellerDetails = [
		{
			label: "Seller Id",
			value: companyDetails?.sellerDetails?.id,
		},
		{
			label: "Seller Type",
			value: companyDetails?.sellerDetails?.sellerType,
		},
		{
			label: "Account email",
			value: companyDetails?.sellerDetails?.accountEmail,
		},
		{
			label: "Person Incharge",
			value: companyDetails?.sellerDetails?.name,
		},
		{
			label: "Person in charge Email",
			value: companyDetails?.sellerDetails?.personInChargeEmail,
		},
		{
			label: "Person in charge Mobile No.",
			value: companyDetails?.sellerDetails?.contactNumber,
		},
		{
			label: "Office No.",
			value: companyDetails?.sellerDetails?.officeNumber,
		},
		{
			label: "Person in charge gender",
			value: companyDetails?.sellerDetails?.personInchargeGender,
		},
	];

	const individualSellerDetails = [
		{
			label: "Seller Id",
			value: companyDetails?.sellerDetails?.id,
		},
		{
			label: "Seller Type",
			value: companyDetails?.sellerDetails?.sellerType,
		},
		{
			label: "Account email",
			value: companyDetails?.sellerDetails?.accountEmail,
		},
		{
			label: "Nick name",
			value: companyDetails?.sellerDetails?.nickName,
		},
		{
			label: "Person in charge Email",
			value: companyDetails?.sellerDetails?.personInChargeEmail,
		},
		{
			label: "Person in charge Mobile No.",
			value: companyDetails?.sellerDetails?.contactNumber,
		},
		{
			label: "Office No.",
			value: companyDetails?.sellerDetails?.officeNumber,
		},
		{
			label: "Person in charge gender",
			value: companyDetails?.sellerDetails?.personInchargeGender,
		},
	];

	const registeredCompanyDetails = [
		{
			label: "Company Name",
			value: companyDetails?.sellerDetails?.companyName,
		},
		{
			label: "Company Description",
			value: companyDetails?.sellerDetails?.companyDescription,
		},
		{
			label: "Malaysia Identification Number",
			value: companyDetails?.companyDetails?.identificationNumber,
		},
		{
			label: "Company Registration No.",
			value: companyDetails?.companyDetails?.businessRegistrationNumber,
		},
		{
			label: "Company Address",
			value: companyDetails?.companyDetails?.companyAddress,
		},
		{
			label: "Business Industry",
			value: companyDetails?.companyDetails?.companyIndustry,
		},
	];

	const IndividualMerchantDetails = [
		{
			label: "Store Description",
			value: companyDetails?.sellerDetails?.companyDescription,
		},
		{
			label: "Full name",
			value: companyDetails?.sellerDetails?.name,
		},
		{
			label: "Malaysia Identification Number",
			value: companyDetails?.companyDetails?.identificationNumber,
		},
		{
			label: "Address",
			value: companyDetails?.companyDetails?.companyAddress,
		},
		{
			label: "Business Industry",
			value: companyDetails?.companyDetails?.companyIndustry,
		},
	];

	const BankDetails = [
		{
			label: "Bank name",
			value: companyDetails?.bankAccountDetails?.bankName,
		},
		{
			label: "Bank address",
			value: companyDetails?.bankAccountDetails?.bankAddress,
		},
		{
			label: "Account holder name",
			value: companyDetails?.bankAccountDetails?.accountHolderName,
		},
		{
			label: "Bank swift code",
			value: companyDetails?.bankAccountDetails?.bankSwiftCode,
		},
		{
			label: "Account number",
			value: companyDetails?.bankAccountDetails?.accountNumber,
		},
	];

	const renderActionButton = () => {
		if (companyDetails?.sellerDetails?.status === "active") {
			return <Button danger small label="DE-ACTIVATE" onClick={() => setShowDeactivatePopup(true)} />;
		} else if (companyDetails?.sellerDetails?.status === "deactivated") {
			return <Button success small label="RE-ACTIVATE" onClick={() => setShowReactivatePopup(true)} />;
		} else if (companyDetails?.sellerDetails?.status === "submitted") {
			return (
				<div className="flex flex-row space-x-2 ">
					<Button danger small label="REJECT" onClick={() => handleVerify("rejected")} />
					<Button small label="VERIFY" onClick={() => handleVerify("active")} />
				</div>
			);
		}
	};

	let status = config.merchantStatus.find((o) => o.value == companyDetails?.sellerDetails?.status);

	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					<Spinner show={loading} />
					<div className=" text-black min-h-full min-h-screen p-8">
						{error ? (
							<Message variant="danger" message={error} />
						) : (
							<>
								<NavigationTitle
									prevPage="Seller"
									toPrevPage="/admin/merchants"
									currentPage="Seller Details"
								/>
								<div className="bg-white rounded-md p-6 mt-8">
									<div className="flex flex-row  w-full justify-between">
										<div className="items-center">
											<p className="m-txt mb-3">
												{companyDetails?.sellerDetails?.status === "active"
													? "VERIFIED"
													: "PENDING VERIFICATION"}
											</p>
											<div className="flex">
												<img className="w-10 h-10" src={MerchantLogo} alt="logo" />
												<div className="flex flex-col ml-3">
													<div className="flex flex-row">
														<div className="font-semibold mr-3">
															{companyDetails?.sellerDetails?.companyName}
														</div>
														<Pill status={status?.color}>{status?.label}</Pill>
													</div>

													<div className="text-sm">
														{companyDetails?.sellerDetails?.personInChargeEmail}
													</div>
												</div>
											</div>
										</div>

										<div className="">{renderActionButton()}</div>
									</div>
									<hr className="my-5" />

									<Row className="w-full divide-x-2">
										<div className="w-1/3">
											<h2 className="bg-primaryBg px-4 py-4 mr-4">Seller Details</h2>
											{companyDetails?.sellerDetails?.sellerType == "Corporate" ? (
												<>
													{corporateSellerDetails.map((details) => (
														<div key={details.label} className="my-4">
															<div className="m-txt m-txt--grey m-txt--s">
																{details.label}
															</div>
															<div className="m-txt m-txt--bold">
																{details?.value ? details.value : "-"}
															</div>
														</div>
													))}
												</>
											) : (
												<>
													{individualSellerDetails.map((details) => (
														<div key={details.label} className="my-4">
															<div className="m-txt m-txt--grey m-txt--s">
																{details.label}
															</div>
															<div className="m-txt m-txt--bold">
																{details?.value ? details.value : "-"}
															</div>
														</div>
													))}
												</>
											)}
										</div>
										<div className="w-2/3 px-2">
											<h2 className="bg-primaryBg px-4 py-4 ml-4">
												{companyDetails?.sellerDetails?.sellerType == "Corporate"
													? "Registered Company"
													: "Business Information"}
											</h2>
											{companyDetails?.sellerDetails?.sellerType == "Corporate" ? (
												<div className="mx-8">
													{registeredCompanyDetails.map((details) => (
														<div key={details.label} className="my-4">
															<div className="m-txt m-txt--grey m-txt--s">
																{details.label}
															</div>
															<div className="m-txt m-txt--bold">
																{details?.value ? details.value : "-"}
															</div>
														</div>
													))}
												</div>
											) : (
												<div className="mx-8">
													{IndividualMerchantDetails.map((details) => (
														<div key={details.label} className="my-4">
															<div className="m-txt m-txt--grey m-txt--s">
																{details.label}
															</div>
															<div className="m-txt m-txt--bold">
																{details?.value ? details.value : "-"}
															</div>
														</div>
													))}
												</div>
											)}
											<div
												className="bg-white px-4 py-4 mx-8 my-4 row"
												style={{ border: "1px solid #E6E6E6" }}
											>
												<h2>Bank account details</h2>
												<div>
													{BankDetails.map((details) => (
														<div key={details.label} className="my-4 w-1/2 inline-block">
															<div className="m-txt m-txt--grey m-txt--s">
																{details.label}
															</div>
															<div className="m-txt m-txt--bold">
																{details?.value ? details.value : "-"}
															</div>
														</div>
													))}
												</div>
											</div>
											<div className="bg-primaryBg px-4 py-4 ml-4 row">
												<h2 style={{ width: "70%", display: "inline-block" }}>
													Company verification documents
												</h2>
												{!_.isEmpty(companyDetails?.companyDetails?.companyDocuments) &&
													companyDetails?.companyDetails?.companyDocuments[0].length !==
														0 && (
														<div
															onClick={() =>
																downloadAll(
																	companyDetails?.companyDetails?.companyDocuments
																)
															}
															style={{
																width: "30%",
																display: "inline-block",
																textAlign: "end",
																cursor: "pointer",
															}}
														>
															Download all
															<img
																src={Download}
																alt="download"
																style={{ display: "inline-block", marginLeft: 20 }}
															/>
														</div>
													)}
											</div>

											{!_.isEmpty(companyDetails?.companyDetails?.companyDocuments) &&
												companyDetails?.companyDetails?.companyDocuments[0].length !== 0 &&
												companyDetails?.companyDetails?.companyDocuments.map(
													(document, index) => <DocumentButton url={document} index={index} />
												)}
										</div>
									</Row>
								</div>
								<Popup open={showDeactivatePopup} onPopupClose={() => setShowDeactivatePopup(false)}>
									<div>
										<div className="flex justify-center mb-5">
											<img src={DeactivateIcon} />
										</div>
										<div className="px-5">
											<p className="m-txt m-txt--m m-txt--bold mb-5">
												Deactivate {companyDetails?.sellerDetails?.companyName} account?
											</p>
											<p className="m-txt m-txt--s m-txt--grey">
												Once the seller account have been deactivated, this seller no longer
												able to login to their account. You could do the reactivation whenever
												you wish to. Thanks.
											</p>
										</div>
										<div className="flex flex-row space-x-2 justify-center mt-5">
											<Button text label="CANCEL" onClick={() => setShowDeactivatePopup(false)} />
											<Button
												text
												gold
												label="DEACTIVATE NOW"
												onClick={() => {
													handleVerify("deactivated");
													setShowDeactivatePopup(false);
												}}
											/>
										</div>
									</div>
								</Popup>
								<Popup open={showReactivatePopup} onPopupClose={() => setShowReactivatePopup(false)}>
									<div>
										<div className="flex justify-center mb-5">
											<img src={ReactivateIcon} />
										</div>
										<div className="px-5">
											<p className="m-txt m-txt--m m-txt--bold mb-5">
												Reactivate {companyDetails?.sellerDetails?.companyName} account?
											</p>
											<p className="m-txt m-txt--s m-txt--grey">
												The seller account will be unlocked after the reactivation, please
												confirm.
											</p>
										</div>
										<div className="flex flex-row space-x-2 justify-center mt-5">
											<Button text label="CANCEL" onClick={() => setShowReactivatePopup(false)} />
											<Button
												text
												gold
												label="REACTIVATE NOW"
												onClick={() => {
													handleVerify("active");
													setShowReactivatePopup(false);
												}}
											/>
										</div>
									</div>
								</Popup>
								<Alert
									message={alert.message}
									show={alert.show}
									onClose={(show) => setAlert({ show })}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MerchantDetailsPage;
