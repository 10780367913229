import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchOrderDetails } from "redux/orderSlice";
import { getCommaSeparatedNumber } from "utils/functions";
import moment from "moment";
import { config } from "common/config";
import ImageNotFound from "common/image-not-found.jpg";
import { changeAddressToString } from "common/utility";
import ColoredText from "components/colored-text/colored-text";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import StatusText from "components/ui/StatusText";
import { NavigationTitle, Row } from "components/ui/UIComponents";
import Button from "components/button/button";
import api from "api/api";

const OrderDetailsPage = () => {
	const history = useHistory();
	const { id } = useParams();
	const dispatch = useDispatch();
	const [courier, setCourier] = useState();
	const { orderDetails, loading, error } = useSelector((state) => state.orders);

	const navigateBack = () => {
		history.goBack();
	};

	useEffect(() => {
		dispatch(fetchOrderDetails(id));
	}, [id]);

	useEffect(() => {
		fetchCourier();
	}, []);

	const productList = orderDetails?.productDetailsList?.map((product) => {
		return {
			productImage: product.productImage.imagePath[0],
			productName: product.productName,
			productSKU: product.productSKU,
			price: product.amount,
			quantity: product.quantity,
			totalAmount: product.amount * product.quantity || 0,
			variation: product.variation,
		};
	});

	const seller = [
		{
			label: "Seller Id",
			value: orderDetails?.sellerDetails?.id,
		},
		{
			label: "Seller Type",
			value: orderDetails?.sellerDetails?.sellerType,
		},
		{
			label: "Company Name",
			value: orderDetails?.sellerDetails?.companyName,
		},
		{
			label: "Person In Charge",
			value: orderDetails?.sellerDetails?.name,
		},
		{
			label: "Email",
			value: orderDetails?.sellerDetails?.personInChargeEmail,
		},
		{
			label: "Mobile No",
			value: orderDetails?.sellerDetails?.contactNumber,
		},
	];

	const orderDetailsList = [
		{
			label: "Order by",
			value: orderDetails?.ordersDetails?.orderedBy,
			note: orderDetails?.ordersDetails?.email,
		},
		{
			label: "Customer ID",
			value: orderDetails?.ordersDetails?.customerId,
		},
		{
			label: "Ship To",
			value: changeAddressToString(orderDetails?.ordersDetails?.address),
		},
		{
			label: "Shipping Company",
			value: "E-commerce delivery",
		},
	];

	function changeStatusColor(status) {
		switch (status) {
			case 0:
				return "text-success uppercase";
			case 2:
				return "text-warning uppercase";
			case 3:
				return "text-success uppercase";
			case 4:
				return "text-danger uppercase";
			case 5:
				return "text-gray uppercase";
			default:
				return "text-gray uppercase";
		}
	}

	function findStatusTextFromInt(number) {
		if (number) {
			return config.ordersPage.pageConfig.filters.find((item) => item.value === number)?.label || "";
		}
	}

	const handleRefund = async () => {
		try {
			await api.patch.orderRefund(orderDetails.ordersDetails.orderNumber);
			dispatch(fetchOrderDetails(id));
		} catch (error) {
			console.log(error);
		}
	};

	const fetchCourier = async () => {
		const response = await api.get.getCourier();
		setCourier(response.data?.result);
	};

	return (
		<>
			<Spinner show={loading} />
			<div className=" text-black min-h-full min-h-screen p-8">
				{error && <Message variant="danger" message={error} />}
				<NavigationTitle prevPage="Orders" toPrevPage="/admin/orders" currentPage="Order Details" />
				<div className="bg-white rounded-md p-6 mt-8">
					<div className="flex flex-row  w-full justify-between">
						<div className="flex flex-row flex-wrap items-center">
							<div className="flex flex-col ml-2">
								<div className="flex flex-row">
									<div className="font-normal">
										<ColoredText
											color={changeStatusColor(orderDetails?.ordersDetails?.status)}
											text={orderDetails?.ordersDetails?.status == 'cancelled'? 'Cancelled By Seller':findStatusTextFromInt(orderDetails?.ordersDetails?.status)}
										></ColoredText>
										{orderDetails?.ordersDetails?.status == 'cancelled' && orderDetails?.ordersDetails?.cancellationReason?
										<p className="text-gray font-medium text-sm mb-2">Reason: {orderDetails?.ordersDetails?.cancellationReason}</p>
									:null}
									</div>
								</div>

								<div className="font-semibold">
									Order ID: # {orderDetails?.ordersDetails?.orderNumber}
								</div>
								<div className="text-sm">Store Name: {orderDetails?.sellerDetails?.companyName}</div>
							</div>
						</div>

						<div className="items-end mx-10 text-right">
							{orderDetails?.ordersDetails?.status == "cancelled" ||
							orderDetails?.ordersDetails?.status == "cancelledByCustomer" ? (
								<Button small label="REFUND IS DONE" onClick={() => handleRefund()} />
							) : null}
							<div className="mt-4">
								Total: RM
								<span className="ml-2 text-red-500 font-semibold">
									{getCommaSeparatedNumber(orderDetails?.ordersDetails?.totalAmount, 2)}
								</span>
							</div>
							<div className="">
								Placed On: {moment(orderDetails?.ordersDetails?.placedOn).format("DD MMM YYYY")}
							</div>
						</div>
					</div>
					<hr className="my-5" />

					<Row className="w-full divide-x-2">
						<div className="w-1/3 ">
							<h2 className="bg-primaryBg px-4 py-4 mr-4">Seller Details</h2>
							<div className=" mx-4">
								{seller.map((details) => (
									<div key={details.label} className="my-4">
										<div className="m-txt--lightGrey font-medium text-sm">{details.label}</div>
										<div className="font-semibold">{details.value} </div>
									</div>
								))}
							</div>
						</div>
						<div className="w-2/3 px-2 ">
							<h2 className="bg-primaryBg px-4 py-4 ml-4">Order Details</h2>
							<div className="ml-4 grid grid-rows-3 grid-flow-col h-56 px-6">
								{orderDetailsList.map((details, index) => {
									if (index === orderDetailsList.length - 1) {
										return (
											<div key={details.label} className="my-4 flex justify-end">
												<div>
													<div className="m-txt--lightGrey font-medium text-sm">
														{details.label}
													</div>
													<div className="font-semibold flex justify-end">
														{details.value != null ? details.value : " "}
													</div>
												</div>
											</div>
										);
									} else {
										return (
											<div key={details.label} className="my-4 ">
												<div className="m-txt--lightGrey font-medium text-sm">{details.label}</div>
												<div className="font-semibold">
													{details.value != null ? details.value : " "}
												</div>
												<div className="m-txt--lightGrey text-sm">
													{details.note != null ? details.note : " "}
												</div>
											</div>
										);
									}
								})}
							</div>
							<div className="ml-4">
								<hr className="my-4" />
								{productList?.map((item) => (
									<div key={item}>
										<div
											className="px-5 font-semibold m-txt--lightGrey text-xs"
										>
											SKU: <span>{item.productSKU.substring(3)}</span>
										</div>
										<div className="flex flex-row items-center">
											<div className="px-4">
												<img
													src={item?.productImage !== "" ? item?.productImage : ImageNotFound}
													className="rounded-full w-20 object-fill"
													alt="product"
												/>
											</div>
											<div className="w-full">
												<div className="font-medium">{item.productName}</div>
												{item?.variation ? (
													<div>
														<h6 style={{ fontSize: "small", color: "grey" }}>
															{item?.variation.titles[0]}:{" "}
															{item?.variation.variations.variationOne}
															{item?.variation.titles[1] ? (
																<>
																	, {item?.variation.titles[1]}:{" "}
																	{item?.variation.variations.variationTwo}
																</>
															) : null}
														</h6>
													</div>
												) : null}
												<div className="text-sm text-g">RM{item?.variation?.price}/pc</div>
											</div>
											<div className="grid justify-items-end">
												<div className="font-medium">x {item.quantity}</div>
												<div>RM{getCommaSeparatedNumber(item?.price, 2) || "0.00"}</div>
											</div>
										</div>
										<hr className="my-4" />
									</div>
								))}
							</div>
							<div className="w-full flex flex-col  items-end font-medium">
								<div className="">
									Subtotal: {getCommaSeparatedNumber(orderDetails?.ordersDetails?.subtotal, 2)}
								</div>
								<div className="">
									Shipping Fee: RM{" "}
									{getCommaSeparatedNumber(orderDetails?.ordersDetails?.shippingFee, 2)}
								</div>
								<div className="mt-6">
									Total:{" "}
									<span className="text-red-500">
										RM {getCommaSeparatedNumber(orderDetails?.ordersDetails?.totalAmount, 2)}
									</span>
								</div>
							</div>
						</div>
					</Row>
				</div>
			</div>
		</>
	);
};

export default OrderDetailsPage;
