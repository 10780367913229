import React from "react";

export const ProductCategoryIcon = ({ size }) => {
  return (
    <svg
      width={size || "19"}
      height={size || "19"}
      viewBox="0 0 19 19"
      fill="none"
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M8.16406 19H0.742188C0.332245 19 0 18.6678 0 18.2578V10.8359C0 10.426 0.332245 10.0938 0.742188 10.0938H8.16406C8.57401 10.0938 8.90625 10.426 8.90625 10.8359V18.2578C8.90625 18.6678 8.57401 19 8.16406 19ZM7.42188 11.5781H1.48438V17.5156H7.42188V11.5781ZM18.5419 8.84972C18.2646 8.96467 17.9453 8.90118 17.7331 8.68896L10.3112 1.26708C10.0988 1.05472 10.0353 0.735519 10.1503 0.458214C10.2652 0.180763 10.5357 0 10.8359 0H18.2578C18.6678 0 19 0.332245 19 0.742188V8.16406C19 8.46427 18.8192 8.73491 18.5419 8.84972ZM17.5156 1.48438H12.6278L17.5156 6.37223V1.48438ZM14.5469 10.0938C17.0023 10.0938 19 12.0914 19 14.5469C19 17.0023 17.0023 19 14.5469 19C12.0914 19 10.0938 17.0023 10.0938 14.5469C10.0938 12.0914 12.0914 10.0938 14.5469 10.0938ZM14.5469 17.5156C16.1839 17.5156 17.5156 16.1839 17.5156 14.5469C17.5156 12.9099 16.1839 11.5781 14.5469 11.5781C12.9099 11.5781 11.5781 12.9099 11.5781 14.5469C11.5781 16.1839 12.9099 17.5156 14.5469 17.5156ZM8.68881 7.63917L5.50277 4.45312L8.68881 1.26708C8.97873 0.977165 8.97873 0.50721 8.68881 0.217438C8.39904 -0.0723343 7.92908 -0.0724792 7.63931 0.217438L4.45312 3.40348L1.26694 0.217293C0.977165 -0.0724792 0.50721 -0.0724792 0.217438 0.217293C-0.0724792 0.50721 -0.0724792 0.977165 0.217438 1.26694L3.40348 4.45312L0.217438 7.63917C-0.0724792 7.92908 -0.0724792 8.39904 0.217438 8.68881C0.50721 8.97873 0.977165 8.97873 1.26694 8.68881L4.45312 5.50277L7.63931 8.68896C7.92908 8.97873 8.39904 8.97873 8.68881 8.68896C8.97873 8.39904 8.97873 7.92908 8.68881 7.63917Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="matrix(1 0 0 -1 0 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
