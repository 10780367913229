import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { config } from "common/config";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { RoundedButton, SearchInput } from "components/ui/UIComponents";
import AddAccouncementModal from "./AddAccouncementModal";
import Pagination from "components/pagination/pagination";
import { fetchAnnouncement, filteredAnnouncements, setCurrentPage } from "redux/announcementSlice";


const AdminAnnouncementPage = () => {
	const today = new Date();
	const [selected, setSelected] = useState(0);
	const [yearInsert, setYearInsert] = useState(today.getFullYear());
	const [popupVisible, setPopupVisible] = useState(false);
	const [list, setList] = useState([]);
	const [search, setSearch] = useState("");
	const [sort, setSort] = useState({
        key: "",
        order: ""
    });

	const dispatch = useDispatch();

	const { loading, error, announcements, currentPage, totalPage, filterAnnouncements } = useSelector(
		(state) => state.announcement
	);

	const createNew = () => {
		setPopupVisible(true);
	};

	const pageConfig = config.announcementPage.pageConfig;

	useEffect(() => {
		let array;

		if (filterAnnouncements && filterAnnouncements.length > 0) {
			array = filterAnnouncements;
		} else {
			array = announcements;
		}
		mapColumns(array);
	}, [announcements, filterAnnouncements]);

	function mapColumns(array) {
		const list = array.map((announcement) => ({ ...announcement }));
		setList(list);
	}

	//recipient 0=seller 1=buyer 2=buyer&seller
	//field: "${recipient},year"
	useEffect(() => {
		if (yearInsert) {
			if (selected === 0) {
				dispatch(
					fetchAnnouncement({
						page: currentPage,
						search,
						field: "year",
						value: yearInsert,
						sort: sort.key,
						order: sort.order,
					})
				);
				return;
			} else {
				dispatch(
					fetchAnnouncement({
						page: currentPage,
						search,
						field: "month,year",
						value: `${selected},${yearInsert}`,
						sort: sort.key,
						order: sort.order,
					})
				);
			}
		}
	}, [selected, yearInsert]);

	const searchList = async (value, key) => {
		setSearch(value)
		setSelected(0)

		dispatch(
			fetchAnnouncement({
				page: 0,
				search: value
			})
		);
	};

	const filterMonth = (index) => {
		setSearch("")
		setSelected(index)
	}

	const handleSort = (sort, order) => {
		setSort({
            key: sort,
            order: order
        })

		if (selected === 0) {
			dispatch(
				fetchAnnouncement({
					page: currentPage,
					search,
					field: "year",
					value: yearInsert,
					sort,
					order,
				})
			);
			return;
		} else {
			dispatch(
				fetchAnnouncement({
					page: currentPage,
					search,
					field: "month,year",
					value: `${selected},${yearInsert}`,
					sort,
					order,
				})
			);
		}
	};

	const handlePagination = (value) => {
		const currentIndex = Math.max(0, Math.min(currentPage + value, totalPage - 1));
		dispatch(setCurrentPage(currentIndex));

		if (selected === 0) {
			dispatch(
				fetchAnnouncement({
					page: currentIndex,
					search,
					field: "year",
					value: yearInsert,
					sort: sort.key,
					order: sort.order,
				})
			);
		} else {
			dispatch(
				fetchAnnouncement({
					page: currentIndex,
					search,
					field: "month,year",
					value: `${selected},${yearInsert}`,
					sort: sort.key,
					order: sort.order,
				})
			);
		}
	};

	const handleSpecificPagination = (value) => {
		dispatch(setCurrentPage(value));

		if (selected === 0) {
			dispatch(
				fetchAnnouncement({
					page: value,
					search,
					field: "year",
					value: yearInsert,
					sort: sort.key,
					order: sort.order,
				})
			);
		} else {
			dispatch(
				fetchAnnouncement({
					page: value,
					search,
					field: "month,year",
					value: `${selected},${yearInsert}`,
					sort: sort.key,
					order: sort.order,
				})
			);
		}
	};

	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					<AddAccouncementModal setPopupVisible={setPopupVisible} popupVisible={popupVisible} />
					<Spinner show={loading} />

					<div className="text-black min-h-full min-h-screen p-8">
						{error && <Message variant="danger" message={error} />}
						<div className="flex justify-between">
							<div className="w-2/4">
								<SearchInput
									placeholder="Search announcement message keyword"
									icon={<FiSearch size="18" />}
									value={search}
									onChange={(e) => searchList(e.target.value)}
								/>
							</div>
							<div>
								<RoundedButton label="CREATE NEW" dark onClick={() => createNew()} />
							</div>
						</div>

						<div className="flex flex-row justify-between">
							<div className="flex flex-row my-6 divide-x-2 space-x-4 divide-gray-500">
								{pageConfig.filters.map((filter, index) => (
									<div
										key={filter}
										className={`pr-2 pl-6 cursor-pointer ${
											selected === index ? "text-gray-800 font-semibold" : "m-txt--lightGrey"
										}`}
										onClick={() => filterMonth(index)}
									>
										{filter}
									</div>
								))}
							</div>
							<div className="flex items-center">
								<div>
									<span className="font-bold p-4">Year</span>
									<input
										className="w-20 p-2"
										maxLength={4}
										value={yearInsert}
										type="number"
										onChange={(e) => setYearInsert(parseInt(e.target.value))}
									/>
								</div>
							</div>
						</div>

						<TableComponent headers={config.announcementPage.announcementHeader} rows={list} sortFunction={handleSort} />

						<div className="flex flex-row items-center justify-end my-2">
							<button onClick={() => handlePagination(-1)}>
								<AiFillCaretLeft size="16" color={currentPage == 0 ? "#D2D2D2" : "#000000"}/>
							</button>

							<Pagination totalPages={totalPage} currentPage={currentPage + 1} onPageClick={handleSpecificPagination}/>

							<button onClick={() => handlePagination(+1)}>
								<AiFillCaretRight size="16" color={currentPage == totalPage - 1  ? "#D2D2D2" : "#000000"}/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminAnnouncementPage;
