import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiBold, BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logout } from "redux/adminSlice";
import { fetchSalesReport, setEndDate, setStartDate } from "redux/dashboardSlice";
import { getCommaSeparatedNumber } from "utils/functions";
import api from "api/api";
import { config } from "common/config";
import { formatDate } from "common/utility";
import TimePeriodPress from "components/admin/TimePeriodPress";
import ColoredText from "components/colored-text/colored-text";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { DashboardTile, Row } from "components/ui/UIComponents";
import { pathnames } from "routes/routes";

//First Sales Date
let firstDate = null;

const AdminDashboardPage = () => {
	const history = useHistory();
	const today = new Date();
	const dispatch = useDispatch();

	const [list, setList] = useState([]);
	const [cancellationList, setCancellationList] = useState([]);
	const [selectedPeriod, setSelectedPeriod] = useState(4);
	const { loading, error, salesRevenue, newCancellation, newSeller, chartData, statusCode, startDate, endDate } =
		useSelector((state) => state.dashboard);


	// logout if duplicate session
	if (statusCode === 999) {
		dispatch(logout());
	}

	// graph options
	let graphOptions = {
		chart: {
			zoomType: "x",
			type: "areaspline",
			reflow: false,
			marginTop: 30,
		},
		title: {
			text: "",
		},
		subtitle: {
			text: "",
		},
		legend: {
			enabled: false,
		},

		series: [
			{
				data: chartData,
				name: "Total Revenue (RM)",
			},
		],
		xAxis: { 
			type: "datetime",
			tickInterval: selectedPeriod == 0 ? 24 * 3600 * 1000 : undefined,
			labels: {
				format: '{value:%b %d}'
			},
		},
		yAxis: {
			title: {
				text: "RM",
				align: "high",
				rotation: 0,
				y: -20,
				x: -20,
				offset: 0,
				style: {
					color: "black",
					fontWeight: "bold",
				},
			},
		},
		plotOptions: {
			areaspline: {
				marker: {
					enabled: true,
					symbol: "circle",
					radius: 3,
				},
				lineWidth: 1.5,
				fillColor: {
					linearGradient: [0, 0, 0, 300],
					stops: [
						[0, "#2FA8FF"],
						[1, "#FFFFFF"],
					],
				},
			},
		},
		tooltip: {
			pointFormat: "",
			formatter: function () {
				var s = new Date(this.x).toLocaleDateString("en-GB");
				return s + "<br/><b>Total Revenue (RM): " + this.y.toFixed(2) + "</b>";
			},
		},
		credits: false,
	};

	useEffect(() => {
		const userInfoFromStorage = localStorage.getItem("userInfo")
			? JSON.parse(localStorage.getItem("userInfo"))
			: null;

		if (!userInfoFromStorage) {
			history.push("/");
		}

		fetchStartDate()
	}, []);

	//temporary solution to fetch first date from list
	const fetchStartDate = async () => {
		const response = await api.get.salesReport("", "");
		const startDate = formatDate(response.data?.result?.salesRevenue[0]?.date, "DD MMM YYYY");
		firstDate = startDate;
		dispatch(setStartDate(startDate));
	}

	// fetch sales report when date range changes
	useEffect(() => {
		if (startDate && endDate) {
			const filters = "dateFrom,dateTo";
			const values = `${startDate},${endDate}`;
			dispatch(fetchSalesReport({ filters, values }));
		}
	}, [startDate, endDate]);

	// map  data into rows for table
	useEffect(() => {
		mapColumns(newSeller);
	}, [newSeller]);

	useEffect(() => {
		const cancellations =
			newCancellation instanceof Array
				? newCancellation.map((cancellation) => ({
						cancelBy: cancellation.cancelledBy == "Merchant" ? "Seller" : "Buyer",
						name: cancellation.storeName,
						itemCount: cancellation.itemCount,
						amount: getCommaSeparatedNumber(cancellation.amount),
						status: <ColoredText
									text={config.ordersPage.pageConfig.filters.find((item) => item.value === cancellation.status)?.label || cancellation.status}
									color={changeStatusColor(cancellation.status)}
								/>,
						action: (
							<button onClick={() => navigateToOrderDetails(cancellation.orderNumber)}>
								<BsChevronRight size="15" />
							</button>
						),
				  }))
				: [];
		setCancellationList(cancellations);
	}, [newCancellation]);

	function mapColumns(array) {
		if (array instanceof Array) {
			const sellers = array.map((seller) => ({
				...seller,
				storeType: config.accountType[seller.storeType]?.label,
				action: (
					<button onClick={() => navigateToDetails(seller.id)}>
						<BsChevronRight size="15" />
					</button>
				),
			}));

			setList(sellers);
		}
	}

	function changeStatusColor(status) {
		switch (status) {
			case "refunded":
				return "text-danger";
			default:
				return "text-gray";
		}
	}

	// handle start and end date change
	const datePicked = (value, id) => {
		let formattedDate = formatDate(value, "DD MMM YYYY");
		if (id === "startDate") {
			dispatch(setStartDate(formattedDate));
		}
		if (id === "endDate") {
			dispatch(setEndDate(formattedDate));
		}
	};

	const handleDatePicker = (range, index) => {
		let startDate = "";
		let endDate = "";
		setSelectedPeriod(index);
		endDate = formatDate(today, "DD MMM YYYY");
		switch (range.type) {
			case "week":
				startDate = formatDate(moment().subtract("1", "week"), "DD MMM YYYY");
				break;
			case "month":
				startDate = formatDate(moment().subtract("1", "month"), "DD MMM YYYY");
				break;
			case "3month":
				startDate = formatDate(moment().subtract("3", "month"), "DD MMM YYYY");
				break;
			case "year":
				startDate = formatDate(moment().subtract("1", "year"), "DD MMM YYYY");
				break;
			case "all":
				startDate = formatDate(firstDate, "DD MMM YYYY");
				break;
			default:
				break;
		}
		dispatch(setStartDate(startDate));
		dispatch(setEndDate(endDate));
	};

	const navigateToDetails = (id) => {
		history.push(config.routes.merchantDetails.replace("id", id));
	};

	const navigateToOrderDetails = (id) => {
		history.push(`/admin${pathnames.orderDetails.replace(":id", id)}`);
	};

	const navigateToRoute = (route) => {
		history.push(route);
	};

	const pageConfig = config.dashboardPage.pageConfig;

	return (
		<>
			<Spinner show={loading} />
			<div className="m-container">
				<div className="m-wrapper">
					<div className="text-black min-h-full p-8">
						{error && <Message variant="danger" message={error} />}
						<Row className="mb-8">
							<DashboardTile
								superscript={pageConfig.summary[0].label}
								value={getCommaSeparatedNumber(salesRevenue.totalRevenue, 0)}
							/>
							<DashboardTile
								superscript={pageConfig.summary[1].label}
								value={getCommaSeparatedNumber(salesRevenue.todayRevenue, 0)}
							/>
							<DashboardTile
								superscript={pageConfig.summary[2].label}
								value={getCommaSeparatedNumber(salesRevenue.totalMerchants, 0)}
							/>
						</Row>
						<Row>
							<div className="m-txt m-txt--bold mb-2">Sales Revenue</div>
							<div className="pt-20 w-full bg-white rounded-md">
								<div className="flex lg:flex-row justify-between px-10 -mt-10 md:flex-col sm:flex-col">
									<div className="flex flex-row  divide-x border-2 cursor-pointer ">
										{pageConfig.timePeriod.map((item, index) => (
											<TimePeriodPress
												key={item.label}
												item={item}
												index={index}
												handleDatePicker={handleDatePicker}
												selected={selectedPeriod}
											/>
										))}
									</div>
									<div className="lg:-mt-5 md:mt-5 sm:mt-5">
										<div className="m-txt">Select date range:</div>
										<div className="flex flex-row">
											<input
												className="w-26 bg-gray-200 px-2 py-1"
												type="date"
												placeholder="dd-mm-yyyy"
												min={formatDate(firstDate, "YYYY-MM-DD")}
												max={formatDate(today, "YYYY-MM-DD")}
												onChange={(e) => datePicked(e.target.value, "startDate")}
												value={formatDate(startDate, "YYYY-MM-DD")}
											/>
											<div className="bg-gray-200 px-2">_</div>
											<input
												min={formatDate(startDate, "YYYY-MM-DD")}
												max={formatDate(today, "YYYY-MM-DD")}
												className="w-26 bg-gray-200 px-2 py-1"
												placeholder="dd-mm-yyyy"
												type="date"
												onChange={(e) => datePicked(e.target.value, "endDate")}
												value={formatDate(endDate, "YYYY-MM-DD")}
											/>
										</div>
									</div>
								</div>
								<div className="w-full mt-10 px-10 pb-5">
									{graphOptions && (
										<HighchartsReact
											allowChartUpdate={true}
											immutable={true}
											highcharts={Highcharts}
											options={graphOptions}
										/>
									)}
								</div>
							</div>
						</Row>
						<Row>
							<div className="w-full flex flex-col mt-10">
								<div className="flex-row flex justify-between">
									<div className="m-txt m-txt--bold mb-2">New Sellers</div>
									<span
										className="m-txt m-txt--bold cursor-pointer"
										onClick={() => navigateToRoute(config.routes.merchants)}
									>
										View All
									</span>
								</div>

								<TableComponent
									headers={config.dashboardPage.sellersHeader}
									rows={list}
									hasButton={true}
								/>
							</div>

							<div className="w-full flex flex-col mt-10">
								<div className="flex-row flex justify-between">
									<div className="m-txt m-txt--bold mb-2">New Cancellations</div>
									<span
										className="m-txt m-txt--bold cursor-pointer"
										onClick={() => navigateToRoute(config.routes.orders)}
									>
										View All
									</span>
								</div>

								<TableComponent
									hasButton={true}
									headers={config.dashboardPage.cancellationHeader}
									rows={cancellationList}
								/>
							</div>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminDashboardPage;
