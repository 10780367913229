import api from "api/api";
import { sortByKey } from "common/utility";
import moment from "moment";
import toast from "react-hot-toast";
import { findByValue } from "utils/functions";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	merchants: [],
	filteredMerchants: null,
	loading: false,
	error: "",
	currentPage: 0,
	totalPage: 0,
	selectedMerchant: {},
	monthlyStatements: [],
	filteredMonthlyStatements: [],
	monthlyOrderDetails: {},
	companyDetails: {
		sellerDetails: null,
		companyDetails: null,
		bankAccountDetails: null,
	},
};

export const fetchMerchants = createAsyncThunk(
	"merchants/fetchMerchants",
	async ({ currentPage, search, filter, sort, order }) => {
		const response = await api.get.sellers({
			page: currentPage,
			search,
			filter,
			sort,
			order,
		});
		return response;
	}
);

export const fetchAllMerchants = createAsyncThunk(
	"merchants/fetchAllMerchants",
	async ({ currentPage, search, filter, sort, order }) => {
		const response = await api.get.sellers({
			page: currentPage,
			search,
			filter,
			sort,
			order,
		});
		return response;
	}
);

export const fetchCompanyDocuments = createAsyncThunk("merchants/fetchCompanyDocuments", async ({ sellerId }) => {
	const response = await api.get.getCompanyDocuments({ sellerId: sellerId });
	return response;
});

export const changeVerificationStatus = createAsyncThunk(
	"merchants/changeVerification",
	async ({ sellerId, status }, thunkAPI) => {
		const response = await api.post.verifyCompany({
			id: sellerId,
			status: status,
		});

		const { merchants } = thunkAPI.getState();

		//TODO fix once sellerID changes

		//thunkAPI.dispatch(fetchCompanyDocuments({ sellerId: merchants?.sellerDetails?.id}));

		return response;
	}
);

const merchantSlice = createSlice({
	name: "merchants",
	initialState,
	reducers: {
		filterMerchants: (state, action) => {
			const { value, key } = action.payload;
			if (value !== undefined) {
				state.filteredMerchants = findByValue(state.merchants, value, key);
			} else {
				state.filteredMerchants = state.merchants;
			}
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		sort: (state, action) => {
			const { key, order } = action.payload;
			state.merchants = sortByKey([...state.merchants], key, order);
			state.filteredMerchants = sortByKey([...state.merchants], key, order);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchMerchants.fulfilled, (state, action) => {
			state.loading = false;
			state.filteredMerchants = null;

			const { status, data } = action.payload;

			if (status === 200) {
				const { merchantCompanyDetails, pageCount } = action.payload.data.result;

				state.totalPage = pageCount;

				const today = moment().endOf(moment());

				state.merchants = merchantCompanyDetails.map((seller) => ({
					id: seller.sellerId,
					storeName: seller.storeName,
					type: seller.type,
					products: seller.numberOfProducts,
					joined:
						moment().diff(seller.joinedDate, "days") < 1
							? "Today"
							: moment(seller.joinedDate).format("DD MMM YYYY"),
				}));
			} else {
				state.error = data.message;
			}
		});

		builder.addCase(fetchMerchants.pending, (state) => {
			state.loading = true;
			state.error = null;
		});

		builder.addCase(fetchMerchants.rejected, (state, action) => {
			state.error = action.error.message;
		});

		builder.addCase(fetchCompanyDocuments.fulfilled, (state, action) => {
			state.loading = false;
			state.error = null;
			const { status, data } = action.payload;

			if (status === 200) {
				const { sellerDetails, companyDetails, bankAccountDetails } = data.result;
				state.companyDetails = { sellerDetails, companyDetails, bankAccountDetails };
			} else {
				state.error = data.message;
			}
		});
		builder.addCase(fetchCompanyDocuments.pending, (state) => {
			state.loading = true;
			state.error = null;
			state.companyDetails = null;
		});

		builder.addCase(fetchCompanyDocuments.rejected, (state, action) => {
			state.error = action.error.message;
		});

		builder.addCase(changeVerificationStatus.fulfilled, (state, action) => {
			state.loading = false;
			state.error = null;
			const { data, status } = action.payload;

			if (status === 200) {
				const { verified, companyName } = data.result.company;

				switch (verified) {
					case 1:
						toast.success(`Company  ${companyName} was approved`);
						break;
					case 2:
						toast.error(`Company  ${companyName} was rejected`);
						break;
					default:
						console.log(data);
						break;
				}
			}
		});

		builder.addCase(changeVerificationStatus.pending, (state) => {
			state.loading = true;
			state.error = null;
		});

		builder.addCase(changeVerificationStatus.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});
	},
});

export const { filterMerchants, setCurrentPage, sort } = merchantSlice.actions;

export default merchantSlice.reducer;
