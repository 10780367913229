import api from "api/api";
import { findByValue } from "utils/functions";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	loading: false,
	error: "",
	streams: [],
	currentPage: 0,
	totalPage: 0,
	filteredStreams: null,
	filter: "sellerId",
	streamDetails: {
		sellerDetails: null,
		merchantStreamDetails: null,
		streamAnnouncementStatusDetails: null,
	},
	singlestreamDetails: null,
};

export const fetchStreams = createAsyncThunk("streams/fetchStreams", async ({ currentPage, sort, order, search }) => {
	const response = await api.get.streams({ page: currentPage, sort: sort, order: order, search: search });
	return response;
});

export const fetchStreamDetails = createAsyncThunk("streams/fetchStreamDetails", async ({ sellerId, sort, order }) => {
	const response = await api.get.streamDetails({ sellerId, sort, order });
	return response;
});

export const fetchSingleStreamDetails = createAsyncThunk("streams/fetchSingleStreamDetails", async ({ streamId }) => {
	const response = await api.get.singleStreamDetails({ streamId });
	return response;
});

const streamSlice = createSlice({
	name: "streams",
	initialState,

	reducers: {
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},

		filterUpgradeRequest: (state, action) => {
			state.filteredStreams = state.streams.filter((o) => o.upgradeRequest === true);
		},

		filterStreams: (state, action) => {
			if (action.payload !== null) {
				const { value, key } = action.payload;
				if (value !== undefined) {
					state.filteredStreams = findByValue(state.streams, value, key);
				} else {
					state.filteredStreams = state.streams;
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchStreams.pending, (state) => {
			state.loading = true;
			state.error = "";
		});

		builder.addCase(fetchStreams.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchStreams.fulfilled, (state, action) => {
			state.loading = false;
			const { data, status } = action.payload;

			if (status === 200) {
				const { pageCount, streamList } = data.result;
				state.totalPage = pageCount;
				state.streams = streamList;
			} else {
				state.error = data.message;
			}
		});

		builder.addCase(fetchStreamDetails.pending, (state) => {
			state.loading = true;
			state.error = "";
			state.orderDetails = {
				sellerDetails: null,
				productDetailsList: null,
				ordersDetails: null,
			};
		});

		builder.addCase(fetchStreamDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchStreamDetails.fulfilled, (state, action) => {
			state.loading = false;
			const { data, status } = action.payload;

			if (status === 200) {
				const { sellerDetails, merchantStreamDetails, streamAnnouncementStatusDetails } = data.result;

				state.streamDetails = { sellerDetails, merchantStreamDetails, streamAnnouncementStatusDetails };
			} else {
				state.error = data.message;
			}
		});

		builder.addCase(fetchSingleStreamDetails.pending, (state) => {
			state.loading = true;
			state.error = "";
			state.singlestreamDetails = null;
		});

		builder.addCase(fetchSingleStreamDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});

		builder.addCase(fetchSingleStreamDetails.fulfilled, (state, action) => {
			state.loading = false;
			const { data, status } = action.payload;

			if (status === 200) {
				const singlestreamDetails = data.result;

				state.singlestreamDetails = singlestreamDetails;
			} else {
				state.error = data.message;
			}
		});
	},
});

export const { setCurrentPage, filterUpgradeRequest, filterStreams } = streamSlice.actions;

export default streamSlice.reducer;
