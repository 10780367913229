import React from "react";

export const AnnouncementIcon = ({ size }) => {
  return (
    <svg
      width={size || "16"}
      height={size || "18"}
      viewBox="0 0 16 18"
      className="fill-current"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.8158 0.0709896C14.6401 -0.0274616 14.4248 -0.0232311 14.253 0.0819368L7.87821 3.98603H5.04821C4.44381 3.95408 3.86884 4.16471 3.42746 4.58018C3.26317 4.73478 3.12468 4.91324 3.01432 5.10769H1.61377C0.723929 5.10769 0 5.83162 0 6.72147V9.25277C0 10.1426 0.723929 10.8665 1.61377 10.8665H3.01154C3.23568 11.2673 3.57404 11.5994 3.9854 11.8193L2.04602 15.1784C1.97162 15.3073 1.95192 15.4606 1.99129 15.6041C2.0307 15.7476 2.12592 15.8694 2.25569 15.9422L4.71166 17.32C4.79776 17.3683 4.89127 17.3913 4.98353 17.3913C5.17601 17.3913 5.36308 17.2913 5.46606 17.1129L8.23538 12.3162L14.2593 15.8961C14.3469 15.9482 14.4453 15.9742 14.5437 15.9742C14.6386 15.9742 14.7336 15.95 14.819 15.9014C14.9929 15.8024 15.1004 15.6177 15.1004 15.4176V0.55664C15.1004 0.35521 14.9915 0.169478 14.8158 0.0709896ZM1.61377 9.75327C1.33779 9.75327 1.11328 9.52876 1.11328 9.25277V6.72147C1.11328 6.44549 1.33779 6.22098 1.61377 6.22098H2.71422C2.71414 6.22929 2.71359 6.23756 2.71359 6.24588V9.72837C2.71359 9.73672 2.7141 9.74495 2.71422 9.75327H1.61377ZM3.82687 6.24588C3.82687 5.91998 3.95938 5.60834 4.19043 5.39088C4.41034 5.18392 4.69727 5.07983 4.99878 5.09831C5.01002 5.09897 7.47854 5.09931 7.47854 5.09931V10.993H5.05077C4.36317 10.9436 3.82687 10.391 3.82687 9.72833V6.24588ZM4.77687 16.0801L3.2919 15.247L5.07237 12.1631C5.08309 12.1446 5.09233 12.1255 5.10068 12.1063H7.07108L4.77687 16.0801ZM13.9871 14.4393L8.59182 11.233V4.8545L13.9871 1.55028V14.4393Z" />
    </svg>
  );
};
