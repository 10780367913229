import { config } from "common/config";
import ColoredText from "components/colored-text/colored-text";
import { Message } from "components/ui/Message";
import Pagination from "components/pagination/pagination";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { SearchInput } from "components/ui/UIComponents";
import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchMerchants, filterMerchants, setCurrentPage } from "redux/merchantsSlice";

const MerchantListPage = () => {
	const [selected, setSelected] = useState(0);
	const [list, setList] = useState([]);
	const [search, setSearch] = useState();
	const history = useHistory();
	const dispatch = useDispatch();
	const { loading, error, merchants, filteredMerchants, currentPage, totalPage } = useSelector(
		(state) => state.merchants
	);

	const pageConfig = config.merchantsPage.pageConfig;

	useEffect(() => {
		dispatch(fetchMerchants({ currentPage }));
	}, []);

	// Update Table
	useEffect(() => {
		mapColumns(merchants);

		if (filteredMerchants) {
			mapColumns(filteredMerchants);
		}
	}, [merchants, filteredMerchants]);

	function mapColumns(array) {
		const merchants = array.map((merchant) => ({
			...merchant,
			joined:
				merchant.joined === "Today" ? (
					<ColoredText text={merchant.joined} color="text-success" />
				) : (
					merchant.joined
				),
			action: (
				<button onClick={() => navigateToDetails(merchant.id)}>
					<BsChevronRight size={15} />
				</button>
			),
		}));

		setList(merchants);
	}

	const searchMerchant = async (search) => {
		setSearch(search);
		setSelected(0);

		dispatch(
			fetchMerchants({
				currentPage: 0,
				search,
			})
		);
	};

	const handleFilter = (index) => {
		setSelected(index);

		let filter;
		if (index === 0) {
			filter = "";
		} else {
			filter = pageConfig.filters[index].value;
		}

		dispatch(setCurrentPage(0));

		dispatch(
			fetchMerchants({
				currentPage: 0,
				search,
				filter,
				sort: "date",
				order: "desc",
			})
		);
	};

	const navigateToDetails = (id) => {
		history.push(config.routes.merchantDetails.replace("id", id));
	};

	const handlePagination = (value) => {
		const currentIndex = Math.max(0, Math.min(currentPage + value, totalPage - 1));
		dispatch(setCurrentPage(currentIndex));

		dispatch(
			fetchMerchants({
				currentPage: currentIndex,
				search,
				filter: pageConfig.filters[selected].value,
				sort: "date",
				order: "desc",
			})
		);
	};

	const handleSpecificPagination = (value) => {
		dispatch(setCurrentPage(value));

		dispatch(
			fetchMerchants({
				currentPage: value,
				search,
				filter: pageConfig.filters[selected].value,
				sort: "date",
				order: "desc",
			})
		);
	};

	const sort = (key, order) => {
		dispatch(fetchMerchants({ currentPage, sort: key, order }));
	};

	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					<Spinner show={loading} />
					<div className=" text-black min-h-screen p-8">
						{error && <Message variant="danger" message={error} />}
						<div className="w-2/4">
							<SearchInput
								value={search}
								onChange={(e) => searchMerchant(e.target.value)}
								placeholder="Search Store Name, Seller ID"
								icon={<FiSearch size="18" />}
							/>
						</div>
						<div className="flex flex-row my-6 divide-x-2 divide-gray-500">
							{pageConfig.filters.map((filter, index) => (
								<div
									key={filter + index}
									className={`px-5 cursor-pointer  ${
										selected === index ? "text-gray-800 font-semibold" : "m-txt--lightGrey"
									}`}
									onClick={() => handleFilter(index)}
								>
									{filter.label}
								</div>
							))}
						</div>

						<TableComponent
							headers={config.merchantsPage.sellerHeader}
							rows={list}
							hasButton={true}
							hasSorting={true}
							sortFunction={sort}
						/>

						<div className="flex flex-row items-center justify-end my-2">
							<button onClick={() => handlePagination(-1)}>
								<AiFillCaretLeft size="16" color={currentPage == 0 ? "#D2D2D2" : "#000000"}/>
							</button>

							<Pagination totalPages={totalPage} currentPage={currentPage + 1} onPageClick={handleSpecificPagination}/>

							<button onClick={() => handlePagination(+1)}>
								<AiFillCaretRight size="16" color={currentPage == totalPage - 1  ? "#D2D2D2" : "#000000"}/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MerchantListPage;
