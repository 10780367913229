import React from "react";

const ProductCategory = ({ category }) => {
  return (
    <div className="flex w-full items-center px-4">
      {category?.categoryName?.name ? (
        <img
          className="w-6 h-6 mr-2"
          src={category?.categoryName?.image}
          alt={category?.categoryName?.name}
        />
      ) : null}

      <p>{category?.categoryName?.name}</p>
    </div>
  );
};

export default ProductCategory;
