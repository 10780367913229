import React, { useState } from "react";
import SortHeader from "./SortHeader";
import { streamStatusColorFormat, streamPastStatusColorFormat } from "common/utility";

const TableComponent = (props) => {
	const columnsLength = Object.keys(props?.headers)?.length;

	const [activeSortOrder, setActiveSort] = useState(null);

	return (
		<div className="flex w-full justify-center items-center rounded-lg flex-col">
			<div className="flex flex-row w-full divide-x">
				{props.headers
					? props.headers.map((header, index) => {
							if (index === columnsLength - 1 && props.hasButton) {
								return (
									<div
										key={header.key}
										className="flex w-1/2 bg-tableHeaderColor rounded-tr-md text-white justify-center  items-center h-12 px-4"
									>
										{header.label}
									</div>
								);
							}
							if (index === 0) {
								return (
									<div
										key={header.key}
										className="flex w-full bg-tableHeaderColor rounded-tl-md text-white items-center h-12 text-left justify-between px-4"
									>
										<div className="pl-4">{header.label}</div>
										<div className="mr-8">
											{header.hasSorting ? (
												<SortHeader
													sortFunction={props.sortFunction}
													sortKey={header.key}
													setActiveSortOrder={setActiveSort}
													activeSortOrder={activeSortOrder}
												/>
											) : null}
										</div>
									</div>
								);
							} else {
								return (
									<div
										key={header.key}
										className={
											`flex w-full bg-tableHeaderColor text-white items-center h-12 justify-around px-4
											${index === columnsLength - 1 ? "rounded-tr-md" : ""}`
										}
									>
										{header.label}
										{header.hasSorting ? (
											<SortHeader
												sortFunction={props.sortFunction}
												sortKey={header.key}
												setActiveSortOrder={setActiveSort}
												activeSortOrder={activeSortOrder}
											/>
										) : null}
									</div>
								);
							}
					  })
					: null}
			</div>

			<div className="flex flex-col w-full">
				{!(props?.rows?.length > 0) && <div className="mx-auto mt-10">No items found</div>}
				{props.rows &&
					props.rows.map((column, index) => {
						return (
							<div
								className={`flex 
									flex-row 
									h-16
									${index == props.rows.length -1 ? "rounded-b-md" : "" } 
									divide-x 
									overflow-auto 
									text-${streamPastStatusColorFormat(column["status"]).color}`
								}
								key={column + index}
								style={{backgroundColor: (index + 1) % 2 == 0 ? "#F4f4f4" : "#FFFFFF"}}
							>
								{Object.keys(column).map((key, index) => {
									switch (true) {
										case index === columnsLength - 1 && props.hasButton:
											return (
												<button
													key={index}
													{...column[key].props}
													className={`flex  w-1/2  justify-center items-center divide-x border-b-2 px-4  text-sm text-${
														streamPastStatusColorFormat(column["status"]).color
													}`}
												></button>
											);

										case index === 0:
											return props.hideId ? null : (
												<div
													key={index}
													className={`flex w-full items-center divide-x border-b-2 break-all px-4 text-sm text-${
														streamPastStatusColorFormat(column["status"]).color
													}`}
												>
													<div className="pl-4">{column[key]}</div>
												</div>
											);

										case index !== 0:
											return (
												<>
													{(key == "status" && column["status"] == "Past" || key == "status" && column["status"] == "Posted") ? (
														<div
															key={index}
															className={`flex w-full justify-center px-4
				   items-center divide-x border-b-2 text-gray`}
														>
															{column[key]}
														</div>
													) : (key == "status" && column["status"] == "Hidden") ? (
														<div
															key={index}
															className={`flex w-full justify-center px-4
				   items-center divide-x border-b-2 text-red-500`}
														>
															{column[key]}
														</div>
													) :
													(key == "status" && column["status"] == "Upcoming") ||
													  (key == "status" && column["status"] == "Live" ||key == "status" && column["status"] ==  "Scheduled") ? (
														<div
															key={index}
															className={`flex w-full justify-center px-4
			   items-center divide-x border-b-2 text-green-500`}
														>
															{column[key]}
														</div>
													) : key != "status" && column["status"] == "Past" ? (
														<div
															key={index}
															className={`flex w-full justify-center px-4
		   items-center divide-x border-b-2 text-gray`}
														>
															{column[key]}
														</div>
													) : (key != "status" && column["status"] == "Upcoming") ||
													  (key != "status" && column["status"] == "Live") ? (
														<div
															key={index}
															className={`flex w-full justify-center px-4
	   items-center divide-x border-b-2 text-black`}
														>
															{column[key]}
														</div>
													) : (
														<div
															key={index}
															className={`flex w-full px-4
	   items-center divide-x border-b-2 text-black`}
														>
															{column[key]}
														</div>
													)}
												</>
											);

										case column[key] === "id":
											return props.hideId ? null : (
												<div
													key={index}
													className={`flex w-full justify-center px-4
                       items-center divide-x border-b-2 text-${streamPastStatusColorFormat(column["status"]).color}`}
												>
													{column[key]}
												</div>
											);

										default:
											return null;
									}
								})}
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default TableComponent;
