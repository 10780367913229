import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchSingleStreamDetails } from "redux/streamSlice";
import { fetchCompanyDocuments } from "redux/merchantsSlice";
import ReactPlayer from "react-player";
import moment from "moment";
import { config } from "common/config";
import api from "api/api";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import TableComponent from "components/ui/TableComponent";
import { NavigationTitle, RoundedButton, Row } from "components/ui/UIComponents";
import Button from "components/button/button";
import Popup from "components/popup/popup";
import Alert from "components/alert/alert";
import { streamStatusFormat, preRecordStatusFormat } from "common/utility";
import { dateFormat, timeFormat } from "common/utility";
import ImageNotFound from "common/image-not-found.jpg";
import HideStreamIcon from "appassets/liveStream/hide-stream-icon.png";

export default function LiveStreamVideoDetailPage() {
	const history = useHistory();
	const { id } = useParams();
	const dispatch = useDispatch();
	const { singlestreamDetails, loading, error } = useSelector((state) => state.streams);
	const { companyDetails } = useSelector((state) => state.merchants);
	const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
	const [isHideVideoModalVisible, setIsHideVideoModalVisible] = useState(false);
	const [video, setVideo] = useState();
	const [productList, setProductList] = useState([]);
	const [alert, setAlert] = useState({
		show: false,
		message: "",
	});

	useEffect(() => {
		dispatch(fetchSingleStreamDetails({ streamId: id }));
	}, [id]);

	useEffect(() => {
		dispatch(fetchCompanyDocuments({ sellerId: singlestreamDetails?.companyId }));
	}, [singlestreamDetails?.companyId]);

	const playVideo = (url) => {
		setVideo(url);
		setIsVideoModalVisible(true);
	};

	useEffect(() => {
		let productList = [];

		if (singlestreamDetails?.streamProducts) {
			productList = singlestreamDetails?.streamProducts?.map((product) => ({
				productName: product.productName,
				category: product.category,
				sku: product.productSKU,
				stock: product.stock,
				price: product.productPrice.toFixed(2),
			}));

			setProductList(productList);
		}
	}, [singlestreamDetails?.streamProducts]);

	const customStyles = {
		content: {
			border: "none",
			width: "fit-content",
			height: "fit-content",
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: 10,
			padding: 0,
		},
	};

	const handleHideStream = async () => {
		let event = "setToSuspended";

		if (singlestreamDetails?.streamStatus == 4) {
			if (singlestreamDetails?.streamDate <= moment()) {
				event = "setToStreaming";
			} else {
				event = "setToOffline";
			}
		}

		const response = await api.post.triggerStreamEvent(singlestreamDetails?.id, event);
		if (response.status == 200) {
			dispatch(fetchSingleStreamDetails({ streamId: id }));
			if (event !== "setToSuspended") {
				setAlert({
					show: true,
					message: "Video is shown to the public"
				})
			} else {
				setAlert({
					show: true,
					message: "Video is hidden from the public"
				})
			}
		}
	};

	return (
		<>
			<Spinner show={loading} />
			<div className=" text-black min-h-full min-h-screen p-8">
				{error && <Message variant="danger" message={error} />}
				<NavigationTitle
					prevPage={companyDetails?.sellerDetails?.companyName}
					toPrevPage={"/admin/streams/" + singlestreamDetails?.companyId}
					prevPrevPage="Video/Live stream "
					toPrevPrevPage="/admin/streams/"
					currentPage={singlestreamDetails?.prerecordVideoPath ? "Pre-recorded video details" : "Live stream details"}
				/>
				<div className="flex items-end">
					<div className="flex-1">
						<h1 className="font-bold text-lg pt-12 px-6 inline-block">
							Video ID: {singlestreamDetails?.id}
						</h1>
						<h4
							className="font-light rounded-lg text-white inline-block px-6 align-middle"
							style={
								singlestreamDetails?.prerecordVideoPath
									? {
											backgroundColor: preRecordStatusFormat(singlestreamDetails?.streamStatus)
												.backgroundColor,
											fontSize: "12px",
									  }
									: {
											backgroundColor: streamStatusFormat(singlestreamDetails?.streamStatus)
												.backgroundColor,
											fontSize: "12px",
									  }
							}
						>
							{singlestreamDetails?.prerecordVideoPath
								? preRecordStatusFormat(singlestreamDetails?.streamStatus).label
								: streamStatusFormat(singlestreamDetails?.streamStatus).label}
						</h4>
						{singlestreamDetails?.streamStatus == 4 ? (
							<h4 className="m-txt m-txt--s m-txt--grey inline-block px-6">
								Hide on: {dateFormat(singlestreamDetails?.updatedAt)}
							</h4>
						) : null}
					</div>
					{singlestreamDetails?.prerecordVideoPath ? (
					<div className="flex flex-1 justify-end mr-6">
						{singlestreamDetails?.streamStatus !== 4 ? (
							<Button
								small
								outline
								danger
								label="Hide from public"
								onClick={() => setIsHideVideoModalVisible(true)}
							/>
						) : (
							<Button small outline success label="Show to public" onClick={() => handleHideStream()} />
						)}
					</div>):null}
				</div>
				<div className="bg-white rounded-md p-6 mt-8">
					<h2 className="bg-primaryBg px-4 py-4 mr-4">Video information</h2>
					<div className="px-4 pt-4 pb-2">
						<h1 className="inline-block text-xs" style={{ color: "#828282" }}>
							Date & time to start
						</h1>
						{singlestreamDetails?.prerecordVideoPath ? (
							<div className="inline-block float-right">
								<h1 className="inline-block text-xs" style={{ color: "#828282" }}>
									Posted Video:
								</h1>
								<h1 className="inline-block text-xs mx-2">Video.mp4</h1>
								<FiEye
									className="inline-block cursor-pointer"
									onClick={() => playVideo(singlestreamDetails?.prerecordVideoPath)}
								/>
							</div>
						) : null}
						<h1 className="font-medium">
							{dateFormat(singlestreamDetails?.streamDate)}, {timeFormat(singlestreamDetails?.streamDate)}
						</h1>
						<h1 className="inline-block text-xs pt-6" style={{ color: "#828282" }}>
							Live Title
						</h1>
						<h1 className="font-medium">{singlestreamDetails?.title}</h1>
						<h1 className="inline-block text-xs pt-6" style={{ color: "#828282" }}>
							Cover photo
						</h1>
						<div>
							<img
								src={singlestreamDetails?.image !== "" ? singlestreamDetails?.image : ImageNotFound}
								className="w-24 object-fill pt-2"
								alt="cover"
							/>
						</div>
						<h1 className="inline-block text-xs pt-6" style={{ color: "#828282" }}>
							Products
						</h1>
						<div className="p-5 h-full flex flex-wrap content-between">
							<TableComponent headers={config.streamDetailsPageProduct.detailHeader} rows={productList} />
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={isVideoModalVisible}
				onRequestClose={() => setIsVideoModalVisible(false)}
				style={customStyles}
			>
				<div
					className="absolute justify-end m-4"
					style={{ right: 0, zIndex: 50 }}
					onClick={() => setIsVideoModalVisible(false)}
				>
					<button onClick={() => setIsVideoModalVisible(false)}>
						<MdClose size="24" color="gray" />
					</button>
				</div>
				<ReactPlayer
					url={video}
					controls={true}
					width={"fit-content"}
					height={"auto"}
					className="cursor-pointer video-wrapper"
				/>
			</Modal>
			<Popup open={isHideVideoModalVisible} onPopupClose={() => setIsHideVideoModalVisible(false)}>
				<div>
					<div className="flex justify-center mb-5">
						<img src={HideStreamIcon} />
					</div>
					<div className="px-5">
						<p className="m-txt m-txt--m m-txt--bold mb-5">Confirm to hide this post from public?</p>
						<p className="m-txt">Seller will get notified once the post is hidden from public</p>
					</div>
					<div className="flex flex-row space-x-2 justify-center mt-6">
						<Button text label="CANCEL" onClick={() => setIsHideVideoModalVisible(false)} />
						<Button
							text
							danger
							label="HIDE"
							onClick={() => {
								handleHideStream();
								setIsHideVideoModalVisible(false);
							}}
						/>
					</div>
				</div>
			</Popup>
			<Alert message={alert.message} show={alert.show} onClose={(show) => setAlert({ show })} />
		</>
	);
}
