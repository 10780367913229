import React from "react";

import Download from "appassets/merchants/upload.svg";
import Document from "appassets/merchants/document.svg";

const DocumentButton = ({ url, index }) => {

	const download = (file, index) => {
		var FileSaver = require('file-saver');
		var type =  file.substring(file.lastIndexOf('.'));
		var fileName = 'Document ' + index + type;
		FileSaver.saveAs(file, fileName);
	}

	return (
		<a onClick={() => download(url, (index+1))}  download target="blank"  style={{cursor: 'pointer'}}>
			<div className="ml-6 mt-4">
				<div className="flex flex-row border-black border-b-4 bg-gray-200 px-4 py-2 w-64 justify-between items-center">
					<img src={Document} alt="document" />
					Document {index + 1}
					<img src={Download} alt="download" />
				</div>
			</div>
		</a>
	);
};

export default DocumentButton;
