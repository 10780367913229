import React from "react";

export const ADSIcon = ({ size }) => {
  return (
    <svg
      className="fill-current"
      width={size || 19}
      height={size || 19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.95187 10.7333H3.77266C3.41178 10.7333 3.1189 10.4404 3.1189 10.0795C3.1189 9.71867 3.41178 9.42578 3.77266 9.42578H5.95187C6.31275 9.42578 6.60564 9.71867 6.60564 10.0795C6.60564 10.4404 6.31275 10.7333 5.95187 10.7333Z" />
      <path d="M16.8229 19H2.17708C0.976125 19 0 18.0239 0 16.8229V2.17708C0 0.976125 0.976125 0 2.17708 0H16.8229C18.0239 0 19 0.976125 19 2.17708V16.8229C19 18.0239 18.0239 19 16.8229 19ZM2.17708 1.1875C1.63163 1.1875 1.1875 1.63163 1.1875 2.17708V16.8229C1.1875 17.3684 1.63163 17.8125 2.17708 17.8125H16.8229C17.3684 17.8125 17.8125 17.3684 17.8125 16.8229V2.17708C17.8125 1.63163 17.3684 1.1875 16.8229 1.1875H2.17708Z" />
      <path d="M9.00284 12.0407H8.34908C7.9882 12.0407 7.69531 11.7478 7.69531 11.3869V7.46431C7.69531 7.10343 7.9882 6.81055 8.34908 6.81055H9.00284C10.4446 6.81055 11.6179 7.98383 11.6179 9.4256C11.6179 10.8674 10.4446 12.0407 9.00284 12.0407ZM9.00284 8.11807V10.7331C9.72372 10.7331 10.3104 10.1465 10.3104 9.4256C10.3104 8.70472 9.72372 8.11807 9.00284 8.11807Z" />
      <path d="M5.95187 12.0407C5.59099 12.0407 5.29811 11.7478 5.29811 11.3869V8.55392C5.29811 8.31333 5.10285 8.11807 4.86227 8.11807C4.62168 8.11807 4.42642 8.31333 4.42642 8.55392V11.3869C4.42642 11.7478 4.13354 12.0407 3.77266 12.0407C3.41178 12.0407 3.1189 11.7478 3.1189 11.3869V8.55392C3.1189 7.59245 3.9008 6.81055 4.86227 6.81055C5.82373 6.81055 6.60564 7.59245 6.60564 8.55392V11.3869C6.60564 11.7478 6.31275 12.0407 5.95187 12.0407Z" />
      <path d="M14.3599 12.0407H13.089C12.7281 12.0407 12.4352 11.7478 12.4352 11.3869C12.4352 11.026 12.7281 10.7331 13.089 10.7331H14.3599C14.4976 10.7331 14.6144 10.5832 14.6144 10.4062C14.6144 10.2293 14.4985 10.0794 14.3599 10.0794H13.9964C13.1352 10.0794 12.4343 9.34628 12.4343 8.44496C12.4343 7.54363 13.136 6.81055 13.9973 6.81055H15.2682C15.6291 6.81055 15.9219 7.10343 15.9219 7.46431C15.9219 7.82519 15.6291 8.11807 15.2682 8.11807H13.9973C13.8595 8.11807 13.7427 8.268 13.7427 8.44496C13.7427 8.62191 13.8587 8.77184 13.9973 8.77184H14.3607C15.222 8.77184 15.9228 9.50492 15.9228 10.4062C15.9228 11.3076 15.2211 12.0407 14.3599 12.0407Z" />
    </svg>
  );
};
