import { BiHome, BiPackage, BiVideoRecording } from "react-icons/bi";
import { CgMenuRound } from "react-icons/cg";
import { ADSIcon } from "components/icons/ads";
import { AnnouncementIcon } from "components/icons/announcements";
import { MonthlySettlementIcon } from "components/icons/monthly_settlement";
import { ProductCategoryIcon } from "components/icons/product-category";
import AdminAdApplys from "pages/Admin/AdApplication/AdminAdApplys";
import AdminAnnouncementPage from "pages/Admin/Announcements/AdminAnnouncementPage";
import AdminDashboardPage from "pages/Admin/Dashboard/AdminDashboardPage";
import MerchantAdApplyDetails from "pages/Admin/AdApplication/MerchantAdApplyDetails";
import MerchantDetailsPage from "pages/Admin/Merchant/MerchantDetailsPage";
import MerchantListPage from "pages/Admin/Merchant/MerchantListPage";
import AdminMonthlySettlement from "pages/Admin/MonthlyStatement/AdminMonthlySettlement";
import MerchantMonthlySettlementDetailPage from "pages/Admin/MonthlyStatement/MerchantMonthlySettlementDetailPage";
import OrderDetailsPage from "pages/Admin/Orders/OrderDetailsPage";
import OrdersListPage from "pages/Admin/Orders/OrdersListPage";
import ProductCategoriesPage from "pages/Admin/ProductCategories/ProductCategoriesPage";
import AdminStreamsPage from "pages/Admin/Streams/AdminStreamsPage";
import AdminStreamsDetailPage from "pages/Admin/Streams/MerchantStreamsDetailPage";
import LiveStreamVideoDetailPage from "pages/Admin/Streams/LiveStreamVideoDetailPage";
import AdminAccountSettingPage from "pages/Admin/Account/AdminAccountSettingPage";

export const pathnames = {
	dashboard: '/dashboard',
	merchants: '/merchants',
	merchantDetails: '/merchants/merchant/:id',
	orders: '/orders',
	orderDetails: '/orders/order/:id',
	streams: '/streams',
	streamDetails: '/streams/:id',
	LiveStreamVideoDetails: '/streams/details/:id',
	adApplys: '/ad-applys',
	adApplyDetails: '/ad-applys/:id',
	monthlySettlement: '/monthly-settlement',
	monthlySettlementDetails: '/monthly-settlement/:id/:month',
	announcement: '/announcements',
	productCategory: '/product-category',
	accountSetting: '/account/settings',

}

export const routes = [
	{
		path: pathnames.dashboard,
		component: AdminDashboardPage,
		isPrivate: true,
		icon: <BiHome size="24" />,
		name: "Dashboard",
		showOnSideBar: true,
	},

	{
		path: pathnames.merchants,
		component: MerchantListPage,
		isPrivate: true,
		icon: <BiPackage size="24" />,
		name: "Seller",
		showOnSideBar: true,
	},
	{
		path: pathnames.merchantDetails,
		component: MerchantDetailsPage,
		isPrivate: true,
		name: "Seller Details",
		showOnSideBar: false,
	},
	{
		path: pathnames.orders,
		component: OrdersListPage,
		isPrivate: true,
		icon: <CgMenuRound size="24" />,
		name: "Orders",
		showOnSideBar: true,
	},
	{
		path: pathnames.orderDetails,
		component: OrderDetailsPage,
		isPrivate: true,
		name: "Order Details",
		showOnSideBar: false,
	},
	{
		path: pathnames.streams,
		component: AdminStreamsPage,
		isPrivate: true,
		icon: <BiVideoRecording size="24" />,
		name: "Video/Live Stream",
		showOnSideBar: true,
	},
	{
		path: pathnames.streamDetails,
		component: AdminStreamsDetailPage,
		isPrivate: true,
		name: "Stream Details",
		showOnSideBar: false,
	},
	{
		path: pathnames.LiveStreamVideoDetails,
		component: LiveStreamVideoDetailPage,
		isPrivate: true,
		name: "Video/Live Stream Details",
		showOnSideBar: false,
	},
	{
		path: pathnames.accountSetting,
		component: AdminAccountSettingPage,
		isPrivate: true,
		name: "Account Settings",
		showOnSideBar: false,
	},
	{
		path: pathnames.adApplys,
		component: AdminAdApplys,
		isPrivate: true,
		name: "Ads Management",
		icon: <ADSIcon size="24" />,
		showOnSideBar: true,
	},
	{
		path: pathnames.adApplyDetails,
		component: MerchantAdApplyDetails,
		isPrivate: true,
		name: "Ads Management Details",
		showOnSideBar: false,
	},
	{
		path: pathnames.monthlySettlement,
		component: AdminMonthlySettlement,
		isPrivate: true,
		name: "Monthly Settlement",
		icon: <MonthlySettlementIcon size="24" />,
		showOnSideBar: true,
	},
	{
		path: pathnames.monthlySettlementDetails,
		component: MerchantMonthlySettlementDetailPage,
		isPrivate: true,
		name: "Monthly Settlement Details",
		showOnSideBar: false,
	},
	{
		path: pathnames.announcement,
		component: AdminAnnouncementPage,
		isPrivate: true,
		name: "Announcements",
		icon: <AnnouncementIcon size="24" />,
		showOnSideBar: true,
	},
	{
		path: pathnames.productCategory,
		component: ProductCategoriesPage,
		isPrivate: true,
		name: "Product Categories",
		icon: <ProductCategoryIcon size="24" />,
		showOnSideBar: true,
	},
];
