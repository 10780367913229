import React from "react";

const Pagination = ({ totalPages, currentPage, onPageClick }) => {
  return (
    <div className="pagination">
      {Array.from(Array(totalPages), (e, i) => {
        if (i === 8) {
          return (
            <span className='pagination__item' key={i}>
              ...
            </span>
          );
        }
        if (i > 8) {
          return '';
        } else {
          return (
            <span 
                className={
                    i + 1 == currentPage ? 
                    "pagination__item pagination__item--active m-txt m-txt--grey" : 
                    "pagination__item m-txt m-txt--grey"
                }
                key={i}
                onClick={() => onPageClick(i)}
            >
              {i + 1}
            </span>
          );
        }
      })}
      {/* {currentPage} / {totalPages} */}
    </div>
  );
};

export default Pagination;
