import AppleSVG from "appassets/mobileUser/appleStore.svg";
import GoogleSVG from "appassets/mobileUser/googlePlay.svg";
import MobileUserSVG from "appassets/mobileUser/mobileUser.svg";
import Navbar from "components/common/Navbar";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function MobileUser({ location }) {
  const history = useHistory();

  useEffect(() => {
    if (window.innerWidth > 1024) {
      history.push("/");
    }
  }, [location.pathname]);
  return (
    <>
      <Navbar />
      <div className="w-full h-screen flex flex-col items-center justify-center space-y-6 px-8">
        <img src={MobileUserSVG} alt="notfound" className="w-full" />
        <h2>
          For better mobile experience, please download our apps on the app
          stores.
        </h2>
        <div className="grid grid-cols-2 space-x-2">
          <div>
            <img src={AppleSVG} alt="" />
          </div>
          <div>
            <img src={GoogleSVG} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
