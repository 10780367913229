import React from "react";

const TimePeriodPress = ({ selected, index, handleDatePicker, item }) => {
	return (
		<div
			className={`w-16 h-10 flex items-center justify-center ${
				selected === index ? "bg-black text-white border-none" : "bg-white m-txt--lightGrey"
			}`}
			index={index}
			onClick={() => handleDatePicker(item, index)}
			selectedPeriod={selected}
			key={`${index}_${item.label}`}
		>
			<div className="font-semibold text-sm">{item.label}</div>
		</div>
	);
};

export default TimePeriodPress;
