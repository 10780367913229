export function findByValue(array, string, key) {
	if (key) {
		const mutatedArray = array.filter((o) => o[key].toLowerCase().includes(string.toLowerCase()));
		return mutatedArray;
	} else {
		let flatten = array.map((item) => {
			return flattenObject(item);
		});
		let filtered = flatten?.filter((o) =>
			Object.keys(o).some((key) => {
				const value = o[key]?.toString();
				if (value !== undefined || value !== "" || value !== null) {
					if (value?.toLowerCase().includes(string.toLowerCase())) {
						return o;
					} else return null;
				} else return null;
			})
		);

		//compare id of two array, case for product categories
		const results = array.filter(({ id: id1 }) => filtered.some(({ id: id2 }) => id2 === id1));
		return results;
	}
}

export function findByKeys(array, string, keys) {
	if (keys.length > 0) {
		const mutatedArray = [];

		array.filter((o) =>
			keys.forEach((element) => {
				const value = o[element].toString();

				if (value !== undefined) {
					if (value.toLowerCase().includes(string.toLowerCase())) {
						mutatedArray.push(o);
					} else {
						return null;
					}
				} else {
					return null;
				}
			})
		);
		return mutatedArray;
	}
}

export const debounce = (func, wait) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			timeout = null;
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

function flattenObject(data) {
	var result = {};
	function recurse(cur, prop) {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (Array.isArray(cur)) {
			for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop ? prop + "." + i : "" + i);
			if (l == 0) result[prop] = [];
		} else {
			var isEmpty = true;
			for (var p in cur) {
				isEmpty = false;
				recurse(cur[p], prop ? prop + "." + p : p);
			}
			if (isEmpty) result[prop] = {};
		}
	}
	recurse(data, "");
	return result;
}

export const getCommaSeparatedNumber = (price, points = 2) => {
	if (price > 0) {
		return parseFloat(price)
			.toFixed(points)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	if (price === 0) return parseFloat("0.00").toFixed(points);
};
