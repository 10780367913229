/*eslint no-undef: "error"*/
/*eslint-env browser*/
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { RoundedButton, Row, NavigationTitle, ImageInput } from "components/ui/UIComponents";
import { useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdDetails, verifyBanner } from "redux/adSlice";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import Pill from "components/ui/Pill";
import Popup from "components/popup/popup";
import Button from "components/button/button";
import { dateFormat, formatDate } from "common/utility";
import deleteIcon from "appassets/add-products/delete-icon.png";
import DeleteBannerIcon from "appassets/adApply/delete-banner-icon.png";
import api from "api/api";
import { IoIosWarning } from "react-icons/io";
import moment from "moment";

const MerchantAdApplyDetails = () => {
	const history = useHistory();
	const [banner, setBanner] = useState({ desktop: null, mobile: null });

	const { id } = useParams();

	const dispatch = useDispatch();

	const { loading, error, bannerDetails, verifyBannerSuccess } = useSelector((state) => state.ad);
	const [additionalMsg, setAdditionalMsg] = useState("");
	const [textareaCount, setTextareaCount] = useState(0);
	const [deleteBannerPopup, setDeleteBannerPopup] = useState(false);
	const [selectedBanner, setSelectedBanner] = useState();
	const [errorMsg, setErrorMsg] = useState({
		desktop: "",
		mobile: "",
	});
	const [bannerDimension, setBannerDimension] = useState({
		desktop: {
			width: bannerDetails?.desktopImage?.width,
			height: bannerDetails?.desktopImage?.height,
			size: (bannerDetails?.desktopImage?.size / 1024).toFixed(2),
		},
		mobile: {
			width: bannerDetails?.mobileImage?.width,
			height: bannerDetails?.mobileImage?.height,
			size: (bannerDetails?.mobileImage?.size / 1024).toFixed(2),
		},
	});
	const adsType = [
		{
			name: "Desktop Ad",
			type: "desktop",
			width: 1100,
			height: 400,
			dimension: bannerDetails?.desktopImage?.dimension,
			size: (bannerDetails?.desktopImage?.size / 1024).toFixed(2),
		},
		{
			name: "Mobile Ad",
			type: "mobile",
			width: 600,
			height: 380,
			dimension: bannerDetails?.mobileImage?.dimension,
			size: (bannerDetails?.mobileImage?.size / 1024).toFixed(2),
		},
	];

	useEffect(() => {
		if (id !== "new") {
			dispatch(fetchAdDetails(id));
		}
	}, [id]);

	useEffect(() => {
		if (id !== "new") {
			setBanner({
				...banner,
				desktop: bannerDetails?.desktopImage?.file,
				mobile: bannerDetails?.mobileImage?.file,
			});

			setBannerDimension({
				...bannerDimension,
				desktop: {
					width: bannerDetails?.desktopImage?.width,
					height: bannerDetails?.desktopImage?.height,
					size: (bannerDetails?.desktopImage?.size / 1024).toFixed(2),
				},
				mobile: {
					width: bannerDetails?.mobileImage?.width,
					height: bannerDetails?.mobileImage?.height,
					size: (bannerDetails?.mobileImage?.size / 1024).toFixed(2),
				},
			});
		}
	}, [bannerDetails]);

	useEffect(() => {
		setTextareaCount(additionalMsg.length);
	}, [additionalMsg]);

	const navigateBack = () => {
		history.goBack();
	};

	const createNotification = (type, message, title) => {
		switch (type) {
			case "info":
				NotificationManager.info(message);
				break;
			case "success":
				NotificationManager.success(message);
				break;
			case "warning":
				NotificationManager.warning(message);
				break;
			case "error":
				NotificationManager.error(title, message);
				break;
		}
	};

	const onDragOver = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const onDrop = (e, uploadMethod, bannerType) => {
		const fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/bmp"];
		let imageSizeTooBig = 1000000;
		if (uploadMethod === "browse") {
			if (!fileTypes.includes(e.target.files[0].type)) {
				createNotification("error", "File format must be either png, jpg or bmp");
				return false;
			}
			let img = new Image();
			let image = {
				uri: URL.createObjectURL(e.target.files[0]),
				name: e.target.files[0].name,
				type: e.target.files[0].type,
				file: e.target.files[0],
			};
			img.src = image.uri;
			if (bannerType == "desktop") {
				setBanner({
					...banner,
					desktop: image,
				});
				//check desktop banner size
				if (e.target.files[0].size > imageSizeTooBig) {
					setErrorMsg({ ...errorMsg, desktop: "File size exceeded 1MB" });
					return false;
				}
				//check desktop banner dimension
				img.onload = () => {
					if (img.width != adsType[0].width && img.height != adsType[0].height) {
						setErrorMsg({ ...errorMsg, desktop: "Invalid dimension" });
						return false;
					}
				};
				setErrorMsg({ ...errorMsg, desktop: "" });
			} else if (bannerType == "mobile") {
				setBanner({
					...banner,
					mobile: image,
				});
				//check mobile banner size
				if (e.target.files[0].size > imageSizeTooBig) {
					setErrorMsg({ ...errorMsg, mobile: "File size exceeded 1MB" });
					return false;
				}
				//check mobile banner dimension
				img.onload = () => {
					if (img.width != adsType[1].width && img.height != adsType[1].height) {
						setErrorMsg({ ...errorMsg, mobile: "Invalid dimension" });
						return false;
					}
				};
				setErrorMsg({ ...errorMsg, mobile: "" });
			}
		} else {
			e.preventDefault();
			let img = new Image();
			const {
				dataTransfer: { files },
			} = e;
			const { size, type } = files[0];
			if (!fileTypes.includes(type)) {
				createNotification("error", "File format must be either png, jpg or bmp");
				return false;
			}
			let image = {
				uri: URL.createObjectURL(files[0]),
				name: files[0].name,
				type: files[0].type,
				file: files[0],
			};
			img.src = image.uri;
			if (bannerType == "desktop") {
				setBanner({
					...banner,
					desktop: image,
				});
				//check desktop banner size
				if (size > imageSizeTooBig) {
					setErrorMsg({ ...errorMsg, desktop: "File size exceeded 1MB" });
					return false;
				}
				//check desktop banner dimension
				img.onload = () => {
					if (img.width != adsType[0].width && img.height != adsType[0].height) {
						setErrorMsg({ ...errorMsg, desktop: "Invalid dimension" });
						return false;
					}
				};
				setErrorMsg({ ...errorMsg, desktop: "" });
			} else if (bannerType == "mobile") {
				setBanner({
					...banner,
					mobile: image,
				});
				//check mobile banner size
				if (size > imageSizeTooBig) {
					setErrorMsg({ ...errorMsg, mobile: "File size exceeded 1MB" });
					return false;
				}
				//check mobile banner dimension
				img.onload = () => {
					if (img.width != adsType[1].width && img.height != adsType[1].height) {
						setErrorMsg({ ...errorMsg, mobile: "Invalid dimension" });
						return false;
					}
				};
				setErrorMsg({ ...errorMsg, mobile: "" });
			}
		}
	};

	const handleDeleteBannerPopup = (id, isVisible) => {
		setSelectedBanner(id);
		setDeleteBannerPopup(isVisible);
	};

	const handleBannerDeletion = async () => {
		try {
			const response = await api.get.deleteBanner(selectedBanner);
			setDeleteBannerPopup(false);
			history.replace(`/admin/ad-applys/`, { triggerAlert: true });
		} catch (error) {
			console.log(error);
		}
	};

	const uploadBanner = async () => {
		try {
			let headers = { "Content-Type": "multipart/form-data" };
			const formData = new FormData();
			formData.append("sellerId", 1);
			formData.append("name", banner["desktop"].name.replace(/\.[^/.]+$/, ""));
			formData.append("desktopImage", banner["desktop"].file, banner["desktop"].name);
			formData.append("mobileImage", banner["mobile"].file, banner["mobile"].name);
			formData.append("startDate", formatDate(moment().add(1, "days"), "DD MMM YYYY"));
			formData.append("endDate", formatDate(moment().add(5, "years"), "DD MMM YYYY"));
			const response = await api.post.createBanner(formData, headers);

			history.replace(`/admin/ad-applys/`, { Uploaded: true });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<NotificationContainer />
			<Spinner show={loading} />
			<div className=" text-black min-h-full min-h-screen p-8">
				{error && <Message variant="danger" message={error} />}
				<NavigationTitle
					prevPage="Ads management"
					toPrevPage="/admin/ad-applys"
					currentPage={id != "new" ? "Banner details" : "Add new banner"}
				/>
				<div className="bg-white rounded-md py-6 px-12 mt-8">
					{id == "new" ? (
						<div>
							<div className="flex flex-row justify-end">
								<div className="flex items-center">
									{banner["desktop"] &&
									banner["mobile"] &&
									!errorMsg["desktop"] &&
									!errorMsg["mobile"] ? (
										<RoundedButton label="ADD" dark onClick={() => uploadBanner()} />
									) : (
										<RoundedButton label="ADD" dark className="opacity-50 pointer-events-none" />
									)}
								</div>
							</div>
							<div>
								<p className="font-medium text-lg mb-2">Upload banner</p>
								<p className="mb-1" style={{ color: "#666666" }}>
									Please make sure your uploaded banner are meet the banner rules below:
								</p>
								<p style={{ color: "#666666" }}>1) File Size: Maximum 1MB for each file</p>
								<p style={{ color: "#666666" }}>2) Format: *jpg, *png, *bmp</p>
							</div>
							{/* desktop */}
							<div className="mt-12">
								<div>
									<p className="text-sm min-w-100 inline-block" style={{ color: "#828282" }}>
										Desktop Ad
									</p>
									<p className="text-sm inline-block">Required dimension: </p>
									<p className="text-sm inline-block">
										<strong> 1100px (Width) x 400px (Height)</strong>
									</p>
									{errorMsg["desktop"] != "" ? (
										<div className="inline-block float-right">
											<IoIosWarning className="inline-block text-red-500 text-2xl mr-2" />
											<p className="inline-block text-red-500 text-sm font-bold">
												{errorMsg["desktop"]}
											</p>
										</div>
									) : null}
								</div>
							</div>
							<div className="pt-4" key="desktop">
								<ImageInput
									onDrop={(e) => onDrop(e, "drag", "desktop")}
									onClick={(e) => onDrop(e, "browse", "desktop")}
									onDragOver={(e) => onDragOver(e)}
									inputItem={banner["desktop"]?.uri ? banner["desktop"]?.uri : banner["desktop"]}
									type="banner"
									device="Desktop"
								/>
							</div>
							{/* mobile */}
							<div className="mt-12">
								<div>
									<p className="text-sm min-w-100 inline-block" style={{ color: "#828282" }}>
										Mobile Ad
									</p>
									<p className="text-sm inline-block">Required dimension: </p>
									<p className="text-sm inline-block">
										<strong> 600px (Width) x 380px (Height)</strong>
									</p>
									{errorMsg["mobile"] != "" ? (
										<div className="inline-block float-right">
											<IoIosWarning className="inline-block text-red-500 text-2xl mr-2" />
											<p className="inline-block text-red-500 text-sm font-bold">
												{errorMsg["mobile"]}
											</p>
										</div>
									) : null}
								</div>
							</div>
							<div className="pt-4 m-auto mb-24" style={{ width: "70%" }} key="mobile">
								<ImageInput
									onDrop={(e) => onDrop(e, "drag", "mobile")}
									onClick={(e) => onDrop(e, "browse", "mobile")}
									onDragOver={(e) => onDragOver(e)}
									inputItem={banner["mobile"]?.uri ? banner["mobile"]?.uri : banner["mobile"]}
									type="banner"
									device="Mobile"
								/>
							</div>
						</div>
					) : (
						<div>
							<div className="flex flex-row justify-between">
								<div className="flex items-center">
									<Pill
										color="text-red-500 border-2 border-red-500 py-2 w-full ml-0 cursor-pointer"
										bg="bg-transparent"
										onClick={() => handleDeleteBannerPopup(id, true)}
									>
										<div className="flex flex-row">
											<img
												src={deleteIcon}
												style={{ height: "16px", marginTop: "3px" }}
												className="inline mr-4"
											/>
											<div className="inline">Delete</div>
										</div>
									</Pill>
								</div>
								<div className="flex items-center">
									<p>
										Uploaded date:{" "}
										{dateFormat(moment(bannerDetails.bannerLaunchDate).subtract(1, "days"))}
									</p>
								</div>
							</div>
							{/* desktop */}
							<div className="mt-12">
								<div className="inline-flex">
									<p className="text-sm min-w-100" style={{ color: "#828282" }}>
										Desktop Ad
									</p>
									<p className="text-sm">Required dimension: </p>
									<p className="text-sm">
										<strong> 1100px (Width) x 400px (Height)</strong>
									</p>
								</div>
							</div>
							<div className="pt-4" key="desktop">
								<img
									className="mx-auto"
									src={banner["desktop"]?.uri ? banner["desktop"]?.uri : banner["desktop"]}
									width="50%"
								/>
							</div>
							{/* mobile */}
							<div className="mt-12">
								<div className="inline-flex">
									<p className="text-sm min-w-100" style={{ color: "#828282" }}>
										Mobile Ad
									</p>
									<p className="text-sm">Required dimension: </p>
									<p className="text-sm">
										<strong> 600px (Width) x 380px (Height)</strong>
									</p>
								</div>
							</div>
							<div className="pt-4 mb-12" key="mobile">
								<img
									className="mx-auto"
									src={banner["mobile"]?.uri ? banner["mobile"]?.uri : banner["mobile"]}
									width="30%"
								/>
							</div>
						</div>
					)}
				</div>
				<Popup open={deleteBannerPopup} onPopupClose={() => setDeleteBannerPopup(false)}>
					<div>
						<div className="flex justify-center mb-5">
							<img src={DeleteBannerIcon} />
						</div>
						<div>
							<p className="m-txt m-txt--m m-txt--bold mb-5">
								Are you sure you want to permanently <br /> delete this banner?
							</p>
						</div>
						<div className="flex flex-row space-x-2 justify-center mt-5">
							<Button text label="CANCEL" onClick={() => setDeleteBannerPopup(false)} />
							<Button text danger label="DELETE" onClick={() => handleBannerDeletion()} />
						</div>
					</div>
				</Popup>
			</div>
		</>
	);
};

export default MerchantAdApplyDetails;
