import React from "react";

export function Spinner(props) {
  return props.show ? (
    <div
      className={`flex w-full min-h-full bg-black justify-center items-center absolute opacity-50 z-50`}
    >
      <div className="max-w-lg flex flex-col justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`animate-spin ${props.height} ? ${props.height} : h-10 ${props.width} ? ${props.width} : w-10 stroke-current`}
          style={{color: "#bbbbbb"}}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#00abfb"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="12" y1="6" x2="12" y2="3" />
          <line x1="16.25" y1="7.75" x2="18.4" y2="5.6" />
          <line x1="18" y1="12" x2="21" y2="12" />
          <line x1="16.25" y1="16.25" x2="18.4" y2="18.4" />
          <line x1="12" y1="18" x2="12" y2="21" />
          <line x1="7.75" y1="16.25" x2="5.6" y2="18.4" />
          <line x1="6" y1="12" x2="3" y2="12" />
          <line x1="7.75" y1="7.75" x2="5.6" y2="5.6" />
        </svg>
        <h1 className="fond-bold text-2xl animate-pulse"> Loading...</h1>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
