import { createSlice } from "@reduxjs/toolkit";

export const sideMenuSlice = createSlice({
  name: "sideMenu",
  initialState: {
    isMobile: window.innerWidth <= 768,
    showSideMenu: false,
  },
  reducers: {
    toggleSideMenu: (state) => {
      state.showSideMenu = !state.showSideMenu;
    },
    checkSideMenu: (state) => {
      state.isMobile = window.innerWidth <= 768;
    },
  },
});

export const { checkSideMenu, toggleSideMenu } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;
