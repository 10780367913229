import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { IoCheckmarkCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchAds, setBannerFee } from "redux/adSlice";
import { config } from "common/config";
import ColoredText from "components/colored-text/colored-text";
import AdApplyTableComponent from "components/ui/AdApplyTableComponent";
import { Message } from "components/ui/Message";
import { Spinner } from "components/ui/Spinner";
import StatusText from "components/ui/StatusText";
import TableComponent from "components/ui/TableComponent";
import Popup from "components/popup/popup";
import Alert from "components/alert/alert";
import Button from "components/button/button";
import { RoundedButton, SearchInput } from "components/ui/UIComponents";
import Pill from "components/ui/Pill";
import deleteIcon from "appassets/add-products/delete-icon.png";
import DeleteBannerIcon from "appassets/adApply/delete-banner-icon.png";
import api from "api/api";
import moment from "moment";
import { dateFormat } from "common/utility";

export default function AdminAdApplys() {
	const [selected, setSelected] = useState("");
	const history = useHistory();
	const location = useLocation();
	const [feePerDay, setFeePerDay] = useState();
	const dispatch = useDispatch();
	const [list, setList] = useState();
	const [deleteBannerPopup, setDeleteBannerPopup] = useState(false);
	const [selectedBanner, setSelectedBanner] = useState();
	const [alert, setAlert] = useState({
		show: false,
		message: "",
	});

	const { loading, error, bannerList, currentPage, totalPage, pageSize } = useSelector((state) => state.ad);

	const pageConfig = config.adsApply.pageConfig;

	const handleNavigate = (bannerId) => {
		history.push(`/admin/ad-applys/${bannerId}`);
	};

	useEffect(() => {
		if (selected) {
			dispatch(
				fetchAds({
					sort: "requestDate",
					filters: "status",
					values: selected,
					page: currentPage,
					size: pageSize,
				})
			);
		}

		if (!selected) {
			dispatch(
				fetchAds({
					sort: "requestDate",
					filters: "",
					values: "",
					page: currentPage,
					size: pageSize,
				})
			);
		}
	}, [selected]);

	useEffect(() => {
		let list = [];

		if (bannerList) {
			list = bannerList.map((banner) => ({
				bannerAttached: (
					<img
						className="h-12 object-contain inline"
						src={banner?.desktopBannerImage}
						alt="banner"
						responsive={true}
						fluid={true}
					/>
				),
				dateToLaunch: dateFormat(moment(banner?.bannerLaunchDate).subtract(1, "days")),
				deleteBtn: (
					<Pill
						color="text-red-500 border-2 border-red-500 py-2 w-full ml-0 cursor-pointer"
						bg="bg-transparent"
						onClick={() => handleDeleteBannerPopup(banner?.bannerId, true)}
					>
						<div className="flex flex-row">
							<img
								src={deleteIcon}
								style={{ height: "16px", marginTop: "3px" }}
								className="inline mr-4"
							/>
							<div className="inline">Delete</div>
						</div>
					</Pill>
				),
				action: (
					<button onClick={() => handleNavigate(banner?.bannerId)}>
						<BsChevronRight size="15" />
					</button>
				),
			}));

			setList(list);
		}
	}, [bannerList]);

	useEffect(() => {
		if (location?.state?.triggerAlert) {
			setAlert({
				show: true,
				message: "Banner Deleted",
			});
			history.replace();
		} else if (location?.state?.Uploaded) {
			setAlert({
				show: true,
				message: "New banner added",
			});
			history.replace();
		}
		dispatch(
			fetchAds({
				sort: "requestDate",
				filters: "",
				values: "",
				page: currentPage,
				size: pageSize,
			})
		);
	}, [location]);

	const sort = (key, order) => {
		dispatch(
			fetchAds({
				sort: key,
				page: currentPage,
				size: pageSize,
				order,
			})
		);
	};

	const handleDeleteBannerPopup = (id, isVisible) => {
		setSelectedBanner(id);
		setDeleteBannerPopup(isVisible);
	};

	const handleBannerDeletion = async () => {
		try {
			const response = await api.get.deleteBanner(selectedBanner);
			setDeleteBannerPopup(false);
			setAlert({
				show: true,
				message: "Banner Deleted",
			});
			dispatch(
				fetchAds({
					sort: "requestDate",
					filters: "",
					values: "",
					page: currentPage,
					size: pageSize,
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Spinner show={loading} />
			<div className="text-black min-h-screen p-8 table w-full">
				{error && <Message variant="danger" message={error} />}
				{list?.length > 0 ? (
					<>
						<div className="flex flex-row justify-between">
							<div className="flex items-center mb-6">
								<div style={{ color: "#666666" }}>* Maximum 6 banners allowed</div>
							</div>
							<div className="flex items-center mb-6">
								{list?.length < 6 ? (
									<RoundedButton
										label="ADD NEW BANNER"
										dark
										onClick={() => history.push(`/admin/ad-applys/new`)}
									/>
								) : (
									<RoundedButton
										label="ADD NEW BANNER"
										dark
										className="opacity-50 pointer-events-none"
									/>
								)}
							</div>
						</div>

						<AdApplyTableComponent
							headers={config.adsApply.adsHeader}
							columns={list}
							hasButton={true}
							sortFunction={sort}
						/>
					</>
				) : (
					<div className="text-center align-middle table-cell">
						<p className="font-medium text-lg mb-4" style={{ color: "#C4C4C4" }}>
							No banner added
						</p>
						<div className="flex justify-center">
							<RoundedButton
								label="Add banner now"
								gold
								onClick={() => history.push(`/admin/ad-applys/new`)}
							/>
						</div>
					</div>
				)}
			</div>
			<Popup open={deleteBannerPopup} onPopupClose={() => setDeleteBannerPopup(false)}>
				<div>
					<div className="flex justify-center mb-5">
						<img src={DeleteBannerIcon} />
					</div>
					<div>
						<p className="m-txt m-txt--m m-txt--bold mb-5">
							Are you sure you want to permanently <br /> delete this banner?
						</p>
					</div>
					<div className="flex flex-row space-x-2 justify-center mt-5">
						<Button text label="CANCEL" onClick={() => setDeleteBannerPopup(false)} />
						<Button text danger label="DELETE" onClick={() => handleBannerDeletion()} />
					</div>
				</div>
			</Popup>
			<Alert message={alert.message} show={alert.show} onClose={(show) => setAlert({ show })} />
		</>
	);
}
