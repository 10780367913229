import MobileUser from "pages/common/MobileUser";
import { NotFound } from "pages/common/NotFound";
import React, { Component } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { routes } from "routes/routes";
import LoginPage from "./pages/common/login/LoginPage";
import AdminLayout from "common/AdminLayout";
import Footer from 'components/footer/footer';

const ProtectedRoute = ({ path, isPrivate, key, ...rest }) => {
  return (
    <Route
      exact
      path={path}
      key={key}
      {...rest}
      render={(props) =>
        true && true ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

function App() {
  return (
    <div className="m-app">
      <Router>
        <AdminLayout>
          <Switch>
            {routes.map((route) => (
              <ProtectedRoute
                exact={true}
                key={route.path}
                path={`/admin${route.path}`}
                component={route.component}
                isPrivate={route.isPrivate}
              ></ProtectedRoute>
            ))}

            <Route path="/" component={LoginPage}></Route>
            <Route path="/mobileUser/" component={MobileUser}></Route>
            <Route path="/*" component={NotFound}></Route>
          </Switch>
          <Footer />
        </AdminLayout>
      </Router>
    </div>
  );
}

export default App;
