import editIcon from "appassets/categories/edit-icon.png";
import { config } from "common/config";
import { Message } from "components/ui/Message";
import ProductCategory from "components/ui/ProductCategory";
import { Spinner } from "components/ui/Spinner";
import StatusText from "components/ui/StatusText";
import TableComponent from "components/ui/TableComponent";
import ColoredText from "components/colored-text/colored-text";
import { RoundedButton, SearchInput } from "components/ui/UIComponents";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, filterCategory } from "redux/productCategorySlice";
import ModalForm from "./ModalForm";

const ProductCategoriesPage = () => {
	const [popupVisible, setPopupVisible] = useState(false);
	const [addEditForm, setAddEditForm] = useState();
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [search, setSearch] = useState("");

	const [list, setList] = useState([]);

	const dispatch = useDispatch();
	const { loading, error, categoryList, filteredCategoryList } = useSelector((state) => state.productCategory);

	useEffect(() => {
		dispatch(fetchCategories({ sort: "date", order: "desc" }));
	}, []);

	useEffect(() => {
		let array;

		if (search) {
			array = filteredCategoryList;
		} else {
			array = categoryList;
		}

		if (array.length > 0) {
			const categories = array.map((category) => ({
				...category,
				categoryName: <ProductCategory category={category} />,
				status: <ColoredText
							text={category.status ? "Active" : "InActive"}
							color={category.status ? "text-success" : "text-gray"}
						/>,
				action: (
					<button onClick={() => handleAddEditCategory("edit", category)}>
						<img src={editIcon} className="w-8 h-8" alt="edit" />
					</button>
				),
			}));
			setList(categories);
		} else {
			setList(array);
		}

	}, [categoryList, filteredCategoryList]);

	const handleAddEditCategory = (type, category) => {
		setSelectedCategory(category);

		if (type === "edit") {
			setTimeout(() => {
				setPopupVisible(true);
			}, 500);
			setAddEditForm(type);
		} else {
			setTimeout(() => {
				setPopupVisible(true);
			}, 500);
			setAddEditForm(type);
		}
	};

	const searchList = async (value, key) => {
		setSearch(value)
		dispatch(filterCategory({ value, key }));
	};

	const sort = (sort, order) => {
		dispatch(fetchCategories({ sort, order }));
	};

	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					<Spinner show={loading} />
					<div className="text-black min-h-full min-h-screen p-8">
						{error && <Message variant="danger" message={error} />}
						<div className="flex justify-between mb-8">
							<div className="w-2/4">
								<SearchInput
									placeholder="Search category"
									icon={<FiSearch size="18" />}
									onChange={(e) => searchList(e.target.value)}
								/>
							</div>
							<div>
								<RoundedButton label="Create New" dark onClick={() => handleAddEditCategory("add")} />
							</div>
						</div>

						<TableComponent
							headers={config.categoryPage.categoryHeader}
							rows={list}
							hideId={true}
							sortFunction={sort}
						/>

						<ModalForm
							popupVisible={popupVisible}
							setPopupVisible={setPopupVisible}
							addEditForm={addEditForm}
							category={selectedCategory}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductCategoriesPage;
