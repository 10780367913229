import api from "api/api";
import { formatDate, sortByKey } from "common/utility";
import moment from "moment";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
	salesRevenue: {
		totalOrders: 0,
		newCancellation: [],
		totalRevenue: 0,
		todayRevenue: 0,
		totalMerchants: [],
		orders: [],
	},
	newCancellation: [],
	newSeller: [],
	error: "",
	loading: false,
	chartData: [],
	statusCode: null,
	startDate: null,
	endDate: formatDate(moment(), "DD MMM YYYY"),
};

export const fetchSalesReport = createAsyncThunk("dashboard/fetchSalesReport", async (data) => {
	const { filters, values } = data;
	const response = await api.get.salesReport(filters, values);
	return response;
});

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: initialState,
	reducers: {
		fetchedSalesReport: (state, action) => {
			const response = action.payload.data.result;
			state.salesRevenue = response;
			state.salesRevenue.orders = response.orders || [];
			state.newCancellation = response.newCancellation || [];
			state.newSeller = response.newSeller || [];
			state.salesRevenue.totalOrders = response.salesRevenue.reduce((acc, item) => acc + item.totalOrder, 0);
		},
		fetchChartData: (state, action) => {
			state.chartData = [];
			if (state.salesRevenue.salesRevenue && state.salesRevenue.salesRevenue.length > 0) {
				let list = state.salesRevenue.salesRevenue.map((sales) => {
					if (sales.updatedAt && sales.revenue) {
						const timestamp = formatDate(sales.updatedAt, "timestamp");
						return [timestamp, sales.revenue];
					} else {
						return [0, 0];
					}
				});
				state.chartData = list.sort(function(x, y){
					return x[0] - y[0];
				})
			} else {
				state.chartData = [];
			}
		},

		setStartDate: (state, action) => {
			state.startDate = action.payload;
		},
		setEndDate: (state, action) => {
			state.endDate = action.payload;
		},
		sortMerchants: (state, action) => {
			const { key, order } = action.payload;
			state.newSeller = sortByKey(state.newSeller, key, order);
		},
		sortNewCancellations: (state, action) => {
			const { key, order } = action.payload;
			state.newCancellation = sortByKey(state.newCancellation, key, order);
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchSalesReport.pending, (state) => {
			state.error = "";
			state.loading = true;
		});
		builder.addCase(fetchSalesReport.fulfilled, (state, action) => {
			const response = action.payload;
			const { responseCode } = response.data;
			state.loading = false;

			if (responseCode === 200) {
				dashboardSlice.caseReducers.fetchedSalesReport(state, action);
				dashboardSlice.caseReducers.fetchChartData(state, action);
			} else {
				state.statusCode = responseCode;
				state.error = `${response.data.error}  ${response.data.message};`;
			}
		});
	},
});

export const { fetchedSalesReport, fetchChartData, setStartDate, setEndDate, sortMerchants, sortNewCancellations } =
	dashboardSlice.actions;
export default dashboardSlice.reducer;
