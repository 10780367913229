import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { NavigationTitle, RoundedButton, Input } from "components/ui/UIComponents";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import MyFlag from "appassets/myAccount/My-flag.png";
import { FiEye, FiEyeOff, FiEdit } from "react-icons/fi";
import { Message } from "components/ui/Message";
import Select from "react-select";
import { config } from "common/config";
import api from "api/api";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function arrayToObject(arr) {
	var obj = {};
	for (var i = 0; i < arr.length; ++i) {
		obj[i] = arr[i];
	}
	return Object.values(obj);
}

const AdminAccountSettingPage = () => {
	const { loading } = useSelector((state) => state.monthlySettlement);
	const { email, password, isAuthenticated, error, user } = useSelector((state) => state.admin);
	const [oriPassword, setOriPassword] = useState(password);
	const [value, setValue] = React.useState(0);
	const [visibility, setVisibility] = useState({
		oldPassword: ["password"],
		newPassword: ["password"],
		confirmPassword: ["password"],
	});
	const [passwordInput, setPasswordInput] = useState({
		old: [""],
		new: [""],
		confirm: [""],
	});
	const [formError, setFormError] = useState({
		old: [""],
		new: [""],
		confirm: [""],
		mobile: [""],
	});
	const [profile, setProfile] = useState();
	const [courier, setCourier] = useState();
	const [countries, setCountries] = useState();
	const [states, setStates] = useState();
	const [statesList, setStatesList] = useState([]);
	const [shippingAreaModal, setShippingAreaModal] = useState(false);
	const westMalaysia = [
		"Johor",
		"Kedah",
		"Kuala Lumpur",
		"Labuan",
		"Malacca",
		"Negeri Sembilan",
		"Pahang",
		"Penang",
		"Perak",
		"Perlis",
		"Putrajaya",
		"Selangor",
		"Terengganu",
		"Kelantan",
	];
	const eastMalaysia = ["Sabah", "Sarawak"];
	const malaysia = [
		"Johor",
		"Kedah",
		"Kuala Lumpur",
		"Labuan",
		"Malacca",
		"Negeri Sembilan",
		"Pahang",
		"Penang",
		"Perak",
		"Perlis",
		"Putrajaya",
		"Selangor",
		"Terengganu",
		"Kelantan",
		"Sabah",
		"Sarawak",
	];

	useEffect(() => {
		fetchAdminProfile();
		fetchCourier();
		fetchCountries();
		fetchStates();
	}, [user]);

	const containsAll = (needles, haystack) => needles.every(Set.prototype.has, new Set(haystack));

	const handleChange = (event, newValue) => {
		setValue(newValue);
		clearTabsData();
	};
	const handleMobileNumber = (e) => {
		e.target.value = e.target.value.replace(/[^0-9]+/g, "");
	};
	const handleShippingFee = (e) => {
		setCourier(
			courier.filter(function (c, i) {
				if (i === 0) {
					c.fee.amount = e.target.value;
				}
				return c;
			})
		);
	};
	const handleCountry = (country, e, index) => {
		if (country == "all") {
			states.map(function (item, i) {
				var id = "checkBox" + i;
				document.getElementById(id).checked = e.target.checked;
			});
			if (e.target.checked) {
				setStatesList(["West and East Malaysia"]);
			} else {
				setStatesList([]);
			}
		} else {
			const newArr = [];
			states.map(function (item, i) {
				var id = "checkBox" + i;
				if (document.getElementById(id).checked) {
					newArr.push(item.label);
				}
			});
			if (newArr.length >= 16) {
				document.getElementById("checkBoxAll").checked = true;
				setStatesList(["West and East Malaysia"]);
				newArr.splice(0, 16);
				newArr.push("West and East Malaysia");
			}
			if (newArr.some((child) => child === "Sabah") && newArr.some((child) => child === "Sarawak")) {
				var Sabah = newArr.indexOf("Sabah");
				newArr.splice(Sabah, 1);
				var Sarawak = newArr.indexOf("Sarawak");
				newArr.splice(Sarawak, 1);
				newArr.push("East Malaysia");
			}
			if (containsAll(westMalaysia, newArr)) {
				if (newArr.some((child) => child === "Sabah")) {
					newArr.splice(0, 15);
					newArr.push("West Malaysia");
					newArr.push("Sabah");
				} else if (newArr.some((child) => child === "Sarawak")) {
					newArr.splice(0, 15);
					newArr.push("West Malaysia");
					newArr.push("Sarawak");
				} else {
					newArr.splice(0, 14);
					newArr.push("West Malaysia");
				}
			}
			setStatesList([...newArr]);
		}
	};

	const createNotification = (type, message, title) => {
		switch (type) {
			case "info":
				NotificationManager.info(message);
				break;
			case "success":
				NotificationManager.success(message);
				break;
			case "warning":
				NotificationManager.warning(message);
				break;
			case "error":
				NotificationManager.error(title, message);
				break;
		}
	};

	const createNewCourier = () => {
		var newCourier = {
			country: "",
			fee: {
				amount: 0,
				currencyCode: "MYR",
			},
			states: [],
		};
		if (courier == undefined) {
			setCourier([newCourier]);
		} else {
			setCourier([...courier, newCourier]);
		}
	};

	const removeCourier = (index) => {
		setCourier(courier.filter((_, i) => i !== index));
	};

	const updateCourierState = () => {
		setCourier(
			courier.filter(function (c, i) {
				if (i === 0) {
					c.states = statesList;
				}
				console.log(c);
				return c;
			})
		);
		handleShippingAreaModal();
	};

	const updateShippingInfo = async () => {
		var x = document.getElementById("shippingFee");
		console.log(x.value);
		setCourier(
			courier.filter(function (c, i) {
				if (i === 0) {
					if (x.value) {
						c.fee.amount = x.value;
					} else {
						c.fee.amount = 0;
					}
				}
				return c;
			})
		);
		
		const payload = courier[0];
		const response = await api.post.updateCourier(payload);
		if (response.status == 200) {
			createNotification("success", "Shipping Infomation updated");
		} else {
			createNotification("error", "Shipping Infomation update failed");
		}
	};

	const changeCountry = (index, country) => {
		setCourier(
			courier.filter(function (c, i) {
				if (i === index) {
					c.country = country.label;
				}
				console.log(c);
				return c;
			})
		);
	};

	const handlePassword = (e, type) => {
		switch (type) {
			case "old":
				setPasswordInput({ ...passwordInput, old: e.target.value });
				break;
			case "new":
				setPasswordInput({ ...passwordInput, new: e.target.value });
				break;
			case "confirm":
				setPasswordInput({ ...passwordInput, confirm: e.target.value });
				break;
		}
	};
	const fetchAdminProfile = async () => {
		const response = await api.get.getAdminProfile({ id: user.adminId });
		setProfile(response.data?.result);
	};

	const fetchCourier = async () => {
		const response = await api.get.getCourier();
		setCourier(response.data?.result);
	};

	const fetchCountries = async () => {
		const response = await api.get.getCountries();
		var country = response.data?.result.map(function (item) {
			return { label: item.country, value: item.id };
		});
		setCountries(arrayToObject(country));
	};

	const fetchStates = async () => {
		const response = await api.get.getStates();
		var state = response.data?.result.map(function (item) {
			return { label: item.state, value: item.id };
		});
		setStates(arrayToObject(state));
	};

	const handleVisibility = (id) => {
		var x = document.getElementById(id);
		if (x.type === "password") {
			x.type = "text";
		} else {
			x.type = "password";
		}
		switch (id) {
			case "oldPassword":
				setVisibility({ ...visibility, oldPassword: x.type });
				break;
			case "newPassword":
				setVisibility({ ...visibility, newPassword: x.type });
				break;
			case "confirmPassword":
				setVisibility({ ...visibility, confirmPassword: x.type });
				break;
		}
	};
	const handleShippingAreaModal = () => {
		setShippingAreaModal(!shippingAreaModal);
	};

	const openShippingAreaModal = () => {
		setShippingAreaModal(true);
		setStatesList(courier[0].states);
	};

	const clearTabsData = () => {
		setFormError({ old: "", new: "", confirm: "", mobile: "" });
		setVisibility({ ...visibility, oldPassword: "password", newPassword: "password", confirmPassword: "password" });
	};

	const updateInfo = async () => {
		var mobileNum = document.getElementById("mobile").value;
		if (mobileNum.length < 9) {
			setFormError({ ...formError, mobile: "Please enter correct mobile number" });
			return;
		}
		setFormError({ ...formError, mobile: "" });
		mobileNum = 0 + mobileNum;
		const payload = { id: user.adminId, mobileNumber: mobileNum };
		const response = await api.post.updateAdminProfile(payload);
		if (response.status == 200) {
			createNotification("success", "Account Infomation updated");
		} else {
			createNotification("error", "Account Infomation update failed");
		}
	};

	const resetPassword = async () => {
		var oldPassword = document.getElementById("oldPassword").value;
		var newPassword = document.getElementById("newPassword").value;
		var confirmPassword = document.getElementById("confirmPassword").value;
		// if (oriPassword != oldPassword) {
		// 	setFormError({ ...formError, old: "Please enter correct old password" });
		// 	return;
		// }
		if (newPassword.length < 6) {
			setFormError({ ...formError, new: "New Password must have more than 6 characters", old: "" });
			return;
		}
		if (newPassword != confirmPassword) {
			setFormError({ ...formError, confirm: "Confirm Password must be same as new password", old: "", new: "" });
			return;
		} else {
			setFormError({ old: "", new: "", confirm: "" });
		}
		const payload = { userId: user.adminId, originalPassword: oldPassword, password: newPassword, platform: 0 };

		const response = await api.post.resetPassword(payload);
		if (response.status == 200) {
			createNotification("success", "Password updated");
			setOriPassword(newPassword);
		} else {
			createNotification("error", "Password update failed", response.data.message);
		}
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: 10,
			width: "50%",
			minWidth: 400,
		},
	};
	return (
		<>
			<div className="text-black min-h-screen p-8">
				<NotificationContainer />
				<NavigationTitle prevPage="My account" toPrevPage="/admin/dashboard" currentPage="Account settings" />
				<AppBar
					position="static"
					style={{ background: "none", boxShadow: "none", color: "black", marginTop: 20 }}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="simple tabs example"
						TabIndicatorProps={{ style: { background: "#000000" } }}
					>
						<Tab
							disableRipple
							label="Account Information"
							{...a11yProps(0)}
							style={{ boxShadow: "none" }}
						/>
						<Tab disableRipple label="Shipping" {...a11yProps(1)} style={{ boxShadow: "none" }} />
						<Tab disableRipple label="Reset Password" {...a11yProps(2)} style={{ boxShadow: "none" }} />
					</Tabs>
				</AppBar>
				{/* General Infomation */}
				<TabPanel value={value} index={0} className="bg-white rounded-md p-6 mt-6">
					<h1 style={{ fontSize: "large" }}>
						<b>General Information</b>
					</h1>
					<h2 style={{ marginTop: 20 }}>Role</h2>
					<Input placeholder="Role" type="text" style={{ maxWidth: "50%" }} value="Admin" readonly />
					<h2 style={{ marginTop: 20 }}>Email Address</h2>
					<Input
						placeholder="Email Address"
						type="email"
						style={{ maxWidth: "50%" }}
						value={email}
						readonly
					/>
					<h2 style={{ marginTop: 20 }}>Mobile Number</h2>

					<div class="inputpad-group prefix mt-2">
						<span class="inputpad-group-addon">
							<img
								src={MyFlag}
								style={{ display: "inline-block", width: "25%", margin: "0 10px 2px 0" }}
							></img>{" "}
							+60 <h1 style={{ color: "lightgray", display: "inline", marginLeft: 10 }}> | </h1>{" "}
						</span>
						{profile?.mobileNumber ? (
							<input
								maxLength={11}
								type="text"
								id="mobile"
								pattern="[0-9]*"
								defaultValue={profile.mobileNumber.substring(1)}
								onChange={(e) => handleMobileNumber(e)}
								placeholder="Mobile Number"
								className="bg-white
						   py-4 inputpad
						  justify-between items-center"
								style={{
									border: "1px solid lightgrey",
									borderLeft: "none",
									borderRadius: "0 0.25rem 0.25rem 0",
									paddingLeft: 0,
								}}
							/>
						) : (
							<input
								maxLength={11}
								type="text"
								id="mobile"
								pattern="[0-9]*"
								onChange={(e) => handleMobileNumber(e)}
								placeholder="Mobile Number"
								className="bg-white
						   py-4 inputpad
						  justify-between items-center"
								style={{
									border: "1px solid lightgrey",
									borderLeft: "none",
									borderRadius: "0 0.25rem 0.25rem 0",
									paddingLeft: 0,
								}}
							/>
						)}
					</div>

					{formError.mobile != "" && <Message variant="danger" message={formError.mobile} />}

					<div class="mt-10 w-8">
						<RoundedButton label="UPDATE" dark onClick={() => updateInfo()} class="w-min" />
					</div>
				</TabPanel>

				{/* Shipping */}
				<TabPanel
					value={value}
					index={1}
					className="bg-white p-6 mt-6"
					style={{
						borderRadius: "0.375rem 0.375rem 0 0",
						borderBottom: "1px solid lightgrey",
					}}
				>
					<h1 style={{ fontSize: "large" }}>
						<b>Shipping options for sellers</b>
					</h1>
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
					className="bg-white p-6"
					style={{
						borderRadius: "0 0 0.375rem 0.375rem",
					}}
				>
					<div style={{ display: "inline-block", width: "70%" }}>
						<h1 style={{ display: "inline-block" }}>
							<b>Own Shipping Team</b>
						</h1>
						<h2 style={{ marginTop: 20 }}>Name</h2>
						<div class="inputpad-group suffix mt-2">
							<input
								type="text"
								id="ShippingName"
								placeholder="Name"
								readonly
								spellcheck="false"
								className="bg-white
              				 py-4 inputpad rounded
              				justify-between items-center"
								style={{
									border: "1px solid lightgrey",
									width: "100%",
								}}
								value="E-commerce delivery"
							/>
						</div>
						<div style={{ display: "inline-block", width: "50%", marginRight: 25 }}>
							<h2 style={{ marginTop: 20 }}>Shipping Fee type</h2>
							<div class="inputpad-group suffix mt-2 w-full">
								<Select
									id="shippingFeeType"
									name="shippingFeeType"
									defaultValue={config.account.shippingFeeType[0]}
									options={config.account.shippingFeeType}
									isDisabled={true}
								></Select>
							</div>
						</div>
						<div style={{ display: "inline-block", width: "45%" }}>
							<h2 style={{ marginTop: 20 }}>Shipping Fee</h2>
							<div class="inputpad-group prefix mt-2" style={{ width: "100%" }}>
								<span class="inputpad-group-addon">
									RM <h1 style={{ color: "lightgray", display: "inline", marginLeft: 10 }}> | </h1>{" "}
								</span>
								<input
									type="number"
									id="shippingFee"
									onChange={(e) => handleShippingFee(e)}
									defaultValue={courier ? courier[0]?.fee?.amount : 0.0}
									placeholder="Shipping Fee"
									className="bg-white
              				 py-4 inputpad
              				justify-between items-center"
									style={{
										border: "1px solid lightgrey",
										borderLeft: "none",
										borderRadius: "0 0.25rem 0.25rem 0",
										paddingLeft: 0,
									}}
								/>
							</div>
						</div>

						<div class="rounded mt-10 py-6 px-8" style={{ backgroundColor: "#E6E6E6" }}>
							{courier && courier.length > 0
								? courier.map((item, index) => {
										if (index === 0) {
											return (
												<div>
													<div style={{ display: "inline-block", width: "40%" }}>
														<h2 style={{ marginTop: 20 }}>Shipping Country</h2>
														<div class="inputpad-group suffix mt-2 w-full">
															<Select
																id="shippingCountry"
																name="shippingCountry"
																options={countries}
																defaultValue={{ label: item.country, value: 0 }}
																onChange={(item) => {
																	changeCountry(index, item);
																}}
															></Select>
														</div>
													</div>
													<div
														style={{
															display: "inline-block",
															width: "45%",
															marginLeft: "10px",
														}}
													>
														<h2 style={{ marginTop: 20 }}>Shipping area</h2>
														<div class="inputpad-group suffix mt-2 w-full">
															<input
																type="text"
																id={"ShippingArea" + index}
																placeholder="Edit Shipping Area"
																value={
																	item.country == "Malaysia"
																		? item.states
																		: "Not Applicable"
																}
																autoComplete="off"
																className="bg-white
              				 py-4 inputpad
              				justify-between items-center"
																style={{
																	border: "1px solid lightgrey",
																	borderRight: "none",
																	borderRadius: "0.25rem 0 0 0.25rem",
																}}
															/>
															{item.country == "Malaysia" ? (
																<span
																	class="inputpad-group-addon"
																	style={{
																		padding: "6px 0 4px 0",
																		width: "10%",
																		backgroundColor: "#ffffff",
																	}}
																>
																	<FiEdit
																		onClick={() => openShippingAreaModal()}
																		class="mx-4"
																		size={20}
																		style={{ float: "right", cursor: "pointer" }}
																	/>
																</span>
															) : null}
														</div>
													</div>
													<div
														style={{
															display: "inline-block",
															verticalAlign: "bottom",
															marginBottom: "10px",
															marginLeft: "15px",
														}}
													>
														<div
															onClick={() => {
																createNewCourier();
															}}
															class="circle-btn"
															style={{ pointerEvents: "none", opacity: 0.3 }}
														>
															+
														</div>
													</div>
												</div>
											);
										}
										if (index > 0) {
											return (
												<div>
													<div style={{ display: "inline-block", width: "40%" }}>
														<h2 style={{ marginTop: 20 }}>Shipping Country</h2>
														<div class="inputpad-group suffix mt-2 w-full">
															<Select
																id="shippingCountry"
																name="shippingCountry"
																options={countries}
																value={
																	item.country
																		? { label: item.country, value: 0 }
																		: null
																}
																onChange={(item) => {
																	changeCountry(index, item);
																}}
															></Select>
														</div>
													</div>
													<div
														style={{
															display: "inline-block",
															width: "45%",
															marginLeft: "10px",
														}}
													>
														<h2 style={{ marginTop: 20 }}>Shipping area</h2>
														<div class="inputpad-group suffix mt-2 w-full">
															<input
																type="text"
																id={"ShippingArea" + index}
																placeholder="Edit Shipping Area"
																value={
																	item.country == "Malaysia"
																		? item.states
																		: "Not Applicable"
																}
																autoComplete="off"
																className="bg-white
              				 py-4 inputpad
              				justify-between items-center"
																style={{
																	border: "1px solid lightgrey",
																	borderRight: "none",
																	borderRadius: "0.25rem 0 0 0.25rem",
																}}
															/>
															{item.country == "Malaysia" ? (
																<span
																	class="inputpad-group-addon"
																	style={{
																		padding: "6px 0 4px 0",
																		width: "10%",
																		backgroundColor: "#ffffff",
																	}}
																>
																	<FiEdit
																		onClick={() => handleShippingAreaModal()}
																		class="mx-4"
																		size={20}
																		style={{ float: "right", cursor: "pointer" }}
																	/>
																</span>
															) : null}
														</div>
													</div>
													<div
														style={{
															display: "inline-block",
															verticalAlign: "bottom",
															marginBottom: "10px",
															marginLeft: "15px",
														}}
													>
														<div
															onClick={() => {
																removeCourier(index);
															}}
															class="circle-btn"
														>
															-
														</div>
													</div>
												</div>
											);
										}
								  })
								: createNewCourier()}
						</div>
					</div>
					<div style={{ display: "inline-block", width: "30%", verticalAlign: "top", float: "right" }}>
						<div style={{ display: "inline-block", float: "right", fontSize: "small" }}>
							<h1 style={{ display: "inline-block", marginRight: 10 }}>
								<b>Enable Shipping Option</b>
							</h1>
							<label class="switch" style={{ display: "inline-block" }}>
								<input type="checkbox" checked={true} />
								<span class="slider round"></span>
							</label>
						</div>
						<div style={{ paddingTop: "30px", width: "50%", float: "right" }}>
							<RoundedButton label="UPDATE" dark onClick={() => updateShippingInfo()} class="w-min" />
						</div>
					</div>
				</TabPanel>
				<Modal
					isOpen={shippingAreaModal}
					onRequestClose={() => setShippingAreaModal(false)}
					style={customStyles}
				>
					<div className="pl-4 pb-4 m-4">
						<div className="flex justify-end">
							<button onClick={() => setShippingAreaModal(false)}>
								<MdClose size="24" color="gray" />
							</button>
						</div>
						<div className="flex justify-start">
							<h1 style={{ fontSize: "24px" }}>
								<b>Shipping Country</b>
							</h1>
							<div style={{ width: "30%", marginLeft: "20px" }}>
								<Select
									options={countries}
									isDisabled={true}
									value={{ label: "Malaysia", value: 0 }}
								></Select>
							</div>
						</div>
						<hr className="my-8" />
						<div style={{ width: "fit-content", display: "inline-block" }}>
							<h1 style={{ fontSize: "18px" }}>
								<b>Shipping area</b>
							</h1>
						</div>

						<div style={{ width: "fit-content", display: "inline-block", float: "right" }}>
							<h2 style={{ display: "inline-block", marginRight: "15px" }}>Select all</h2>
							<div class="round-chk-box" style={{ display: "inline-block", verticalAlign: "text-top" }}>
								<input
									type="checkbox"
									id="checkBoxAll"
									onClick={(e) => {
										handleCountry("all", e);
									}}
								/>
								<label for="checkBoxAll"></label>
							</div>
						</div>
						{statesList ? (
							<div style={{ marginTop: "10px" }}>
								<div>
									{statesList.map((stateChild, index) => {
										if (statesList.length - 1 == index) {
											return <h1 style={{ display: "inline-block" }}>{stateChild}</h1>;
										} else {
											return <h1 style={{ display: "inline-block" }}>{stateChild}, </h1>;
										}
									})}
								</div>
							</div>
						) : null}
						<div className="p-4 m-4">
							{states ? (
								states.map((item, index) => {
									return (
										<div style={{ display: "inline-block", width: "30%", marginBottom: "30px" }}>
											<div
												class="round-chk-box"
												style={{ display: "inline-block", verticalAlign: "text-top" }}
											>
												<input
													type="checkbox"
													id={"checkBox" + index}
													onClick={(e) => {
														handleCountry(item.label, e, index);
													}}
													defaultChecked={
														courier[0].states.some((cou) => cou === item.label) ||
														(courier[0].states == "West Malaysia" &&
															westMalaysia.some((child) => child === item.label)) ||
														(courier[0].states == "East Malaysia" &&
															eastMalaysia.some((child) => child === item.label)) ||
														(courier[0].states == "West and East Malaysia" &&
															malaysia.some((child) => child === item.label))
															? true
															: false
													}
												/>
												<label for={"checkBox" + index}></label>
											</div>
											<div
												style={{
													display: "inline-block",
													verticalAlign: "text-top",
													marginLeft: "15%",
												}}
											>
												{item.label}
											</div>
										</div>
									);
								})
							) : (
								<div>
									<h1>None</h1>
								</div>
							)}
						</div>
						<div>
							<button
								style={{ width: "50%", color: "#BBBBBB" }}
								onClick={() => setShippingAreaModal(false)}
							>
								CANCEL
							</button>
							<button style={{ width: "50%", color: "#C19E66" }} onClick={() => updateCourierState()}>
								SAVE
							</button>
						</div>
					</div>
				</Modal>
				{/* Reset Password*/}
				<TabPanel value={value} index={2} className="bg-white rounded-md p-6 mt-6">
					<h1 style={{ fontSize: "large" }}>
						<b>Reset Password</b>
					</h1>
					{/* old Password */}
					<h2 style={{ marginTop: 20 }}>Old Password</h2>
					<div class="inputpad-group suffix mt-2">
						<input
							type="password"
							id="oldPassword"
							placeholder="Enter your old password"
							onChange={(e) => handlePassword(e, "old")}
							autoComplete="off"
							className="bg-white
              				 py-4 inputpad
              				justify-between items-center"
							style={{
								border: "1px solid lightgrey",
								borderRight: "none",
								borderRadius: "0.25rem 0 0 0.25rem",
							}}
						/>
						<span class="inputpad-group-addon">
							{visibility.oldPassword == "password" ? (
								<FiEyeOff
									onClick={() => handleVisibility("oldPassword")}
									class="mx-8"
									size={20}
									style={{ float: "right", cursor: "pointer" }}
								/>
							) : (
								<FiEye
									onClick={() => handleVisibility("oldPassword")}
									class="mx-8"
									size={20}
									style={{ float: "right", cursor: "pointer" }}
								/>
							)}
						</span>
					</div>

					{formError.old != "" && <Message variant="danger" message={formError.old} />}

					{/* new password */}
					<h2 style={{ marginTop: 20 }}>New Password</h2>
					<div class="inputpad-group suffix mt-2">
						<input
							type="password"
							id="newPassword"
							placeholder="Enter new password"
							onChange={(e) => handlePassword(e, "new")}
							autoComplete="off"
							className="bg-white
              				 py-4 inputpad
              				justify-between items-center"
							style={{
								border: "1px solid lightgrey",
								borderRight: "none",
								borderRadius: "0.25rem 0 0 0.25rem",
							}}
						/>
						<span class="inputpad-group-addon">
							{visibility.newPassword == "password" ? (
								<FiEyeOff
									onClick={() => handleVisibility("newPassword")}
									class="mx-8"
									size={20}
									style={{ float: "right", cursor: "pointer" }}
								/>
							) : (
								<FiEye
									onClick={() => handleVisibility("newPassword")}
									class="mx-8"
									size={20}
									style={{ float: "right", cursor: "pointer" }}
								/>
							)}
						</span>
					</div>

					{formError.new != "" && <Message variant="danger" message={formError.new} />}

					{/*confirm new password */}
					<h2 style={{ marginTop: 20 }}>Confirm New Password</h2>
					<div class="inputpad-group suffix mt-2">
						<input
							type="password"
							id="confirmPassword"
							placeholder="Enter again the new password"
							onChange={(e) => handlePassword(e, "confirm")}
							autoComplete="off"
							className="bg-white
              				 py-4 inputpad
              				justify-between items-center"
							style={{
								border: "1px solid lightgrey",
								borderRight: "none",
								borderRadius: "0.25rem 0 0 0.25rem",
							}}
						/>
						<span class="inputpad-group-addon">
							{visibility.confirmPassword == "password" ? (
								<FiEyeOff
									onClick={() => handleVisibility("confirmPassword")}
									class="mx-8"
									size={20}
									style={{ float: "right", cursor: "pointer" }}
								/>
							) : (
								<FiEye
									onClick={() => handleVisibility("confirmPassword")}
									class="mx-8"
									size={20}
									style={{ float: "right", cursor: "pointer" }}
								/>
							)}
						</span>
					</div>

					{formError.confirm != "" && <Message variant="danger" message={formError.confirm} />}

					<div class="mt-10 w-56">
						{passwordInput.old != "" && passwordInput.new != "" && passwordInput.confirm != "" ? (
							<RoundedButton label="RESET PASSWORD" dark onClick={() => resetPassword()} class="w-min" />
						) : (
							<RoundedButton
								label="RESET PASSWORD"
								dark
								disabled
								onClick={() => resetPassword()}
								class="w-min"
							/>
						)}
					</div>
				</TabPanel>
			</div>
		</>
	);
};

export default AdminAccountSettingPage;
