import React from 'react';

const Button = ({ outline, gold, danger, success, text, small, ...props }) => {
    const className = ['button'];
    const textS = 'button--txt';
    const outlineS = 'button--outline';
    const goldS = 'button--gold';
    const smallS = 'button--small';
    const textGoldS = 'button--txt-gold';
    const textDangerS = 'button--txt-danger';
    const dangerS = 'button--danger';
    const successS = 'button--success';

    if (text) className.push(textS);
    if (text && gold) className.push(textGoldS);
    if (text && danger) className.push(textDangerS);
    if (outline) className.push(outlineS);
    if (gold) className.push(goldS);
    if (danger) className.push(dangerS);
    if (success) className.push(successS);
    if (small) className.push(smallS);

    return (
        <button className={className.join(' ')} {...props} onClick={props.onClick}>
            <p className="button__txt">{props.label}</p>
        </button>
    );
};

export default Button;
