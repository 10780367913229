import { config } from "common/config";
import { Message } from "components/ui/Message";
import Pagination from "components/pagination/pagination";
import { Spinner } from "components/ui/Spinner";
import StatusText from "components/ui/StatusText";
import TableComponent from "components/ui/TableComponent";
import { SearchInput } from "components/ui/UIComponents";
import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchStreams, filterStreams, filterUpgradeRequest, setCurrentPage } from "redux/streamSlice";

export default function AdminStreamsPage() {
	const [selected, setSelected] = useState(null);
	const history = useHistory();
	const [list, setList] = useState([]);
	const [search, setSearch] = useState("");

	const dispatch = useDispatch();

	const { loading, error, streams, totalPage, filteredStreams, filter, currentPage } = useSelector(
		(state) => state.streams
	);

	const pageConfig = config.streamsPage.pageConfig;

	const handleNavigate = (streamId) => {
		history.push(`/admin/streams/${streamId}`);
	};

	useEffect(() => {
		dispatch(fetchStreams({ 
			currentPage, 
			sort: filter,
			search
		}));
	}, []);

	useEffect(() => {
		let array = [];

		if (filteredStreams?.length > 0) {
			array = filteredStreams;
		} else {
			array = streams;
		}

		let rows = array.map((stream) => ({
			streamId: stream.sellerId,
			storeName: stream.storeName,
			// liveStreamCount: stream.liveStreamCount,
			streamStatus: 'Upcoming ('+stream.liveStreamsCount.upcoming+'), Past ('+stream.liveStreamsCount.past+')',
			preStatus: 'Scheduled ('+stream.preRecordVideoCount.scheduled+'), Posted ('+stream.preRecordVideoCount.posted+')',
			// storage: stream.storage,
			// upgradeRequest: (
			// 	<StatusText
			// 		status={stream.upgradeRequest ? "success" : "danger"}
			// 		text={stream.upgradeRequest ? "Yes" : "No"}
			// 	/>
			// ),
			action: (
				<button onClick={() => handleNavigate(stream.sellerId)}>
					<BsChevronRight size="15" />
				</button>
			),
		}));

		setList(rows);
	}, [streams, filteredStreams]);

	const handlePagination = (value) => {
		const currentIndex = Math.max(0, Math.min(currentPage + value, totalPage - 1));
		dispatch(setCurrentPage(currentIndex));
		dispatch(fetchStreams({ currentPage: currentIndex, sort: filter, search }));
	};

	const handleSpecificPagination = (value) => {
		dispatch(setCurrentPage(value));

		dispatch(fetchStreams({ currentPage: value, sort: filter, search }));
	};

	const searchByUpgradeRequest = async (boolean) => {
		setSelected(boolean);
		dispatch(filterUpgradeRequest(boolean));
	};

	useEffect(() => {
		if (selected === pageConfig.filters[2]) {
			searchByUpgradeRequest(selected);
		} else {
			dispatch(filterStreams(selected));
		}
	}, [selected]);

	const searchList = async (value) => {
		setSearch(value);

		dispatch(fetchStreams({ currentPage, search: value }));
	};

	const sort = (key, order) => {
		dispatch(fetchStreams({ currentPage, sort: key, order: order, search }));
	};
	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					{<Spinner show={loading} />}
					<div className="text-black min-h-full min-h-screen p-8">
						{error && <Message variant="danger" message={error} />}
						<div className="w-2/4">
							<SearchInput
								placeholder="Search Seller ID, Store Name"
								icon={<FiSearch size="18" />}
								value={search}
								onChange={(e) => searchList(e.target.value)}
							/>
						</div>
						<div className="flex flex-row my-6 divide-x-2 space-x-4 divide-gray-500">
							{/* {pageConfig.filters.map((filter, index) => (
								<div
									key={filter.value}
									className={`px-2 cursor-pointer ${
										selected === filter.value ? "text-gray-800 font-semibold" : "m-txt--lightGrey"
									}`}
									onClick={() => setSelected(filter.value)}
								>
									{filter.label}
								</div>
							))} */}
						</div>

						<TableComponent
							headers={config.streamsPage.streamHeader}
							rows={list}
							sortFunction={sort}
							hasButton={true}
						/>

						<div className="flex flex-row items-center justify-end my-2">
							<button onClick={() => handlePagination(-1)}>
								<AiFillCaretLeft size="16" color={currentPage == 0 ? "#D2D2D2" : "#000000"}/>
							</button>

							<Pagination totalPages={totalPage} currentPage={currentPage + 1} onPageClick={handleSpecificPagination}/>

							<button onClick={() => handlePagination(+1)}>
								<AiFillCaretRight size="16" color={currentPage == totalPage - 1  ? "#D2D2D2" : "#000000"}/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
