import axios from "axios";

export const axiosService = {
	get: async (url, params = {}, config = {}) => {
		process.env.NODE_ENV === "development" && console.log("AXIOS", url);
		return axios.get(url, { params, ...(await headers()), ...config }).catch((error) => error.response);
	},
	post: async (url, body = {}, type) => {
		return await axios.post(url, body, await headers(type)).catch((error) => error.response);
	},
	patch: async (url, body = {}) => {
		return axios.patch(url, body, await headers());
	},
	delete: async (url, params = {}) => {
		return axios.delete(url, { params, ...(await headers()) });
	},
};

const headers = async (type) => {
	let headers = type !== null ? type : { "Content-Type": "application/json" };

	try {
		const userInfo = JSON.parse(localStorage.getItem("userInfo"));

		const { jsonWebToken } = userInfo;

		if (jsonWebToken) {
			headers = Object.assign({}, headers, {
				Authorization: "Bearer " + jsonWebToken,
			});
		}
	} catch (error) {
		return error;
	}

	return { headers };
};
