export const config = {
	orders: {
		sortBy: [
			{ label: "SortBy", value: "default" },
			{ label: "Paid", value: 5 },
			{ label: "Packing", value: 1 },
			{ label: "Ready to ship", value: 2 },
			{ label: "Latest", value: "asc" },
			{ label: "Oldest", value: "desc" },
		],
		historySortBy: [
			{ label: "Delivered", value: 3 },
			{ label: "Cancelled", value: 4 },
			{ label: "Latest", value: "asc" },
			{ label: "Oldest", value: "desc" },
		],
		reasonToCancel: [
			{ label: "Product out of stock.", value: 1 },
			{ label: "Out of delivery area.", value: 2 },
			{ label: "Others", value: 3 },
		],
	},
	products: {
		sortBy: [
			{ label: "SortBy", value: "default" },
			{ label: "Out of stock", value: "noStock" },
			{ label: "Low in stock", value: "lowStock" },
			{ label: "Inactive", value: "inactive" },
			{ label: "Active", value: "active" },
			{ label: "Added time (New - old)", value: "old-new" },
			{ label: "Added time (Old - new)", value: "new-old" },
		],
		reviewsSortBy: [
			{ label: "SortBy", value: "default" },
			{ label: "Highest rating", value: "desc" },
			{ label: "Lowest rating", value: "asc" },
			{ label: "New review", value: "default" },
		],
		fake: [
			{ name: "Trial product 1", sku: "1690817654" },
			{ name: "Trial product 2", sku: "6410769645" },
			{ name: "Trial product 3", sku: "2087089656" },
			{ name: "Trial product 4", sku: "2440346435" },
			{ name: "Trial product 5", sku: "7718960222" },
			{ name: "Trial product 6", sku: "7711238960" },
		],
	},
	liveStream: {
		storageLimit: [
			{ label: "1 GB (Default)", value: 1 },
			{ label: "100 GB", value: 2 },
			{ label: "500 GB", value: 3 },
			{ label: "1 TB", value: 4 },
		],
	},
	adsApply: {
		pageConfig: {
			filters: [
				{ label: "All", value: "" },
				{ label: "Approved", value: 1 },
				{ label: "Rejected", value: 2 },
				{ label: "Pending", value: 0 },
			],
		},
		adsHeader: [
			{ label: "Banner attached" },
			{ label: "Uploaded date", key: "bannerLaunchDate", hasSorting: true },
			{ label: "Action" },
			{ label: "" },
		],
		adsApproval: [
			{ label: "Pending", value: 0 },
			{ label: "Approved", value: 1 },
			{ label: "Rejected", value: 2 },
		],
		approval: [
			{ label: "In-complete source", value: 0 },
			{ label: "Invalid source", value: 1 },
			{ label: "Exceed file size limit", value: 2 },
			{ label: "Un-available ad posting date", value: 3 },
		],
	},
	announcementPage: {
		announcementHeader: [
			{ label: "Announcement", key: "content" },
			{ label: "Recipient", key: "recipient", hasSorting: true },
			{ label: "Sent date", key: "date", hasSorting: true },
		],
		sentTo: [
			{ label: "Sellers", value: 0 },
			{ label: "Customers", value: 1 },
		],
		pageConfig: {
			filters: ["All", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		},
	},
	merchantStatus: [
		{ label: "SUBMITTED", value: "submitted", color: "warning" },
		{ label: "PENDING", value: "pendingVerification", color: "warning" },
		{ label: "ACTIVE", value: "active", color: "success" },
		{ label: "REJECTED", value: "rejected", color: "danger" },
		{ label: "INACTIVE", value: "deactivated", color: "inactive" },
	],
	routes: {
		merchants: "/admin/merchants",
		merchantDetails: "/admin/merchants/merchant/id",
		orders: "/admin/orders",
	},
	account:{
		shippingFeeType:[
			{ label: "Flat Fee", value: 0}
		]
	},
	accountType: [
		{ label: "Corporate", value: 0 },
		{ label: "Individual", value: 1 },
	],
	dashboardPage: {
		sellersHeader: [
			{ label: "ID" },
			{ label: "Store / Company Name" },
			{ label: "Type" },
			{ label: "No. of products" },
			// { label: "Last Active" },
			{ label: "" },
		],
		cancellationHeader: [
			{ label: "Cancel By" },
			{ label: "Store / Company Name" },
			{ label: "No. Cancelled Items" },
			{ label: "Price (RM)" },
			{ label: "Status" },
			{ label: "" },
		],
		pageConfig: {
			timePeriod: [
				{ label: "1 W", type: "week" },
				{ label: "1 M", type: "month" },
				{ label: "3 M", type: "3month" },
				{ label: "1 Y", type: "year" },
				{ label: "All", type: "all" },
			],
			summary: [
				{ label: "Total of Revenue (RM)", value: 0 },
				{ label: "Today Revenue (RM)", value: 0 },
				{ label: "Merchants", value: 0 },
			],
		},
	},
	merchantsPage: {
		sellerHeader: [
			{ label: "Seller ID", key: "sellerId" },
			{ label: "Store Name", key: "storeName", hasSorting: true },
			{ label: "Type", key: "type", hasSorting: true },
			{ label: "No. of products", key: "numberofproducts", hasSorting: true },
			{ label: "Join date", key: "date", hasSorting: true },
			{ label: "" },
		],
		pageConfig: {
			filters: [
				{ label: "All", value: "" },
				{ label: "Corporate account", value: "corporate" },
				{ label: "Individual account", value: "individual" },
				{ label: "Pending Verification", value: "pendingVerification" },
			],
		},
	},
	ordersPage: {
		ordersHeader: [
			{ label: "Order ID", key: "id", hasSorting: false },
			{ label: "Store Name", key: "storeName", hasSorting: true },
			{ label: "Amount (RM)", key: "price", hasSorting: true },
			{ label: "Date", key: "date", hasSorting: true },
			{ label: "Status", key: "status", hasSorting: true },
			{ label: "" },
		],
		pageConfig: {
			filters: [
				{ label: "All", value: "", showFilter: true },
				{ label: "Delivered", value: "delivered", showFilter: true },
				{ label: "Cancelled", value: "cancelled", showFilter: true },
				{ label: "Cancelled By Customer", value: "cancelledByCustomer" },
				{ label: "To Pack", value: "toPack" },
				{ label: "Packing", value: "packing" },
				{ label: "Pending Payment", value: "pendingPayment" },
				{ label: "Shipped", value: "shipped" },
				{ label: "Refunded", value: "refunded" },
			],
		},
	},
	streamsPage: {
		pageConfig: {
			filters: [
				{ label: "All", value: null },
				{ label: "Reached storage limit", value: "false" },
				{ label: "Upgrade request", value: "true" },
			],
		},
		streamHeader: [
			{ label: "Seller ID", key: "sellerId" },
			{ label: "Store Name", key: "storeName", hasSorting: true },
			// { label: "Scheduled", key: "livesStreamCount", hasSorting: true },
			{ label: "Live stream", key: "streamStatus", hasSorting: true },
			{ label: "Pre-recorded videos", key: "preStatus", hasSorting: true },
			// { label: "Storage", key: "storage", hasSorting: true },
			// { label: "Upgrade request", key: "upgradeRequest", hasSorting: true },
			{ label: "" },
		],
	},
	streamDetailsPage: {
		detailHeader: [
			{ label: "ID" },
			{ label: "Live stream Title", key: "title", hasSorting: true },
			{ label: "Scheduled Date", key: "date", hasSorting: true },
			{ label: "Status", key: "status", hasSorting: true },
			{ label: "" },
		],
		preRecordDetailHeader: [
			{ label: "ID" },
			{ label: "Title", key: "title", hasSorting: true },
			{ label: "Date", key: "date", hasSorting: true },
			{ label: "Status", key: "status", hasSorting: true },
			{ label: "" },
		],
	},
	streamDetailsPageProduct: {
		detailHeader: [
			{ label: "Product Name", key: "productName" },
			{ label: "Category", key: "category"},
			{ label: "Product SKU", key: "sku"},
			{ label: "Stock", key: "stock"},
			{ label: "Price (RM)", key: "price"}
		],
	},
	monthlyStatementPage: {
		pageConfig: {
			filters: ["All", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		},
		statementHeader: [
			{ label: "Seller ID", key: "sellerId", hasSorting: true },
			{ label: "Store Name", key: "storeName", hasSorting: true },
			{ label: "Date", key: "date", hasSorting: true },
			{ label: "Total of sales (RM)", key: "totalSales", hasSorting: true },
			{ label: "Service Fee (RM)", key: "serviceFee", hasSorting: true },
			{ label: "" },
		],
	},
	monthlySettlementDetailsPage: {
		settlementHeader: [
			{ label: "Order ID" },
			{ label: "No. Items", key: "items", hasSorting: true },
			{ label: "Date", key: "date", hasSorting: true },
			{ label: "Total (RM)", key: "amount", hasSorting: true },
			{ label: "" },
		],
		settlementHeaderPdf: [
			{ label: "Order ID" },
			{ label: "No. Items", key: "items" },
			{ label: "Date", key: "date" },
			{ label: "Total (RM)", key: "amount" },
		],
	},
	categoryPage: {
		categoryHeader: [
			{ label: "Category", key: "name", hasSorting: true },
			{ label: "Date added", key: "createdat", hasSorting: true },
			{ label: "Status", key: "status", hasSorting: true },
			{ label: "Action" },
		],
	},
};
