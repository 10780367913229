import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Dropdown from "react-dropdown";

export const FormikField = ({ outline = "", disabled = " ", ...fieldProps }) => {
	const className = `m-touch-input
    mt-2 flex w-full px-5 py-4 rounded
    focus:outline-none
    ${disabled ? "text-opacity-50" : ""}`;

	return <Field {...fieldProps} className={className} />;
};

FormikField.defaultProps = {
	outline: false,
	disabled: false,
	type: "text",
};

FormikField.propTypes = {
	outline: PropTypes.bool,
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

export const FormikErrorMessage = (props) => <ErrorMessage {...props} />;

FormikErrorMessage.defaultProps = {
	component: "p",
	className: "text-sm text-red-600",
};

FormikErrorMessage.propTypes = {
	name: PropTypes.string.isRequired,
};

export const FormDropdown = ({ placeholder, outline = false, options, value, onChange, disabled = false }) => (
	<Dropdown
		controlClassName={`
      cursor-pointer
      mt-2 flex w-full px-5 py-4 rounded
      focus:outline-none
      flex justify-between items-center
      ${outline ? "border-2 border-gray-300" : "border-0 bg-gray-200"}
      ${disabled ? "text-opacity-50" : ""}`}
		menuClassName={"border-0 shadow-md bg-white"}
		className={"bg-white"}
		arrowClassName={"hidden"}
		options={options}
		value={value}
		onChange={onChange}
		placeholder={placeholder ?? "Select"}
		arrowClosed={
			<span>
				<FiChevronDown />
			</span>
		}
		arrowOpen={
			<span>
				<FiChevronUp />
			</span>
		}
	/>
);
