import moment from "moment";

export const currencyFormat = (num = 0) => {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const dateFormat = (date) => {
	const formattedDate = moment(date).calendar(null, {
		lastDay: "[Yesterday]",
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		sameElse: "DD MMM YYYY",
		lastWeek: "DD MMM YYYY",
		nextWeek: "DD MMM YYYY",
	});

	return formattedDate;
};

export const timeFormat = (date) => {
	const formattedTime = moment(date).format("HH:mm");
	return formattedTime;
};

export const insertArray = (array, index, item) => {
	return [...array.slice(0, index), item, ...array.slice(index)];
};

export const getUrlExtension = (url) => {
	return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const orderStatusFormat = (statusCode) => {
	let status = {};

	switch (statusCode) {
		case 0:
			status = { label: "NEW", color: "green" };
			break;
		case 1:
			status = { label: "PACKING", color: "orange" };
			break;
		case 2:
			status = { label: "READY TO SHIP", color: "orange" };
			break;
		case 3:
			status = { label: "DELIVERED", color: "green" };
			break;
		case 4:
			status = { label: "CANCELLED", color: "lightGrey" };
			break;
		case 5:
			status = { label: "PAID", color: "green" };
			break;
		default:
			status = { label: "NEW", color: "green" };
			break;
	}

	return status;
};

export const streamStatusFormat = (statusCode) => {
	let status = {};

	switch (statusCode) {
		case 0:
			status = { label: "Upcoming", backgroundColor: "#BBBBBB" };
			break;
		case 1:
			status = { label: "Live", backgroundColor: "#37A15B" };
			break;
		case 2:
			status = { label: "Past", backgroundColor: "#BBBBBB" };
			break;
		case 4:
			status = { label: "Hidden" , backgroundColor: "#D2D2D2"};
			break;
		default:
			status = { label: "Upcoming" };
			break;
	}

	return status;
};

export const preRecordStatusFormat = (statusCode) => {
	let status = {};

	switch (statusCode) {
		case 0:
			status = { label: "Scheduled", backgroundColor: "#37A15B" };
			break;
		case 1:
			status = { label: "Posted", backgroundColor: "#BBBBBB"};
			break;
		case 4:
			status = { label: "Hidden", backgroundColor: "#D2D2D2"};
			break;
		default:
			status = { label: "Scheduled", backgroundColor: "#37A15B" };
			break;
	}

	return status;
};

export const streamStatusColorFormat = (statusCode) => {
	let status = {};

	switch (statusCode) {
		case "Upcoming":
			status = { color: "green-400" };
			break;
		case "Live":
			status = { color: "green-400" };
			break;
		case "Past":
			status = { color: "gray" };
			break;
		default:
			status = { color: "black" };
			break;
	}

	return status;
};

export const streamPastStatusColorFormat = (statusCode) => {
	let status = {};

	switch (statusCode) {
		case "Past":
			status = { color: "gray" };
			break;
		default:
			status = { color: "black" };
			break;
	}

	return status;
};

export const productStatusFormat = (stock) => {
	let status = {};

	if (stock === 0) {
		status = { color: "red", label: "Out of stock" };
	} else if (stock <= 10) {
		status = { color: "orange", label: `Stock: ${stock} Low stock` };
	} else {
		status = { color: "green", label: `Stock ${stock}` };
	}

	return status;
};

export const dropdownFormat = (data, labelKey, valueKey) => {
	return data.map((item) => {
		return {
			label: item[labelKey],
			value: item[valueKey],
			extraData: item,
		};
	});
};

export const findStringsContainMobileNumber = (value) => {
	if (!value) return true;

	const mobileNumberRegex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/g;
	const numbersOfArray = value.match(/[-+]?[0-9]*\.?[0-9]+/g);

	if (numbersOfArray) {
		const mobileNumbers = numbersOfArray.filter((number) => number.match(mobileNumberRegex));
		const containMobileNumbers = mobileNumbers.length ? false : true;

		return containMobileNumbers;
	}

	return true;
};

export const validMobileNumbers = (value) => {
	if (!value) return false;

	const mobileRegex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/g;
	const validMobileNumbers = value.match(mobileRegex);
	return validMobileNumbers;
};

export const nricFormat = (value) => {
	const val = value.replace(/-/g, "");
	const val_1 = val.substring(0, 6);
	const val_2 = val.substring(6, 8);
	const val_3 = val.substring(8, 12);

	if (val.length <= 6) {
		return val;
	} else if (val.length > 6 && val.length < 9) {
		return `${val_1}-${val_2}`;
	} else if (val.length >= 9) {
		return `${val_1}-${val_2}-${val_3}`;
	}
};

export const validNric = (value) => {
	if (!value) return false;

	const nricRegex = /^\d{6}-\d{2}-\d{4}$/g;
	const validNric = value.match(nricRegex);
	return validNric;
};

export const changeAddressToString = (address) => {
	if (address) {
		const result = JSON.parse(address);
		return result !== null
			? Object.keys(result)
					.map((item) => result[item])
					.join(", ")
			: null;
	}
};

export const formatDate = (date, format) => {
	let result = moment(date).format(format);
	if (format === "timestamp") {
		result = moment(date).unix() * 1000;
	}
	return result;
};

export const sortByKey = (array, key, order) => {
	let mutatedArray = [];
	mutatedArray =
		order === "asc"
			? array.sort((a, b) => {
					if (isFinite(parseFloat(a[key]))) {
						return parseFloat(a[key]) - parseFloat(b[key]);
					}
					return a[key].toString().localeCompare(b[key]);
			  })
			: array.sort((a, b) => {
					if (isFinite(parseFloat(a[key]))) {
						return parseFloat(b[key]) - parseFloat(a[key]);
					}
					return b[key].toString().localeCompare(a[key]);
			  });

	return mutatedArray;
};

export const MonthFormat = (month) => {
	let result = {};

	switch (month) {
		case 1:
			result = { label: "January" };
			break;
		case 2:
			result = { label: "February" };
			break;
		case 3:
			result = { label: "March" };
			break;
		case 4:
			result = { label: "April" };
			break;
		case 5:
			result = { label: "May" };
			break;
		case 6:
			result = { label: "June" };
			break;
		case 7:
			result = { label: "July" };
			break;
		case 8:
			result = { label: "August" };
			break;
		case 9:
			result = { label: "September" };
			break;
		case 10:
			result = { label: "October" };
			break;
		case 11:
			result = { label: "Novermber" };
			break;
		case 12:
			result = { label: "December" };
			break;
		default:
			result = { label: "-" };
			break;
	}

	return result;
};

export const MonthStringFormat = (month) => {
	let result = {};

	switch (month) {
		case "Jan":
			result = { label: 1 };
			break;
		case "Feb":
			result = { label: 2 };
			break;
		case "Mar":
			result = { label: 3 };
			break;
		case "Apr":
			result = { label: 4 };
			break;
		case "May":
			result = { label: 5 };
			break;
		case "Jun":
			result = { label: 6 };
			break;
		case "Jul":
			result = { label: 7 };
			break;
		case "Aug":
			result = { label: 8 };
			break;
		case "Sep":
			result = { label: 9 };
			break;
		case "Oct":
			result = { label: 10 };
			break;
		case "Nov":
			result = { label: 11 };
			break;
		case "Dec":
			result = { label: 12 };
			break;
		default:
			result = { label: 0 };
			break;
	}

	return result;
};