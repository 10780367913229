import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import adminReducer from "./adminSlice";
import sideMenuReducer from "./sideMenuSlice";
import dashboardReducer from "./dashboardSlice";
import monthlySettlementReducer from "./monthlySettlementSlice";
import announcementReducer from "./announcementSlice";
import merchantReducer from "./merchantsSlice";
import orderReducer from "./orderSlice";
import streamReducer from "./streamSlice";
import adReducer from "./adSlice";
import productCategoryReducer from "./productCategorySlice";

const customizedMiddleware = getDefaultMiddleware({
	serializableCheck: false,
});

export default configureStore({
	reducer: {
		admin: adminReducer,
		sideMenu: sideMenuReducer,
		dashboard: dashboardReducer,
		monthlySettlement: monthlySettlementReducer,
		announcement: announcementReducer,
		merchants: merchantReducer,
		orders: orderReducer,
		streams: streamReducer,
		ad: adReducer,
		productCategory: productCategoryReducer,
	},
	middleware: customizedMiddleware,
});
