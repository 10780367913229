import React, { useCallback, useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchOrders, filterOrders, setCurrentPage } from "redux/orderSlice";
import { config } from "common/config";
import ColoredText from "components/colored-text/colored-text";
import { Message } from "components/ui/Message";
import Pagination from "components/pagination/pagination";
import { Spinner } from "components/ui/Spinner";
import StatusText from "components/ui/StatusText";
import TableComponent from "components/ui/TableComponent";
import { SearchInput } from "components/ui/UIComponents";
import { currencyFormat } from "common/utility";

const pageConfig = config.ordersPage.pageConfig;

const OrdersListPage = () => {
	const today = new Date();
	const history = useHistory();
	const [selected, setSelected] = useState("");
	const [list, setList] = useState([]);
	const [yearInsert, setYearInsert] = useState(today.getFullYear());

	const [searchText, setSearchText] = useState("");

	const dispatch = useDispatch();

	const { orders, filteredOrders, loading, error, totalPage, currentPage } = useSelector((state) => state.orders);

	useEffect(() => {
		fetch(currentPage, searchText, yearInsert, "date", "desc", selected);
	}, [yearInsert, currentPage]);

	const fetch = useCallback(
		async (currentPage, search, year, sort, order, status) => {
			dispatch(
				fetchOrders({
					currentPage,
					search,
					year,
					sort,
					order,
					status,
				})
			);
		},
		[dispatch]
	);

	useEffect(() => {
		let array = [];

		if (filteredOrders !== null) {
			array = filteredOrders;
		} else {
			array = orders;
		}

		const list = array.map((order) => ({
			...order,
			price: currencyFormat(parseFloat(order.price)),
			status: (
				<ColoredText
					text={pageConfig.filters.find((item) => item.value === order.status)?.label || order.status}
					color={changeStatusColor(order.status)}
				/>
			),
			action: (
				<button onClick={() => navigateToDetails(order.id)}>
					<BsChevronRight size={15} />
				</button>
			),
		}));

		setList(list);
	}, [orders, filteredOrders]);

	function changeStatusColor(status) {
		switch (status) {
			case "refunded":
				return "text-danger";
			case "delivered":
				return "text-success";
			default:
				return "text-gray";
		}
	}

	const navigateToDetails = (id) => {
		history.push(`/admin/orders/order/${id}`);
	};

	const searchList = async (search) => {
		setSearchText(search);
		setSelected("")

		fetch(0, search, yearInsert)
	};

	const searchByStatus = async (status) => {
		setSelected(status);

		dispatch(setCurrentPage(0));
		fetch(currentPage, searchText, yearInsert, "date", "asc", status);
	};

	const handlePagination = (value) => {
		setSearchText("");
		const currentIndex = Math.max(0, Math.min(currentPage + value, totalPage - 1));
		dispatch(setCurrentPage(currentIndex));

		fetch(currentIndex, searchText, yearInsert, "date", "asc", selected);
	};

	const handleSpecificPagination = (value) => {
		setSearchText("");
		dispatch(setCurrentPage(value));

		fetch(value, searchText, yearInsert, "date", "asc", selected);
	};

	const sort = (key, order) => {
		fetch(currentPage, searchText, yearInsert, key, order, selected);
	};
	
	return (
		<>
			<div className="m-container">
				<div className="m-wrapper">
					<Spinner show={loading} />
					<div className=" text-black min-h-full min-h-screen p-8">
						{error && <Message variant="danger" message={error} />}
						<div className="w-2/4">
							<SearchInput
								value={searchText}
								onChange={(e) => searchList(e.target.value)}
								placeholder="Search Order ID, Store Name"
								icon={<FiSearch size="18" />}
							/>
						</div>
						<div className="flex flex-row justify-between">
							<div className="flex flex-row  my-6  divide-x-2 space-x-4 divide-gray-500">
								{pageConfig.filters.map(
									(filter) =>
										filter.showFilter && (
											<div
												key={filter.value}
												className={`px-5 cursor-pointer ${
													selected === filter.value ? "text-gray-800 font-semibold" : "m-txt--lightGrey"
												}`}
												onClick={() => searchByStatus(filter.value)}
											>
												{filter.value == "delivered" ? "Completed" : filter.label}
											</div>
										)
								)}
							</div>

							<div className="flex items-center">
								<div>
									<span className="font-bold p-4">Year</span>
									<input
										className="w-20 p-2"
										maxLength={4}
										value={yearInsert}
										type="number"
										onChange={(e) => setYearInsert(parseInt(e.target.value))}
									/>
								</div>
							</div>
						</div>

						<TableComponent
							headers={config.ordersPage.ordersHeader}
							rows={list}
							hasButton={true}
							sortFunction={sort}
						/>

						<div className="flex flex-row items-center justify-end my-2">
							<button onClick={() => handlePagination(-1)}>
								<AiFillCaretLeft size="16" color={currentPage == 0 ? "#D2D2D2" : "#000000"}/>
							</button>

							<Pagination totalPages={totalPage} currentPage={currentPage + 1} onPageClick={handleSpecificPagination}/>

							<button onClick={() => handlePagination(+1)}>
								<AiFillCaretRight size="16" color={currentPage == totalPage - 1  ? "#D2D2D2" : "#000000"}/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrdersListPage;
