import axios from "axios";
import { axiosService } from "./axios";

const endpointUrl = process.env.REACT_APP_API_ENDPOINT;

const request = {
	login: endpointUrl + "admin/login",
	signup: endpointUrl + "admin/register",
	activeteUser: endpointUrl + "admin/activate",
	refreshToken: endpointUrl + "admin/refreshJwt",
	activateUser: endpointUrl + "admin/activate/{token}",
	merchantIndex: endpointUrl + "user/merchant/{userId}",
	resetPassword: endpointUrl + "user/resetPassword",
	forgetPassword: endpointUrl + "user/forgotPassword",
	validatePasswordToken: endpointUrl + "user/validatePasswordToken",
	notification: endpointUrl + "notification/admin",
	readNotification: endpointUrl + "notification/admin/read",

	profile: endpointUrl + "user/merchant/profile/{userId}",
	profileUpdate: endpointUrl + "user/merchant/profile/update",
	countries: endpointUrl + "helper/country",
	couriers: endpointUrl + "helper/couriers",
	cities: endpointUrl + "helper/cities",

	salesReport: endpointUrl + "admin/stats/report?filters={filters}&values={values}",
	orders: endpointUrl + "admin/order/history?{params}",
	order: endpointUrl + "admin/order/{orderNumber}",
	orderRefund: endpointUrl + "admin/orders/refund/{orderNumber}",
	sellersList: endpointUrl + "admin/merchantDetails/{page}?search={search}&filter={filter}&sort={sort}&order={order}",

	companyDocuments: endpointUrl + "admin/companyDocuments/{sellerId}",
	verifyCompany: endpointUrl + "admin/company/status/update",

	categories: endpointUrl + "admin/categories?sort={sort}&order={order}",
	createCategory: endpointUrl + "admin/category/create",
	updateCategory: endpointUrl + "admin/category/update",

	monthlySettlement:
		endpointUrl + "admin/monthlySettlement?page={page}&search={search}&filters={filters}&values={values}&sort={sort}&order={order}",
	monthlyOrders:
		endpointUrl + "admin/monthlyOrderSettlement?filters={filters}&values={values}&sort={sort}&order={order}",

	exportOrderSettlement: "admin/export/monthlyOrderSettlement?filters={filters}&values={values}",

	getAnnouncementList:
		endpointUrl + "admin/announcement/?page={page}&search={search}&size=15&sort={sort}&order={order}&field={field}&value={value}",
	createAnnouncement: endpointUrl + "admin/announcement/create",

	streams: endpointUrl + "admin/stream/schedule?sort={sort}&page={page}&order={order}&search={search}",
	streamDetails: endpointUrl + "admin/stream/merchant/{sellerId}?sort={sort}&order={order}",
	singleStreamDetails: endpointUrl + "admin/stream/{streamId}",
	triggerStreamEvent: endpointUrl + "admin/stream/{id}/event/{event}",

	advertisementList:
		endpointUrl +
		"admin/banner/list?filters={filters}&values={values}&sort={sort}&order={order}&page={page}&length={size}",

	bannerDetails: endpointUrl + "admin/banner/{bannerId}",
	bannerVerify: endpointUrl + "admin/banner/verify",
	bannerFee: endpointUrl + "admin/banner/fee",
	createBanner: endpointUrl + "admin/banner/create",
	deleteBanner: endpointUrl + "admin/banner/delete/{id}",
	getAdminProfile: endpointUrl + "admin/profile/{id}",
	updateAdminProfile: endpointUrl + "admin/profile/update",
	getCourier: endpointUrl + "courier/",
	updateCourier: endpointUrl + "courier/admin/update",
	getState: endpointUrl + "helper/state",
};

const api = {
	get: {
		profile: (payload) => {
			const endpoint = request.profile.replace("{userId}", payload);
			return axiosService.get(endpoint);
		},
		salesReport: (filters, values) => {
			const endpoint = request.salesReport.replace("{filters}", filters).replace("{values}", values);
			return axiosService.get(endpoint);
		},
		orders: (params) => {
			const endpoint = request.orders.replace("{params}", params)
			return axiosService.get(endpoint);
		},

		order: (orderNumber) => {
			const endpoint = request.order.replace("{orderNumber}", orderNumber);
			return axiosService.get(endpoint);
		},

		sellers: ({ page, search, filter, sort, order }) => {
			const endpoint = request.sellersList
				.replace("{page}", page)
				.replace("{search}", search)
				.replace("{filter}", filter)
				.replace("{sort}", sort)
				.replace("{order}", order);
			return axiosService.get(endpoint);
		},
		categories: ({ sort, order }) => {
			const endpoint = request.categories.replace("{sort}", sort).replace("{order}", order);
			return axiosService.get(endpoint);
		},
		monthlyStatements: ({ page, search, filters, values, sort, order }) => {
			const endpoint = request.monthlySettlement
				.replace("{page}", page)
				.replace("{search}", search)
				.replace("{filters}", filters)
				.replace("{values}", values)
				.replace("{sort}", sort)
				.replace("{order}", order);
			return axiosService.get(endpoint);
		},
		monthlyOrders: ({ filters, values, sort, order }) => {
			const endpoint = request.monthlyOrders
				.replace("{filters}", filters)
				.replace("{values}", values)
				.replace("{sort}", sort)
				.replace("{order}", order);
			return axiosService.get(endpoint);
		},

		getPdfOrders: ({ filters, values }) => {
			const endpoint = request.monthlyOrders.replace("{filters}", filters).replace("{values}", values);
			return axiosService.get(endpoint);
		},
		getAnnouncementList: ({ page, search, field, value, sort, order }) => {
			const endpoint = request.getAnnouncementList
				.replace("{page}", page)
				.replace("{search}", search)
				.replace("{field}", field)
				.replace("{value}", value)
				.replace("{sort}", sort)
				.replace("{order}", order);

			return axiosService.get(endpoint);
		},
		getCompanyDocuments: ({ sellerId }) => {
			const endpoint = request.companyDocuments.replace("{sellerId}", sellerId);
			return axiosService.get(endpoint);
		},
		streams: ({ page, sort, order, search }) => {
			const endpoint = request.streams.replace("{sort}", sort).replace("{page}", page).replace("{order}", order).replace("{search}", search);
			return axiosService.get(endpoint);
		},
		streamDetails: ({ sellerId, sort, order }) => {
			const endpoint = request.streamDetails
				.replace("{sellerId}", sellerId)
				.replace("{sort}", sort)
				.replace("{order}", order);
			return axiosService.get(endpoint);
		},
		singleStreamDetails: ({ streamId }) => {
			const endpoint = request.singleStreamDetails
				.replace("{streamId}", streamId)
			return axiosService.get(endpoint);
		},
		getBannerList: ({ sort, filters, values, size, page, order }) => {
			const endpoint = request.advertisementList
				.replace("{sort}", sort)
				.replace("{filters}", filters)
				.replace("{values}", values)
				.replace("{page}", page)
				.replace("{size}", size)
				.replace("{order}", order);
			return axiosService.get(endpoint);
		},
		deleteBanner: ( bannerId ) => {
			const endpoint = request.deleteBanner.replace("{id}", bannerId);
			return axiosService.get(endpoint);
		},
		getBannerDetails: ({ bannerId }) => {
			const endpoint = request.bannerDetails.replace("{bannerId}", bannerId);
			return axiosService.get(endpoint);
		},
		getNotification: (payload) => {
			return axiosService.get(request.notification, payload);
		},
		getAdminProfile: ({ id }) => {
			const endpoint = request.getAdminProfile.replace("{id}", id);
			return axiosService.get(endpoint);
		},
		getCourier: () => {
			const endpoint = request.getCourier;
			return axiosService.get(endpoint);
		},
		getCountries: () => {
			const endpoint = request.countries;
			return axiosService.get(endpoint);
		},
		getStates: () => {
			const endpoint = request.getState;
			return axiosService.get(endpoint);
		},
	},
	post: {
		refreshToken: ({ jsonWebToken }) => {
			const payload = { jsonWebToken };
			return axiosService.post(request.refreshToken, payload);
		},
		login: async ({ email, password }) => {
			const payload = { email, password };
			return await axiosService.post(request.login, payload);
		},
		resetPassword: (payload) => {
			return axiosService.post(request.resetPassword, payload);
		},
		forgetPassword: (payload) => {
			return axiosService.post(request.forgetPassword, payload);
		},
		createCategory: (payload, headers) => {
			return axiosService.post(request.createCategory, payload, headers);
		},
		updateCategory: (payload, headers) => {
			return axiosService.post(request.updateCategory, payload, headers);
		},
		createAnnouncement: (payload, headers) => {
			return axiosService.post(request.createAnnouncement, payload, headers);
		},

		verifyBanner: (payload, headers) => {
			return axiosService.post(request.bannerVerify, payload, headers);
		},

		verifyCompany: (payload) => {
			return axiosService.post(request.verifyCompany, payload);
		},
		setBannerFee: (fee) => {
			return axiosService.post(request.bannerFee, fee);
		},
		updateAdminProfile: (payload) => {
			return axiosService.post(request.updateAdminProfile, payload);
		},
		updateCourier: (payload) => {
			return axiosService.post(request.updateCourier, payload);
		},
		readNotification: (payload) => {
			return axiosService.post(request.readNotification, payload);
		},
		triggerStreamEvent: (id, event) => {
			const endpoint = request.triggerStreamEvent
				.replace("{id}", id)
				.replace("{event}", event);
			return axiosService.post(endpoint);
		},
		createBanner: (payload) => {
			return axiosService.post(request.createBanner, payload);
		}
	},
	patch: {
		orderRefund: (orderNumber) => {
			const endpoint = request.orderRefund.replace("{orderNumber}", orderNumber)
			return axiosService.patch(endpoint)
		}
	},

	getSecured: {
		salesReport: () => {
			const endpoint = request.salesReport;
			return axiosService.get(endpoint);
		},
	},
};

export default api;
