/*eslint no-undef: "error"*/
/*eslint-env browser*/
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { createCategory, updateCategory } from "redux/productCategorySlice";
import { ImageInput, RoundedButton, ToggleButton } from "components/ui/UIComponents";
import Button from "components/button/button";
import { NotificationContainer, NotificationManager } from "react-notifications";

const ModalForm = ({ popupVisible, setPopupVisible, addEditForm, category }) => {
	const [categoryForm, setCategoryForm] = useState({
		categoryName: "",
		categoryStatus: false,
		categoryIcon: null,
	});

	const dispatch = useDispatch();

	useEffect(() => {
		if (addEditForm == "edit" && category) {
			setCategoryForm({
				categoryName: category.categoryName.name,
				categoryStatus: category.status,
				categoryIcon: category.categoryName.image,
			});
		} else {
			setCategoryForm({
				categoryName: "",
				categoryStatus: false,
				categoryIcon: null,
			});
		}
	}, [category]);

	const customStyles = {
		content: {
			top: "50%",
			left: "55%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: 10,
			width: "40%",
			minWidth: 400,
		},
	};

	const createNotification = (type, message, title) => {
		switch (type) {
			case "info":
				NotificationManager.info(message);
				break;
			case "success":
				NotificationManager.success(message);
				break;
			case "warning":
				NotificationManager.warning(message);
				break;
			case "error":
				NotificationManager.error(title, message);
				break;
		}
	};

	const onDragOver = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const onDrop = (e, uploadMethod) => {
		let imageSizeTooBig = 20000000;
		if (uploadMethod === "browse") {
			if (e.target.files[0].size < imageSizeTooBig) {
				let image = {
					uri: URL.createObjectURL(e.target.files[0]),
					name: e.target.files[0].name,
					type: e.target.files[0].type,
					file: e.target.files[0],
				};
				setCategoryForm({
					...categoryForm,
					categoryIcon: image,
				});
			} else {
				createNotification("error", "File size exceeded the limit of 20MB'");
			}
		} else {
			e.preventDefault();
			const {
				dataTransfer: { files },
			} = e;
			const { size, type } = files[0];
			const reader = new FileReader();
			const fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/bmp"];

			if (!fileTypes.includes(type)) {
				createNotification("error", "File format must be either png, jpg or bmp");
				return false;
			}
			if (size > imageSizeTooBig) {
				createNotification("error", "File size exceeded the limit of 20MB");
				return false;
			}
			let image = {
				uri: URL.createObjectURL(files[0]),
				name: files[0].name,
				type: files[0].type,
				file: files[0],
			};
			setCategoryForm({
				...categoryForm,
				categoryIcon: image,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData();
		{
			category && category.id && formData.append("id", category.id);
		}
		formData.append("name", categoryForm.categoryName);
		formData.append("active", categoryForm.categoryStatus.toString());
		if (categoryForm.categoryIcon?.file) {
			formData.append("desktopImage", categoryForm.categoryIcon.file, categoryForm.categoryIcon.name);
		}
		if (addEditForm === "add") {
			formData.append("mobileImage", categoryForm.categoryIcon.file, categoryForm.categoryIcon.name);
			dispatch(createCategory({ payload: formData }));
		} else {
			dispatch(updateCategory({ payload: formData }));
		}
		setPopupVisible(false);
	};

	return (
		<>
			<NotificationContainer />
			<Modal
				ariaHideApp={false}
				isOpen={popupVisible}
				onRequestClose={() => setPopupVisible(false)}
				style={customStyles}
			>
				<form noValidate onSubmit={(value) => handleSubmit(value)}>
					<div className="pl-4 m-4">
						<div className="flex justify-end">
							<button onClick={() => setPopupVisible(false)}>
								<MdClose size="24" color="gray" />
							</button>
						</div>
						<div className="pb-3 font-bold text-lg">
							{addEditForm === "add" ? "Add category" : "Edit category"}
						</div>
						<div className="pb-4 text-gray-800 border-b-2 font-medium">
							{addEditForm === "add" ? "Please add category name and icon." : null}
						</div>
						<div className="mt-4">
							<ToggleButton
								activated={categoryForm.categoryStatus}
								onClick={() =>
									setCategoryForm({
										...categoryForm,
										categoryStatus: !categoryForm.categoryStatus,
									})
								}
							/>
						</div>
						<div className="mt-6 mb-2 font-medium">Category name</div>
						<input
							placeholder="Enter category name"
							onChange={(e) =>
								setCategoryForm({
									...categoryForm,
									categoryName: e.target.value,
								})
							}
							value={categoryForm.categoryName}
							className="border-2 p-2 w-full rounded-md mb-4"
						/>

						<div className="font-medium">Upload category icon</div>
						<div className="text-xs">Format: jpg, png, bmp</div>

						<div className="mt-4 w-3/6">
							<ImageInput
								onDrop={(e) => onDrop(e, "drag")}
								onClick={(e) => onDrop(e, "browse")}
								onDragOver={(e) => onDragOver(e)}
								inputItem={categoryForm.categoryIcon?.uri ? categoryForm.categoryIcon.uri : categoryForm.categoryIcon}
							/>
						</div>

						<div className="flex justify-center mt-8">
							{addEditForm === "add" ? (
								<Button small label="Add" type="submit" />
							) : (
								<Button small label="Save Changes" type="submit" />
							)}
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

export default ModalForm;
